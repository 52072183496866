import { createFileRoute } from '@tanstack/react-router'
import EditUserCourses from '../../../../../features/User/Edit/EditUserCourses/EditUserCourses'

export const Route = createFileRoute('/(fullscreen)/users/$id/edit/courses')({
  component: RouteComponent,
})

function RouteComponent() {
  const { id } = Route.useParams()
  return <EditUserCourses id={Number(id)} />
}
