import { createFileRoute } from '@tanstack/react-router'
import GroupEditUsers from '../../../../../features/Groups/GroupEditUsers/GroupEditUsers'

export const Route = createFileRoute('/(fullscreen)/groups/$id/edit/users')({
  component: RouteComponent,
})

function RouteComponent() {
  const { id } = Route.useParams()
  return <GroupEditUsers id={Number(id)} />
}
