import {
  Button,
  Container,
  DefaultMantineColor,
  Fieldset,
  Loader,
  MantineColorsTuple,
  PasswordInput,
  Popover,
  Progress,
  SegmentedControl,
  Select,
  Switch,
  TextInput,
  Textarea,
  rem,
  createTheme,
  Skeleton,
  MultiSelect,
  Tooltip,
  Radio,
  NumberInput,
  Alert,
  NativeSelect,
  Modal,
  Drawer,
  PinInput,
  Tabs,
  InputBase,
} from "@mantine/core";

import inputClasses from "./inputClasses.module.scss";
import switchClasses from "./switchClasses.module.scss";
import buttonClasses from "./buttonClasses.module.scss";
import tabsClasses from "./tabsClasses.module.scss";

type ExtendedCustomColors = "bluegray" | "bluegraylight" | DefaultMantineColor;

declare module "@mantine/core" {
  export interface MantineThemeColorsOverride {
    colors: Record<ExtendedCustomColors, MantineColorsTuple>;
  }
}

const CONTAINER_SIZES: Record<string, string> = {
  xxs: rem(300),
  xs: rem(400),
  sm: rem(500),
  md: rem(600),
  lg: rem(700),
  xl: rem(800),
  xxl: rem(1200),
};

export const SKILLSTER_THEME = createTheme({
  colors: {
    blue: [
      "#DEE6F7",
      "#C2D1F5",
      "#9CB7F1",
      "#769CED",
      "#5991F2",
      "#3B86F7",
      "#3670D6",
      "#3059B4",
      "#2C4988",
      "#28385C",
    ],
    green: [
      "#D2F1E5",
      "#A2EFD9",
      "#72E7C5",
      "#42DFB0",
      "#21DBB0",
      "#00D6AF",
      "#08C79A",
      "#0FB885",
      "#128F6A",
      "#14654E",
    ],
    pink: [
      "#FFE0E4",
      "#FAE0E0",
      "#F7D2D2",
      "#F3C3C3",
      "#F9A2A9",
      "#FF808F",
      "#F16A7F",
      "#E3546E",
      "#A83A4C",
      "#6C2029",
    ],
    yellow: [
      "#F6F4C2",
      "#F6EEA6",
      "#F4EB99",
      "#F1E78B",
      "#F6E761",
      "#FAE636",
      "#DBC81C",
      "#BBA902",
      "#9E8A01",
      "#806B00",
    ],
    grape: [
      "#F1E8FC",
      "#EBE1F7",
      "#D5C6F5",
      "#BEABF3",
      "#BB96F8",
      "#B781FC",
      "#965DDF",
      "#7538C2",
      "#5C2D98",
      "#43216D",
    ],
    bluegraylight: [
      "#f8f9fa",
      "#f8f9fa",
      "#f8f9fa",
      "#f8f9fa",
      "#f8f9fa",
      "#f8f9fa",
      "#f8f9fa",
      "#f8f9fa",
      "#f8f9fa",
      "#f8f9fa",
    ],
    bluegray: [
      "#EFF3F8",
      "#E7EDF5",
      "#E1E7F1",
      "#DAE1EC",
      "#A6B0C2",
      "#717F98",
      "#545F73",
      "#373E4D",
      "#303646",
      "#282E3F",
    ],
    gray: [
      "#EEEEEE",
      "#D6D6D6",
      "#C5C5C5",
      "#B4B4B4",
      "#969696",
      "#777777",
      "#676767",
      "#575757",
      "#454545",
      "#333333",
    ],
    orange: [
      "#F7EFDE",
      "#F5E4C2",
      "#F1D59C",
      "#EDC576",
      "#F2BF59",
      "#F7B83B",
      "#D6A136",
      "#B48830",
      "#88692C",
      "#5C4B28",
    ],
    dark: [
      "#C1C2C5",
      "#A6A7AB",
      "#909296",
      "#5C5F66",
      "#373A40",
      "#2C2E33",
      "#25262B",
      "#1A1B1E",
      "#141517",
      "#101113",
    ],
    purple: [
      "#F1E8FC",
      "#EBE1F7",
      "#D5C6F5",
      "#BEABF3",
      "#BB96F8",
      "#B781FC",
      "#965DDF",
      "#7538C2",
      "#5C2D98",
      "#43216D",
    ],
  },
  headings: {
    fontFamily: "Rajdhani",
    fontWeight: "700",
    sizes: {
      h1: {
        // fontSize: 13,
      },
      h3: {},
    },
  },
  // fontFamily: "Open Sans",
  fontSizes: {
    xs: rem(10),
    sm: rem(11),
    md: rem(14),
    lg: rem(16),
    xl: rem(20),
  },
  breakpoints: {
    xs: "30em",
    sm: "48em",
    md: "64em",
    lg: "74em",
    xl: "90em",
  },
  components: {
    Alert: Alert.extend({
      styles: () => ({
        message: {
          fontSize: 14,
        },
      }),
    }),
    Button: Button.extend({
      // styles: {
      //   root: {
      //     fontSize: rem(14),
      //     fontFamily: "var(--mantine-font-family-headings)",
      //     fontWeight: "700",
      //   },
      // },
      classNames: buttonClasses,
    }),
    Container: Container.extend({
      defaultProps: {
        size: "xxl",
      },
      vars: (_, { size, fluid }) => ({
        root: {
          "--container-size": fluid
            ? "100%"
            : size !== undefined && size in CONTAINER_SIZES
              ? CONTAINER_SIZES[size]
              : rem(size),
        },
      }),
    }),
    Drawer: Drawer.extend({
      styles: {
        title: {
          fontWeight: "bold",
          fontSize: 18,
          fontFamily: "var(--mantine-font-family-headings)",
        },
      },
    }),
    Modal: Modal.extend({
      styles: {
        title: {
          fontWeight: "bold",
          fontSize: 18,
          fontFamily: "var(--mantine-font-family-headings)",
        },
      },
    }),
    Popover: Popover.extend({
      styles: {
        dropdown: {
          border: 0,
          boxShadow: "var(--mantine-shadow-md)",
        },
      },
    }),
    Progress: Progress.extend({
      styles: {
        root: {
          backgroundColor: "var(--mantine-color-bluegray-1)",
        },
        // section: {
        //   backgroundColor: "var(--mantine-color-blue-5)",
        // },
      },
    }),
    SegmentedControl: SegmentedControl.extend({
      styles: {
        root: {
          backgroundColor: "var(--mantine-color-bluegray-1)",
        },
        label: {
          fontSize: 12,
        },
      },
    }),
    Skeleton: Skeleton.extend({
      styles: {
        root: {
          backgroundColor: "pink",
          fill: "pink",
        },
      },
    }),
    Switch: Switch.extend({ classNames: switchClasses }),
    PinInput: PinInput.extend({
      classNames: inputClasses,
      styles: {
        input: {
          fontWeight: "bold",
        },
      },
    }),
    NumberInput: NumberInput.extend({
      classNames: inputClasses,
    }),
    Tabs: Tabs.extend({
      classNames: tabsClasses,
    }),
    TextInput: TextInput.extend({
      classNames: inputClasses,
    }),
    Textarea: Textarea.extend({
      classNames: inputClasses,
    }),
    Tooltip: Tooltip.extend({
      styles: {
        tooltip: {
          fontSize: 12,
          fontWeight: "bold",
        },
      },
    }),
    RadioGroup: Radio.extend({
      classNames: inputClasses,
    }),
    NativeSelect: NativeSelect.extend({
      classNames: inputClasses,
    }),
    MultiSelect: MultiSelect.extend({
      classNames: inputClasses,
    }),
    Select: Select.extend({
      classNames: inputClasses,
    }),
    Loader: Loader.extend({
      defaultProps: {
        size: "xs",
        color: "bluegray.2",
      },
      styles: {},
    }),
    PasswordInput: PasswordInput.extend({
      classNames: inputClasses,
    }),
    Fieldset: Fieldset.extend({
      styles: {
        root: {
          backgroundColor: "var(--mantine-color-bluegraylight-0)",
          borderColor: "var(--mantine-color-bluegray-3)",
        },
        legend: {
          fontWeight: "bold",
          color: "var(--mantine-color-bluegray-7)",
        },
      },
    }),
  },
});
