import { createFileRoute } from "@tanstack/react-router";
import ExerciseCommentsPage from "../../../pages/Admin/ExerciseCommentsPage/ExerciseCommentsPage";

export const Route = createFileRoute("/(app)/admin/exercise-comments")({
  component: RouteComponent,
});

function RouteComponent() {
  return <ExerciseCommentsPage />;
}
