import { Container, ContainerProps } from "@mantine/core";
import classes from "./styles.module.scss";

function PageContainer({ children, ...rest }: ContainerProps) {
  return (
    <Container className={classes.wrapper} {...rest}>
      {children}
    </Container>
  );
}

export default PageContainer;
