import { IconUser } from "@tabler/icons-react";
import LinkItem from "../LinkItem/LinkItem";
import { USERS_PATH } from "../../../../../../../../../routes";
import { useAuth } from "../../../../../../../../../AuthContext";
import { useTranslation } from "react-i18next";

function UserDetailsLink() {
  const { t } = useTranslation();
  const { user } = useAuth();
  return (
    <LinkItem
      label={t("user.subMenu.profileDetails")}
      to={`${USERS_PATH}/${user?.ID}/profile`}
      icon={IconUser}
    />
  );
}

export default UserDetailsLink;
