import { Loader } from "@mantine/core";
import { QueryClient } from "@tanstack/react-query";
import { Outlet, createRootRouteWithContext } from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { AuthContextType } from "../AuthContext";
import PageContainer from "../components/PageContainer/PageContainer";
import ErrorComponent from "../components/RoutingErrors/ErrorComponent/ErrorComponent";
import PageNotFoundComponent from "../components/RoutingErrors/PageNotFoundComponent/PageNotFoundComponent";
import AdminOnly from "../features/Layout/AdminOnly/AdminOnly";
import Header from "../features/Layout/Header/Header";
import RestrictToLanguage from "../components/RestrictToLanguage/RestrictToLanguage";
import SkillsterSurvey from "../components/SkillsterSurvey/SkillsterSurvey";

interface MyRouterContext {
  auth: AuthContextType;
  queryClient: QueryClient;
}

export const Route = createRootRouteWithContext<MyRouterContext>()({
  component: () => (
    <>
      <Outlet />

      <AdminOnly>
        <RestrictToLanguage language="sv">
          <SkillsterSurvey />
        </RestrictToLanguage>
      </AdminOnly>

      {import.meta.env.DEV && (
        <AdminOnly>
          <TanStackRouterDevtools position="bottom-right" />
        </AdminOnly>
      )}
    </>
  ),
  notFoundComponent: () => (
    <>
      <Header />
      <PageContainer>
        <PageNotFoundComponent />
      </PageContainer>
    </>
  ),
  errorComponent: () => (
    <PageContainer>
      <ErrorComponent />
    </PageContainer>
  ),
  pendingComponent: () => (
    <PageContainer>
      <Loader color="blue.5" />
    </PageContainer>
  ),
});
