import { IconEye } from "@tabler/icons-react";
import LinkItem from "../LinkItem/LinkItem";
import { USERS_PATH } from "../../../../../../../../../routes";
import { useAuth } from "../../../../../../../../../AuthContext";
import { useTranslation } from "react-i18next";

function CoursesLink() {
  const { t } = useTranslation();
  const { user } = useAuth();
  return (
    <LinkItem
      label={t("common:listTitles.courses")}
      icon={IconEye}
      to={`${USERS_PATH}/${user?.ID}/courses`}
    />
  );
}

export default CoursesLink;
