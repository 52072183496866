import { createFileRoute } from "@tanstack/react-router";
import RunDataPage from "../../../pages/Admin/RunDataPage/RunDataPage";
import { convertDateToYYYYMMDD } from "../../../helpers/time.helpers";

type RunDataSearch = {
  fromDate: string;
  toDate: string;
  key1: string;
  key2: string | null;
  key3: string | null;
  key1Id: string | null;
  key2Id: string | null;
  filterStrategy: string;
  organizationFilter: number[];
};

export const Route = createFileRoute("/(app)/admin/rundata")({
  component: RouteComponent,
  validateSearch: (search: Record<string, unknown>): RunDataSearch => {
    return {
      fromDate: (search.fromDate as string) || "2022-01-01",
      toDate: (search.toDate as string) || convertDateToYYYYMMDD(new Date()),
      key1: (search.key1 as string) || "vehicle",
      key2: (search.key2 as string) || null,
      key3: (search.key3 as string) || null,
      key1Id: (search.key1Id as string) || null,
      key2Id: (search.key2Id as string) || null,
      filterStrategy: (search.filterStrategy as string) || "include",
      organizationFilter: (search.organizationFilter as number[]) || [],
    };
  },
});

function RouteComponent() {
  return <RunDataPage />;
}
