import { useQuery } from "@tanstack/react-query";
import { apiGet } from "../helpers/request.helpers";
import { DraftLockResponse } from "./course-editor/useDraftLock";

export type Lock = {
  UUID: string;
  ResourceType: string;
  ResourceKey: string;
  CreatedBy: number;
  Created: string;
  Expires: string;
};

export function useLockStatus({ lockUuid }: { lockUuid: string }) {
  return useQuery<Lock, Error>({
    queryKey: ["lock-status", lockUuid],
    queryFn: async () => {
      const json = await apiGet("/locking/show-lock-status", {
        uuid: lockUuid,
      });
      return json.Lock;
    },
  });
}

export function useDraftLockStatus({
  publicDraftUuid,
}: {
  publicDraftUuid: string;
}) {
  return useQuery<Lock, Error>({
    queryKey: ["lock-status", "course", publicDraftUuid],
    queryFn: async () => {
      const json = await apiGet("/locking/show-lock-status", {
        key: publicDraftUuid,
      });
      return json.Lock;
    },
  });
}

export const showDraftLockStatusFn = async ({
  publicDraftUuid,
}: {
  publicDraftUuid: string;
}) => {
  const response = await apiGet("/locking/show-lock-status", {
    key: publicDraftUuid,
  });
  const lock: DraftLockResponse = response.Lock;
  return lock;
};
