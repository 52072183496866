interface LogoSkillsterProps {
  width?: number;
  color?: string;
}
function LogoSkillster(props: LogoSkillsterProps) {
  const width = props.width ?? 148;
  const height = width * (19 / width);
  return (
    <svg
      width="148"
      height="19"
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.65464 0L19.3093 9.46727L9.65464 18.9345L0 9.46727L9.65464 0ZM13.2968 9.17539C12.9146 8.91151 12.3517 8.77958 11.608 8.77958H8.46899C8.0323 8.77958 7.81393 8.65102 7.81393 8.39392C7.81393 8.19094 7.87876 8.04885 8.0084 7.96766C8.13804 7.8797 8.34618 7.83572 8.63275 7.83572H11.4313L13.1099 6.0698H9.1138C8.51335 6.0698 8.00157 6.1307 7.57853 6.25249C7.15549 6.37427 6.8075 6.54681 6.53457 6.77008C6.26846 6.98659 6.07399 7.2437 5.95117 7.5414C5.82835 7.83234 5.76694 8.15034 5.76694 8.49541C5.76694 8.98932 5.94776 9.38513 6.3094 9.68283C6.67103 9.98054 7.24078 10.1294 8.01864 10.1294H11.1679C11.5977 10.1294 11.8127 10.2647 11.8127 10.5353C11.8127 10.7316 11.7479 10.877 11.6182 10.9718C11.4886 11.0665 11.2805 11.1138 10.9939 11.1138H7.23137L5.03714 12.8595H10.5128C11.1201 12.8595 11.6353 12.7986 12.0583 12.6768C12.4882 12.5482 12.8362 12.3723 13.1023 12.149C13.3752 11.919 13.5731 11.6483 13.6959 11.3371C13.8187 11.0259 13.8801 10.6876 13.8801 10.3222C13.8801 9.81477 13.6857 9.43249 13.2968 9.17539Z"
        fill={props.color ?? "white"}
      />
      <path
        d="M39.4076 8.25853C40.6882 8.25853 41.6574 8.48318 42.3154 8.93248C42.985 9.37027 43.3199 10.0212 43.3199 10.8852C43.3199 11.5073 43.2141 12.0834 43.0026 12.6133C42.7912 13.1433 42.4505 13.6041 41.9805 13.9958C41.5223 14.376 40.9231 14.6755 40.183 14.8944C39.4546 15.1018 38.5675 15.2054 37.5219 15.2054H26.5428L29.4154 12.2331H38.3502C38.8436 12.2331 39.202 12.1525 39.4252 11.9912C39.6484 11.8299 39.76 11.5822 39.76 11.2481C39.76 10.7873 39.3899 10.5569 38.6498 10.5569H32.2174C30.8781 10.5569 29.8971 10.3034 29.2744 9.79653C28.6517 9.28962 28.3404 8.61567 28.3404 7.77466C28.3404 7.18711 28.4461 6.64565 28.6576 6.15026C28.8691 5.64335 29.2039 5.20557 29.6621 4.83691C30.132 4.45673 30.7312 4.16296 31.4596 3.95559C32.1881 3.74822 33.0692 3.64453 34.1031 3.64453H44.342L41.4518 6.65141H33.2748C32.7814 6.65141 32.423 6.72629 32.1998 6.87606C31.9766 7.0143 31.865 7.25624 31.865 7.60186C31.865 8.03964 32.2409 8.25853 32.9928 8.25853H39.4076Z"
        fill={props.color ?? "white"}
      />
      <path
        d="M57.663 15.2054H52.147L49.1335 12.164C48.6166 11.6456 48.2171 11.2424 47.9351 10.9543C47.6649 10.6663 47.4534 10.4244 47.3007 10.2286L47.054 11.8875L46.4548 15.2054H42.7892L44.8864 3.64453H48.5343L48.0585 6.32307C47.988 6.68021 47.9116 7.03159 47.8294 7.3772C47.7472 7.72282 47.6767 8.0454 47.6179 8.34493C47.8881 8.13756 48.2171 7.88411 48.6048 7.58457C49.0043 7.27352 49.5094 6.8991 50.1204 6.46132L54.0327 3.64453H59.3195L54.2265 6.96246C53.7801 7.25048 53.3865 7.50393 53.0458 7.72282C52.7051 7.94171 52.3996 8.1318 52.1294 8.29309C51.8709 8.45438 51.6359 8.59839 51.4245 8.72511C51.213 8.85184 51.0015 8.97281 50.79 9.08801C51.0838 9.31842 51.4362 9.60644 51.8474 9.95206C52.2704 10.2977 52.8108 10.7758 53.4687 11.3864L57.663 15.2054Z"
        fill={props.color ?? "white"}
      />
      <path
        d="M61.1542 15.2054H57.5063L59.6034 3.64453H63.2337L61.1542 15.2054Z"
        fill={props.color ?? "white"}
      />
      <path
        d="M75.3383 12.2331L72.4306 15.2054H62.0154L64.1125 3.64453H67.7429L66.1921 12.2331H75.3383Z"
        fill={props.color ?? "white"}
      />
      <path
        d="M87.9201 12.2331L85.0124 15.2054H74.5972L76.6943 3.64453H80.3247L78.7738 12.2331H87.9201Z"
        fill={props.color ?? "white"}
      />
      <path
        d="M99.3564 8.25853C100.637 8.25853 101.606 8.48318 102.264 8.93248C102.934 9.37027 103.269 10.0212 103.269 10.8852C103.269 11.5073 103.163 12.0834 102.952 12.6133C102.74 13.1433 102.399 13.6041 101.929 13.9958C101.471 14.376 100.872 14.6755 100.132 14.8944C99.4034 15.1018 98.5164 15.2054 97.4708 15.2054H86.4917L89.3642 12.2331H98.2991C98.7925 12.2331 99.1508 12.1525 99.3741 11.9912C99.5973 11.8299 99.7089 11.5822 99.7089 11.2481C99.7089 10.7873 99.3388 10.5569 98.5987 10.5569H92.1663C90.827 10.5569 89.8459 10.3034 89.2233 9.79653C88.6006 9.28962 88.2892 8.61567 88.2892 7.77466C88.2892 7.18711 88.395 6.64565 88.6065 6.15026C88.8179 5.64335 89.1528 5.20557 89.611 4.83691C90.0809 4.45673 90.6801 4.16296 91.4085 3.95559C92.1369 3.74822 93.0181 3.64453 94.0519 3.64453H104.291L101.401 6.65141H93.2237C92.7302 6.65141 92.3719 6.72629 92.1487 6.87606C91.9254 7.0143 91.8138 7.25624 91.8138 7.60186C91.8138 8.03964 92.1898 8.25853 92.9417 8.25853H99.3564Z"
        fill={props.color ?? "white"}
      />
      <path
        d="M119.48 3.64453L116.554 6.65141H112.36L110.809 15.2054H107.161L108.695 6.65141H103.161L106.086 3.64453H119.48Z"
        fill={props.color ?? "white"}
      />
      <path
        d="M130.568 12.2331L128.424 15.2054H117.075L119.172 3.64453H132.813L129.905 6.65141H122.274L122.027 8.03388H131.35L128.812 10.6606H121.552L121.27 12.2331H130.568Z"
        fill={props.color ?? "white"}
      />
      <path
        d="M147.328 15.2054H142.446L140.367 12.1467H134.763L134.216 15.2054H130.568L131.626 9.29538H141.882C142.188 9.29538 142.476 9.26082 142.746 9.1917C143.028 9.12257 143.269 9.02465 143.468 8.89792C143.68 8.7712 143.844 8.61567 143.962 8.43134C144.091 8.23549 144.156 8.02236 144.156 7.79194C144.156 7.41177 143.997 7.12951 143.68 6.94518C143.363 6.74933 142.922 6.65141 142.358 6.65141H132.102L135.027 3.64453H142.605C143.216 3.64453 143.838 3.69637 144.473 3.80006C145.119 3.90374 145.701 4.09383 146.218 4.37033C146.746 4.6353 147.175 4.99244 147.504 5.44174C147.833 5.89105 147.997 6.46132 147.997 7.15255C147.997 7.67098 147.903 8.16636 147.715 8.63871C147.539 9.11105 147.281 9.53732 146.94 9.91749C146.611 10.2977 146.212 10.6203 145.742 10.8852C145.272 11.1502 144.755 11.3345 144.191 11.4382C144.355 11.588 144.537 11.7781 144.737 12.0085C144.949 12.2389 145.219 12.5557 145.548 12.9589L147.328 15.2054Z"
        fill={props.color ?? "white"}
      />
    </svg>
  );
}

export default LogoSkillster;
