import { useNavigate } from "@tanstack/react-router";
import {
  ADMIN_PATH,
  COURSES_PATH,
  GROUPS_PATH,
  HOME_PATH,
  SIMULATORS_PATH,
  SUPPORT_PATH,
  USERS_PATH,
} from "../routes";

function useGoToTopLevelRoute() {
  const { pathname } = window.location;
  const navigate = useNavigate();

  const goToTopLevelRoute = () => {
    if (
      pathname === GROUPS_PATH ||
      pathname === USERS_PATH ||
      pathname === COURSES_PATH ||
      pathname === `${COURSES_PATH}/custom` ||
      pathname === SIMULATORS_PATH ||
      pathname.startsWith(SUPPORT_PATH) ||
      pathname.startsWith(HOME_PATH) ||
      pathname.startsWith(ADMIN_PATH)
    )
      return;
    else if (pathname.startsWith(GROUPS_PATH)) navigate({ to: "/groups" });
    else if (pathname.startsWith(USERS_PATH)) navigate({ to: "/users" });
    else if (pathname.startsWith(`${COURSES_PATH}/custom`))
      navigate({ to: "/courses/$type", params: { type: "custom" } });
    else if (pathname.startsWith(COURSES_PATH)) navigate({ to: "/courses" });
    else navigate({ to: "/home" });
  };

  return goToTopLevelRoute;
}

export default useGoToTopLevelRoute;
