import { createFileRoute, redirect } from "@tanstack/react-router";
import Presentation from "../../../../../features/Academy/Presentation/Presentation";
import { fetchLang } from "../../../../../helpers/language.helpers";

export const Route = createFileRoute(
  "/(fullscreen)/home/academy/$lectureId/$segmentId"
)({
  component: RouteComponent,
  beforeLoad: () => {
    if (fetchLang() === "en-US") throw redirect({ to: "/home" });
  },
});

function RouteComponent() {
  const { lectureId, segmentId } = Route.useParams();
  return <Presentation lectureId={lectureId} segmentId={segmentId} />;
}
