import { createFileRoute } from "@tanstack/react-router";
import UsersPage from "../../../pages/UsersPage/UsersPage";

type UsersSearch = {
  sort?: string;
};

export const Route = createFileRoute("/(app)/users/")({
  component: RouteComponent,
  validateSearch: (search: Record<string, any>): UsersSearch => ({
    sort: search.sort,
  }),
});

function RouteComponent() {
  return <UsersPage />;
}
