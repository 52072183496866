import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import sessionUserStore, { SessionUser } from "./store/auth";
import { useStore } from "zustand";
import { queryClient, router } from "./main";

export type AuthContextType = {
  isAuthenticated: boolean | null;
  isPending: boolean;
  user: SessionUser | null;
  selectedOrganizationId: number | null;
  selectedSchoolId: number | null;
  setSelectedOrganizationId: (id: number) => void;
  setSelectedSchoolId: (id: number) => void;
  login: (user: SessionUser, token: string) => void;
  logout: () => void;
};

const AuthContext = createContext<AuthContextType>({
  isAuthenticated: null,
  isPending: true,
  user: null,
  selectedOrganizationId: null,
  selectedSchoolId: null,
  setSelectedOrganizationId: () => {},
  setSelectedSchoolId: () => {},
  login: () => {},
  logout: () => {},
});

const userKey = "skillster.teacher.user";
const tokenKey = "authentication_token";
const schoolIdKey = "school_id";
const organizationIdKey = "organization_id";

function setStoredToken(token: string | null) {
  if (token) {
    localStorage.setItem(tokenKey, token);
  } else {
    localStorage.removeItem(tokenKey);
  }
}

function getStoredUser(): SessionUser | null {
  const storedUser = localStorage.getItem(userKey);
  return storedUser ? JSON.parse(storedUser) : null;
}

function setStoredUser(user: SessionUser | null) {
  if (user) {
    localStorage.setItem(userKey, JSON.stringify(user));
  } else {
    localStorage.removeItem(userKey);
  }
}

function getStoredSelectedSchoolId(): number | null {
  const id = localStorage.getItem(schoolIdKey);
  return id ? Number(id) : null;
}

function getStoredSelectedOrganizationId(): number | null {
  const id = localStorage.getItem(organizationIdKey);
  return id ? Number(id) : null;
}

function setStoredSelectedOrganizationId(id: number | null) {
  if (id) {
    localStorage.setItem(organizationIdKey, id.toString());
  } else {
    localStorage.removeItem(organizationIdKey);
  }
}

function setStoredSelectedSchoolId(id: number | null) {
  if (id) {
    localStorage.setItem(schoolIdKey, id.toString());
  } else {
    localStorage.removeItem(schoolIdKey);
  }
}

export function logOut() {
  queryClient.clear();
  setStoredToken(null);
  setStoredUser(null);
  setStoredSelectedOrganizationId(null);
  setStoredSelectedSchoolId(null);
  sessionUserStore.getState().setUser(null);
  router.invalidate();
}

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const user = useStore(sessionUserStore, (state) => state.user);
  const setUser = useStore(sessionUserStore, (state) => state.setUser);
  const [selectedSchoolId, setSelectedSchoolId] = useState<number | null>(
    getStoredSelectedSchoolId()
  );
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<
    number | null
  >(getStoredSelectedOrganizationId());

  const isAuthenticated = !!user;

  const login = useCallback((user: SessionUser, token: string) => {
    setStoredUser(user);
    setStoredToken(token);
    setUser(user);
  }, []);

  useEffect(() => {
    setUser(getStoredUser());
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isPending: isAuthenticated === null,
        user,
        // token,
        selectedOrganizationId,
        selectedSchoolId,
        setSelectedOrganizationId,
        setSelectedSchoolId,
        login,
        logout: logOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export type SignedInAuthContextType = {
  isAuthenticated: boolean;
  isPending: boolean;
  user: SessionUser;
  selectedOrganizationId: number;
  selectedSchoolId: number;
  setSelectedOrganizationId: (id: number) => void;
  setSelectedSchoolId: (id: number) => void;
  login: (user: SessionUser, token: string) => void;
  logout: () => void;
};

function assertAuthenticated(
  state: AuthContextType
): asserts state is SignedInAuthContextType {
  if (
    state.isAuthenticated &&
    (state.user === null ||
      state.selectedSchoolId === null ||
      state.selectedOrganizationId === null)
  ) {
    throw new Error(
      "selectedSchoolId, selectedOrganizationId or user should not be null when authenticated"
    );
  }
}

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  assertAuthenticated(context);
  return context;
};
