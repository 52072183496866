import { Dialog, Group, Text, Title } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { Survey } from "posthog-js";
import { usePostHog } from "posthog-js/react";
import { useEffect, useState } from "react";
import MultipleChoice from "./components/MultipleChoice/MultipleChoice";
import SurveyRating from "./components/SurveyRating/SurveyRating";
import classes from "./styles.module.scss";
import notificationClasses from "./notificationStyles.module.scss";
import { IconHeart } from "@tabler/icons-react";
import { useLocation } from "@tanstack/react-router";

const showNotification = () =>
  notifications.show({
    message: (
      <Group gap="xs" c="white">
        <IconHeart size={20} />
        <Text fw="bold">Tack för ditt svar!</Text>
      </Group>
    ),
    position: "bottom-right",
    classNames: notificationClasses,
  });

function SkillsterSurvey() {
  const posthog = usePostHog();
  const [survey, setSurvey] = useState<Survey | null>(null);
  const [showSurvey, setShowSurvey] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (survey) return;
    posthog.getActiveMatchingSurveys((surveys) => {
      const activeSurveys = surveys.filter((survey) => survey.type === "api");

      for (let i = 0; i < activeSurveys.length; i++) {
        const currentSurvey = activeSurveys[i];
        const hasInteractedWithSurvey = localStorage.getItem(
          `hasInteractedWithSurvey_${currentSurvey?.id}`
        );
        if (hasInteractedWithSurvey) {
          continue;
        } else {
          setSurvey(currentSurvey);
          posthog.capture("survey shown", {
            $survey_id: currentSurvey.id,
            $survey_name: currentSurvey.name,
          });
          break;
        }
      }
    }, true);
  }, [posthog, location.pathname]);

  useEffect(() => {
    const hasInteractedWithSurvey = localStorage.getItem(
      `hasInteractedWithSurvey_${survey?.id}`
    );
    let timeout: NodeJS.Timeout;
    if (!hasInteractedWithSurvey) {
      timeout = setTimeout(() => {
        setShowSurvey(true);
      }, 3000);
    } else {
      setShowSurvey(false);
    }
    return () => clearTimeout(timeout);
  }, [survey]);

  if (!survey) return null;

  const onSubmit = (choice: string | string[]) => {
    posthog.capture("survey sent", {
      $survey_id: survey.id,
      $survey_name: survey.name,
      $survey_response: choice,
    });
    localStorage.setItem(`hasInteractedWithSurvey_${survey.id}`, "true");
    setShowSurvey(false);
    setSurvey(null);
    showNotification();
  };

  const onDismiss = () => {
    if (survey === null) return;
    setShowSurvey(false);
    setSurvey(null);
    localStorage.setItem(`hasInteractedWithSurvey_${survey.id}`, "true");
  };

  const firstQuestion = survey.questions[0];
  if (!firstQuestion) return null;

  return (
    <Dialog
      opened={showSurvey}
      withCloseButton
      onClose={onDismiss}
      transitionProps={{ transition: "fade-up" }}
      size={300}
      radius="lg"
      shadow="md"
      classNames={{ root: classes.root }}
    >
      <Title order={3} mb="xs" fw={"bold"}>
        {survey.name}
      </Title>
      <Text size="md" mb="xs" fw={500}>
        {firstQuestion.question}
      </Text>

      {firstQuestion.type === "rating" && (
        <SurveyRating question={firstQuestion} onSubmit={onSubmit} />
      )}
      {firstQuestion.type === "multiple_choice" && (
        <MultipleChoice question={firstQuestion} onSubmit={onSubmit} />
      )}
    </Dialog>
  );
}

export default SkillsterSurvey;
