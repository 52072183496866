import { createFileRoute } from '@tanstack/react-router'
import GroupEditCourses from '../../../../../features/Groups/GroupEditCourses/GroupEditCourses'

export const Route = createFileRoute('/(fullscreen)/groups/$id/edit/courses')({
  component: RouteComponent,
})

function RouteComponent() {
  const { id } = Route.useParams()
  return <GroupEditCourses id={Number(id)} />
}
