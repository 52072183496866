import { createFileRoute } from "@tanstack/react-router";
import SupportPage from "../../../pages/SupportPage/SupportPage";

export const Route = createFileRoute("/(app)/support/contact")({
  component: RouteComponent,
});

function RouteComponent() {
  return <SupportPage />;
}
