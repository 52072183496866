import { createFileRoute } from "@tanstack/react-router";
import AdminStatsPage from "../../../pages/Admin/AdminStatsPage/AdminStatsPage";

export const Route = createFileRoute("/(app)/admin/stats")({
  component: RouteComponent,
});

function RouteComponent() {
  return <AdminStatsPage />;
}
