import { AppShell } from "@mantine/core";
import {
  createFileRoute,
  Outlet,
  redirect,
  useMatch,
  useNavigate,
} from "@tanstack/react-router";
import * as React from "react";
import { useRef } from "react";
import ServerName from "../../components/ServerName/ServerName";
import SkillsterSpotlight from "../../components/SkillsterSpotlight/SkillsterSpotlight";
import Drawers from "../../features/Drawers/Drawers";
import Footer from "../../features/Layout/Footer/Footer";
import MobileMenu from "../../features/Layout/Header/components/MobileMenu/MobileMenu";
import Header from "../../features/Layout/Header/Header";
import { user_has_seen_welcome_screen } from "../../helpers/keyValueKeys";
import useHasSeen from "../../hooks/useHasSeen";
import useSkillsterStore from "../../store/app";
import AdminOnly from "../../features/Layout/AdminOnly/AdminOnly";
import useIsMobile from "../../hooks/useIsMobile";

export const Route = createFileRoute("/(app)")({
  component: AppComponent,
  beforeLoad: ({ context, location }) => {
    if (!context.auth.isPending && !context.auth.isAuthenticated) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
      });
    }
  },
});

function AppComponent() {
  const mobileMenuOpened = useSkillsterStore((state) => state.mobileMenuOpen);
  const navigate = useNavigate();
  const { hasNotSeenForSure } = useHasSeen({
    key: user_has_seen_welcome_screen,
  });
  const mobile = useIsMobile();

  const hasChecked = useRef(false);

  const isTicketPage = !!useMatch({
    from: "/(app)/admin/tickets/$id/",
    shouldThrow: false,
  });

  React.useEffect(() => {
    if (hasNotSeenForSure) {
      hasChecked.current = true;
      navigate({ to: "/welcome" });
    }
  }, [hasNotSeenForSure]);

  return (
    <>
      <Drawers />
      <SkillsterSpotlight />
      <AppShell
        header={{
          height: {
            base: 59,
            md: 80,
          },
        }}
        transitionDuration={0}
        navbar={{
          width: 300,
          breakpoint: "md",
          collapsed: { mobile: !mobileMenuOpened, desktop: true },
        }}
      >
        <AppShell.Header withBorder={false}>
          <Header />
        </AppShell.Header>
        <AppShell.Navbar bg="bluegray.9">
          {mobile && <MobileMenu />}
        </AppShell.Navbar>
        <AppShell.Main>
          <React.Suspense>
            <Outlet />
          </React.Suspense>
        </AppShell.Main>
        {!isTicketPage && <Footer />}
      </AppShell>
      <AdminOnly>
        <ServerName />
      </AdminOnly>
    </>
  );
}
