import { modals } from "@mantine/modals";
import CircleContainer from "../CircleContainer";
import FeedbackIcon from "./components/FeedbackIcon";
import FeedbackForm from "../FeedbackForm/FeedbackForm";
import { useAuth } from "../../../../../AuthContext";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mantine/core";
import useIsMobile from "../../../../../hooks/useIsMobile";

function FeedbackButton({ hidePlus }: { hidePlus?: boolean }) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const mobile = useIsMobile();
  function openModal() {
    modals.open({
      title: t("common:feedback.title"),
      children: <FeedbackForm user={user} />,
    });
  }
  return (
    <Tooltip
      label={t("common:feedbackTooltip")}
      openDelay={500}
      disabled={mobile}
    >
      <CircleContainer onClick={() => openModal()}>
        <FeedbackIcon hidePlus={hidePlus} />
      </CircleContainer>
    </Tooltip>
  );
}

export default FeedbackButton;
