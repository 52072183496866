import { createFileRoute } from "@tanstack/react-router";
import GroupsPage from "../../../pages/GroupsPage/GroupsPage";
import PageContainer from "../../../components/PageContainer/PageContainer";
import PageNotFoundComponent from "../../../components/RoutingErrors/PageNotFoundComponent/PageNotFoundComponent";
import ErrorComponent from "../../../components/RoutingErrors/ErrorComponent/ErrorComponent";

export const Route = createFileRoute("/(app)/groups/")({
  component: RouteComponent,
  notFoundComponent: () => (
    <PageContainer>
      <PageNotFoundComponent />
    </PageContainer>
  ),
  errorComponent: () => (
    <PageContainer>
      <ErrorComponent />
    </PageContainer>
  ),
});

function RouteComponent() {
  return <GroupsPage />;
}
