import { createFileRoute } from "@tanstack/react-router";
import AdminBillingPage from "../../../pages/Admin/AdminBillingPage/AdminBillingPage";

export const Route = createFileRoute("/(app)/admin/billing")({
  component: RouteComponent,
});

function RouteComponent() {
  return <AdminBillingPage />;
}
