import { createFileRoute } from "@tanstack/react-router";
import GroupPage from "../../../../pages/GroupPage/GroupPage";
import { Loader } from "@mantine/core";
import { useSuspenseQuery } from "@tanstack/react-query";
import { groupQueryOptions } from "../../../../api/useGroup";
import PageContainer from "../../../../components/PageContainer/PageContainer";
import ErrorComponent from "../../../../components/RoutingErrors/ErrorComponent/ErrorComponent";
import PageNotFoundComponent from "../../../../components/RoutingErrors/PageNotFoundComponent/PageNotFoundComponent";

export const Route = createFileRoute("/(app)/groups/$id")({
  loader: ({ params, context }) => {
    return context.queryClient.ensureQueryData(
      groupQueryOptions(Number(params.id))
    );
  },
  component: RouteComponent,
  notFoundComponent: () => (
    <PageContainer>
      <PageNotFoundComponent />
    </PageContainer>
  ),
  errorComponent: () => (
    <PageContainer>
      <ErrorComponent />
    </PageContainer>
  ),
  pendingComponent: () => (
    <PageContainer>
      <Loader color="blue.5" />
    </PageContainer>
  ),
});

function RouteComponent() {
  const { id } = Route.useParams();
  const { data: group } = useSuspenseQuery(groupQueryOptions(Number(id)));
  return <GroupPage group={group} />;
}
