import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";
import { useFortnoxCallback } from "../../api/billing/useFortnoxCallback";
import LoadingScreen from "../../features/Layout/LoadingScreen/LoadingScreen";

type FortnoxSearch = {
  code: string;
  state: string;
};

export const Route = createFileRoute("/oauth/fortnox/callback")({
  component: RouteComponent,
  validateSearch: (search: Record<string, unknown>): FortnoxSearch => {
    return {
      code: (search.code as string) || "",
      state: (search.state as string) || "",
    };
  },
});

function RouteComponent() {
  const navigate = useNavigate();
  const { code, state } = Route.useSearch();

  const { mutate } = useFortnoxCallback({
    onSuccess: () => navigate({ to: "/admin/billing" }),
  });

  useEffect(() => {
    mutate({ code, state });
  }, [code, state]);

  return <LoadingScreen />;
}
