import { Group, Text, UnstyledButton, useMantineTheme } from "@mantine/core";
import { Icon, Icon2fa, IconProps } from "@tabler/icons-react";
import {
  ForwardRefExoticComponent,
  ReactNode,
  RefAttributes,
  forwardRef,
} from "react";
import classes from "./styles.module.css";

export interface ClickItemProps extends React.HTMLAttributes<HTMLDivElement> {
  icon?: ForwardRefExoticComponent<IconProps & RefAttributes<Icon>>;
  label: string;
  adminItem?: boolean;
  rightSection?: ReactNode;
}

const ClickItem = forwardRef<HTMLButtonElement, ClickItemProps>(
  ({ icon: Icon = Icon2fa, rightSection, adminItem, label, ...props }, ref) => {
    const theme = useMantineTheme();

    return (
      <UnstyledButton ref={ref}>
        <Group {...props} justify="space-between" className={classes.linkItem}>
          <Group gap={8} wrap="nowrap" px="md" py={6} mx={5}>
            <Icon
              width={14}
              color={theme.colors.bluegray[0]}
              strokeWidth={1.5}
            />
            <Text fz={12} fw={500} c="bluegray.0">
              {label}
            </Text>
          </Group>
          {rightSection}
        </Group>
      </UnstyledButton>
    );
  }
);

export default ClickItem;
