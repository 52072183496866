import { useState } from "react";
import { useOrganizationList } from "../../../../../../api/useOrganizationList";
import useChangeOrganization from "../../../../../../hooks/useChangeOrganization";
import Base from "../Base/Base";
import OrganizationOption from "./components/OrganizationOption";
import { useAuth } from "../../../../../../AuthContext";

function OrganizationNavigation({ withLabel }: { withLabel?: boolean }) {
  const changeOrganization = useChangeOrganization();
  const { selectedOrganizationId, user } = useAuth();

  const isInOwnOrganization = user?.OrganizationID === selectedOrganizationId;

  const { data, isPending, isError } = useOrganizationList();

  const selectedOrganization = data?.map.get(selectedOrganizationId);

  const [search, setSearch] = useState("");

  if (isPending)
    return (
      <Base
        disabled
        options={[]}
        isPending
        label={withLabel ? "Organisation" : undefined}
        onChange={onChange}
        selectedName={selectedOrganization?.Name}
        placeholder="Sök organisation"
      />
    );

  if (isError) return <>Error</>;

  const options =
    data?.list
      .filter((org) =>
        org.Name.toLowerCase().includes(search.toLowerCase().trim())
      )
      .map((org) => (
        <OrganizationOption
          key={org.ID}
          organizationId={org.ID}
          schoolCount={org.SchoolCount}
          organizationName={org.Name}
          simulatorCount={org.SimulatorCount}
          active={selectedOrganization?.ID === org.ID}
        />
      )) ?? [];

  function onChange(id: number | null) {
    if (id === null) return;
    changeOrganization(id);
  }

  return (
    <Base
      options={options}
      searchProps={{ search, setSearch }}
      label={withLabel ? "Organisation" : undefined}
      onChange={onChange}
      selectedName={selectedOrganization?.Name}
      placeholder="Sök organisation"
      highlight={!isInOwnOrganization}
    />
  );
}

export default OrganizationNavigation;
