import { createFileRoute, redirect } from "@tanstack/react-router";
import { showDraftLockStatusFn } from "../../api/useLockStatus";
import CourseEditor from "../../components/CourseEditor/CourseEditor";

export const Route = createFileRoute("/course-editor/$publicUuid")({
  component: RouteComponent,
  validateSearch: (
    search: Record<string, any>
  ): {
    id: string;
  } => ({ id: search.id ?? "" }),
  beforeLoad: ({ params }) => ({
    showLockStatus: () =>
      showDraftLockStatusFn({ publicDraftUuid: params.publicUuid }),
  }),
  loader: async ({ context: { showLockStatus } }) => await showLockStatus(),
  onError: () => {
    throw redirect({ to: "/courses" });
  },
});

function RouteComponent() {
  const { id } = Route.useSearch();
  const lock = Route.useLoaderData();
  return <CourseEditor id={Number(id)} lock={lock} />;
}
