import { createFileRoute } from "@tanstack/react-router";
import SimulatorsPage from "../../pages/SimulatorsPage/SimulatorsPage";

export const Route = createFileRoute("/(app)/simulators")({
  component: RouteComponent,
});

function RouteComponent() {
  return <SimulatorsPage />;
}
