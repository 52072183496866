import { ReactNode } from "react";
import {
  useIsAdminUser,
  useIsAdminUserOnSkillsterOrganization,
  useIsAdminUserOnSkillsterSchool,
} from "../../../helpers/server.helpers";

function AdminOnly({
  children,
  restrictToSkillsterOrg,
  restrictToSkillsterSchool,
}: {
  children?: ReactNode;
  restrictToSkillsterOrg?: boolean;
  restrictToSkillsterSchool?: boolean;
}) {
  const isAdminUser = useIsAdminUser();
  const isAdminUserOnSkillsterOrg = useIsAdminUserOnSkillsterOrganization();
  const isAdminUserOnSkillsterSchool = useIsAdminUserOnSkillsterSchool();

  if (restrictToSkillsterSchool)
    return isAdminUserOnSkillsterSchool ? children : null;

  if (restrictToSkillsterOrg)
    return isAdminUserOnSkillsterOrg ? children : null;

  return isAdminUser ? children : null;
}

export default AdminOnly;
