import { Button, Flex, Stack, Text } from "@mantine/core";
import { RatingSurveyQuestion } from "posthog-js";
import { useState } from "react";
import rating1 from "./assets/png/1.png";
import rating2 from "./assets/png/2.png";
import rating3 from "./assets/png/3.png";
import rating4 from "./assets/png/4.png";
import rating5 from "./assets/png/5.png";
import classes from "./styles.module.scss";
import clsx from "clsx";

function SurveyRating({
  onSubmit,
  question,
}: {
  question: RatingSurveyQuestion;
  onSubmit: (value: string) => void;
}) {
  const [value, setValue] = useState(0);

  const ratings = [rating1, rating2, rating3, rating4, rating5];

  return (
    <Stack gap="xs">
      <Stack gap={5}>
        <Flex justify="space-between" align="center">
          {ratings.map((rating, index) => (
            <div
              key={rating}
              className={clsx({
                [classes.option]: true,
                [classes.active]: value === index + 1,
              })}
              onClick={() => setValue(index + 1)}
            >
              <img
                src={rating}
                className={clsx({
                  [classes.emoji]: true,
                  [classes.active]: value === index + 1,
                })}
              />
            </div>
          ))}
        </Flex>
        <Flex justify="space-between" align="center">
          <Text size="xs">{question.lowerBoundLabel}</Text>
          <Text size="xs">{question.upperBoundLabel}</Text>
        </Flex>
      </Stack>
      <Button
        disabled={value === 0}
        onClick={() => onSubmit(value.toString())}
        classNames={{ root: classes.button }}
      >
        Skicka
      </Button>
    </Stack>
  );
}

export default SurveyRating;
