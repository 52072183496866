import { fetchLang, fiLocale, svLocale } from "./language.helpers";
import { format } from "date-fns";
import { enGB, sv, fi } from "date-fns/locale";
import { MONTHS_SHORT } from "./constants";
import { CompetitionStatus } from "../api/useCompetitions";

export const locale = () => (svLocale() ? sv : fiLocale() ? fi : enGB);

export function formatSecondsToReadable(
  durationInSeconds?: number,
  showSeconds?: boolean
) {
  if (durationInSeconds === undefined) return null;

  const duration = {
    hours: Math.floor(durationInSeconds / 3600),
    minutes:
      Math.floor(durationInSeconds / 3600) < 1 && !showSeconds
        ? Math.ceil((durationInSeconds % 3600) / 60)
        : Math.floor((durationInSeconds % 3600) / 60),
    seconds: Math.floor(durationInSeconds % 60),
  };

  const hours = duration.hours;
  const minutes = duration.minutes;
  const h = svLocale() ? "tim" : "h";
  const m = "min";
  const mDisplay =
    showSeconds && duration.minutes === 0 ? "" : `${minutes} ${m}`;
  const sDisplay = duration.seconds + " s";
  const hDisplay = hours > 0 ? `${hours.toLocaleString(fetchLang())} ${h}` : "";
  return `${hDisplay} ${mDisplay} ${showSeconds ? sDisplay : ""}`;
}

export function formatDateToReadable(
  date?: Date,
  dateFormat: string = "EEEE dd LLL hh:mm"
) {
  if (!date) return "null";
  return format(date, dateFormat, { locale: locale() });
}

export const dateStringToReadable = (
  date: string,
  alwaysShowYear = false,
  fallback?: string
) =>
  date
    ? dateObjectToReadable(new Date(safariDateFix(date)), alwaysShowYear)
    : (fallback ?? "");

export function formatFirebaseTimestamp(date: Date) {
  const isThisYear = date.getFullYear() === new Date().getFullYear();
  return format(date, `dd MMM ${!isThisYear ? "yyyy" : ""}, kk:mm`, {
    locale: svLocale() ? sv : fiLocale() ? fi : enGB,
  });
}

const today = new Date();
export const dateObjectToReadable = (date: Date, alwaysShowYear = false) =>
  `${date.getDate()} ${MONTHS_SHORT()[date.getMonth()]}${
    today.getFullYear() > date.getFullYear() || alwaysShowYear
      ? ` ${date.getFullYear()}`
      : ""
  }`;
export const dateObjectToReadableWithYear = (date: Date) =>
  dateObjectToReadable(date, true);

export function formatLastActive(lastActive?: string, fallback?: string) {
  if (!lastActive || lastActive === "") return fallback ?? "-";

  return dateObjectToReadable(new Date(lastActive), true);
}

const replaceAt = (string: string, index: number, replacement: string) => {
  if (index >= string.length) return string;
  return string.substring(0, index) + replacement + string.substring(index + 1);
};

export const safariDateFix = (timestamp: string) =>
  timestamp.charAt(10) === " " ? replaceAt(timestamp, 10, "T") : timestamp;

export function convertDateToYYYYMMDD(date: Date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export function formatHighscoreRuntime(secondsWithDecimals: number) {
  const h = Math.floor(secondsWithDecimals / 3600);
  const m = Math.floor((secondsWithDecimals % 3600) / 60);
  const s = Math.floor((secondsWithDecimals % 3600) % 60);

  const hDisplay = h < 1 ? "" : h < 10 ? "0" + h : h;
  const mDisplay = m < 10 ? "0" + m : m;
  const sDisplay = s < 10 ? "0" + s : s;

  return `${hDisplay ? `${hDisplay}:` : ""}${mDisplay}:${sDisplay}`;
}

export const dateDifferenceInMs = (dateInitial: Date, dateFinal: Date) =>
  dateFinal.getTime() - dateInitial.getTime();

export const dateDifferenceInDays = (date1: Date, date2: Date) =>
  Math.round((date2.getTime() - date1.getTime()) / (1000 * 3600 * 24));

export const addOneDayToDate = (date: Date) => {
  const nextDate = new Date(date);
  nextDate.setDate(nextDate.getDate() + 1);
  return nextDate;
};

export function getCompetitionStatus({
  startTime,
  endTime,
}: {
  startTime: string;
  endTime: string;
}): CompetitionStatus {
  const now = new Date();
  const start = new Date(startTime);
  const end = new Date(endTime);

  if (now.getTime() > start.getTime() && now.getTime() < end.getTime())
    return "active";

  if (now.getTime() > end.getTime()) return "past";

  return "coming";
}

export function getMondayOfThisWeek() {
  const d = new Date();
  const day = d.getDay();
  const diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}

export function getSundayOfThisWeek() {
  const sunday = getMondayOfThisWeek();
  sunday.setDate(sunday.getDate() + 6);
  return sunday;
}

export function getFirstDayOfThisMonth() {
  const firstDay = new Date();
  firstDay.setDate(1);
  return firstDay;
}

export function getLastDayOfThisMonth() {
  const lastDay = new Date();
  lastDay.setDate(1);
  lastDay.setMonth(lastDay.getMonth() + 1);
  lastDay.setDate(lastDay.getDate() - 1);
  return lastDay;
}

export function datesAreOnSameDay(a: Date, b: Date) {
  return (
    a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDate() === b.getDate()
  );
}
