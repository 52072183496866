export const ACTIVATE_PATH = "/activate";
export const HOME_PATH = "/home";
export const LOGIN_PATH = "/login";
export const GROUPS_PATH = "/groups";

export const HOME_START_PATH = `${HOME_PATH}`;
export const HOME_STATS_PATH = `${HOME_PATH}/stats`;
export const HOME_NEWS_PATH = `${HOME_PATH}/news`;
export const HOME_YOUTUBE_PATH = `${HOME_PATH}/youtube`;
export const HOME_ACADEMY_PATH = `${HOME_PATH}/academy`;

export const ACADEMY_DETAILS_PATH = `${HOME_ACADEMY_PATH}/:lectureId/:segmentId`;

export const GROUP_PATH = `${GROUPS_PATH}/:id`;
export const GROUP_COURSES_PATH = `${GROUPS_PATH}/:id/courses`;
export const GROUP_DRIVING_HISTORY_PATH = `${GROUPS_PATH}/:id/driving-history`;
export const GROUP_TACHOGRAPH_PATH = `${GROUPS_PATH}/:id/tachograph`;
export const GROUP_EDIT_COURSES = `${GROUP_PATH}/edit/courses`;
export const GROUP_EDIT_USERS_PATH = `${GROUP_PATH}/edit/users`;
export const GROUP_EDIT_SETTINGS_PATH = `${GROUP_PATH}/edit/settings`;
export const GROUP_CREATE_PATH = `${GROUPS_PATH}/create-new`;

export const USERS_PATH = "/users";
export const USERS_CREATE_PATH = `${USERS_PATH}/create-user`;
export const USERS_IMPORT_PATH = `${USERS_PATH}/import-users`;

export const USER_PATH = `${USERS_PATH}/:id`;
export const USER_DRIVING_RECORD_PATH = `${USERS_PATH}/:id/driving-record`;
export const USER_COURSES_PATH = `${USERS_PATH}/:id/courses`;
export const USER_PROFILE_PATH = `${USERS_PATH}/:id/profile`;
export const USER_TACHOGRAPH_PROGRESS_PATH = `${USERS_PATH}/:id/tachograph`;
export const USER_EDIT_COURSES_PATH = `${USERS_PATH}/:id/edit/courses`;
export const USER_EDIT_PERSONAL_INFO_PATH = `${USERS_PATH}/:id/edit/personal-info`;
export const USER_EDIT_ACCOUNT_PATH = `${USERS_PATH}/:id/edit/account`;

export const COURSES_PATH = "/courses";
export const COURSES_CUSTOM_PATH = `${COURSES_PATH}/custom`;

export const NATIVE_COURSE_PATH = `${COURSES_PATH}/:publicCourseUuid`;
export const CUSTOM_COURSE_PATH = `${COURSES_CUSTOM_PATH}/:publicCourseUuid`;
export const SIMULATORS_PATH = "/simulators";
export const SUPPORT_PATH = "/support";
export const INSTALLATION_GUIDE_PATH = `${SUPPORT_PATH}/installation`;

export const ADMIN_PATH = "/admin";
export const ADMIN_TICKETS_BASE_PATH = `${ADMIN_PATH}/tickets`;
export const ADMIN_BUGREPORT_PATH = `${ADMIN_TICKETS_BASE_PATH}/bugreport`;
export const ADMIN_FEEDBACK_PATH = `${ADMIN_TICKETS_BASE_PATH}/feedback`;
export const ADMIN_TICKETS_PATH = `${ADMIN_TICKETS_BASE_PATH}/:type`;
export const ADMIN_TICKET_BASE_PATH = `${ADMIN_PATH}/tickets`;
export const ADMIN_TICKET_PATH = `${ADMIN_PATH}/ticket/:id`;
export const ADMIN_GITHUB_CALLBACK_PATH = "/oauth/github/callback";
export const ADMIN_STATS_PATH = `${ADMIN_PATH}/stats`;
export const ADMIN_ALL_SIMULATORS_PATH = `${ADMIN_PATH}/simulators`;
export const ADMIN_ORGANIZATIONS_PATH = `${ADMIN_PATH}/organizations`;
export const ADMIN_SCHOOLS_PATH = `${ADMIN_PATH}/schools`;
export const ADMIN_ORGANIZATION_PATH = `${ADMIN_PATH}/organizations/:id`;
export const ADMIN_SCHOOL_PATH = `${ADMIN_PATH}/organizations/:organizationId/schools/:schoolId`;
export const ADMIN_ALL_USERS_PATH = `${ADMIN_PATH}/users`;
export const ADMIN_MAP_PATH = `${ADMIN_PATH}/map`;
export const ADMIN_TOPLIST_PATH = `${ADMIN_PATH}/toplist`;
export const ADMIN_COURSE_EDITOR_STATS_PATH = `${ADMIN_PATH}/course-editor-stats`;
export const ADMIN_RUNDATA_PATH = `${ADMIN_PATH}/rundata`;
export const ADMIN_EXERCISE_COMMENTS_PATH = `${ADMIN_PATH}/exercise-comments`;
export const ADMIN_BILLING_PATH = `${ADMIN_PATH}/billing`;
export const ADMIN_FORTNOX_CALLBACK = "/oauth/fortnox/callback";
export const ADMIN_COMPETITIONS_PATH = `${ADMIN_PATH}/competitions`;
export const ADMIN_COMPETITION_PATH = `${ADMIN_COMPETITIONS_PATH}/:id`;
export const ADMIN_CREATE_COMPETITION_PATH = `${ADMIN_PATH}/create-competition`;

export const ADMIN_ACADEMY_PATH = `${ADMIN_PATH}/academy`;
export const ADMIN_ACADEMY_TAB_PATH = `${ADMIN_ACADEMY_PATH}/:tab`;
export const ADMIN_ACADEMY_COURSES = `${ADMIN_PATH}/courses`;
export const ADMIN_ACADEMY_COURSE_PATH = `${ADMIN_ACADEMY_TAB_PATH}/:lectureId`;
export const ADMIN_TEACHER_LECTURES_STATS_FOR_SCHOOL = `${ADMIN_ACADEMY_TAB_PATH}/:lectureId/:schoolId`;

export const ADMIN_CONTESTS_PATH = `${ADMIN_PATH}/contests`;
export const ADMIN_CONTEST_PATH = `${ADMIN_CONTESTS_PATH}/:id`;
export const ADMIN_CREATE_CONTEST_PATH = `${ADMIN_PATH}/contests/create`;
export const ADMIN_TACHOGRAPH_EXERCISES = `${ADMIN_PATH}/tachograph-exercises/`;
export const ADMIN_EDIT_TACHOGRAPH_EXERCISE_PATH = `${ADMIN_PATH}/edit-tachograph-exercise/:id`;
export const EDIT_LECTURE_BASEPATH = `${ADMIN_ACADEMY_COURSES}/edit`;
export const EDIT_LECTURE_PATH = `${EDIT_LECTURE_BASEPATH}/:id`;

export const COURSE_EDITOR_PATH = `/course-editor`;
