import { IconLogout } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../../../../../../AuthContext";
import ClickItem from "../LinkItem/ClickItem";

function LogoutLink() {
  const { t } = useTranslation(["common"]);
  const { logout } = useAuth();

  async function handleLogOut() {
    logout();
  }

  return (
    <ClickItem
      label={t("buttons.logOut")}
      icon={IconLogout}
      onClick={handleLogOut}
    />
  );
}

export default LogoutLink;
