import { create } from "zustand";

export type SessionUser = {
  ID: number;
  FirstName: string;
  LastName: string;
  Email: string;
  Role: number;
  SchoolClassID: number;
  SchoolID: number;
  OrganizationID: number;
};

type SessionUserState = {
  user: SessionUser | null;
  setUser: (user: SessionUser | null) => void;
};

const sessionUserStore = create<SessionUserState>()((set) => ({
  user: null,
  setUser: (user: SessionUser | null) => set(() => ({ user })),
}));

export default sessionUserStore;
