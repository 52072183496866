import { Cloudinary } from "@cloudinary/url-gen";
import "@mantine/carousel/styles.css";
import "@mantine/charts/styles.css";
import { CSSVariablesResolver, MantineProvider, rem } from "@mantine/core";
import "@mantine/core/styles.css";
import { DatesProvider } from "@mantine/dates";
import "@mantine/dates/styles.css";
import "@mantine/dropzone/styles.css";
import { ModalsProvider } from "@mantine/modals";
import {
  Notifications,
  notifications,
  notificationsStore,
} from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import { NavigationProgress } from "@mantine/nprogress";
import "@mantine/nprogress/styles.css";
import "@mantine/spotlight/styles.css";
import "@mantine/tiptap/styles.css";
import { IconCheck, IconX } from "@tabler/icons-react";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import "dayjs/locale/en";
import "dayjs/locale/fi";
import "dayjs/locale/sv";
import i18next from "i18next";
import posthog from "posthog-js";
import { StrictMode, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { AuthProvider, logOut, useAuth } from "./AuthContext";
import LoadingScreen from "./features/Layout/LoadingScreen/LoadingScreen";
import { useFetchLang } from "./helpers/language.helpers";
import { NetworkError } from "./helpers/request.helpers";
import "./i18n";
import { routeTree } from "./routeTree.gen";
import "./styles.scss";
import errorNotificationClasses from "./theme/notification.error.module.scss";
import successNotificationClasses from "./theme/notification.success.module.scss";
import { SKILLSTER_THEME } from "./theme/theme";

window.addEventListener("vite:preloadError", (event) => {
  window.location.reload();
});

// if (import.meta.env.VITE_IS_LOCAL_HOST !== "true") {
//   posthog.init(import.meta.env.VITE_PUBLIC_POSTHOG_KEY, {
//     api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
//   });
// }
posthog.init(import.meta.env.VITE_PUBLIC_POSTHOG_KEY, {
  api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
});

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

export const router = createRouter({
  scrollRestoration: true,
  routeTree,
  context: {
    auth: undefined!,
    queryClient: undefined!,
  },
});

function InnerApp() {
  const auth = useAuth();
  const fetchLang = useFetchLang();
  return (
    <DatesProvider
      settings={{
        locale: fetchLang.slice(0, 2),
      }}
    >
      <RouterProvider router={router} context={{ auth, queryClient }} />
    </DatesProvider>
  );
}

export const cld = new Cloudinary({
  cloud: {
    cloudName: "dy7i8hekz",
  },
});

i18next.on("languageChanged", (lng) => {
  document.documentElement.setAttribute("lang", lng);
});

const xIcon = <IconX style={{ width: rem(20), height: rem(20) }} />;
const checkIcon = <IconCheck style={{ width: rem(20), height: rem(20) }} />;

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError(error, query) {
      console.warn(error);
      if (error instanceof NetworkError) {
        if (error.StatusCode === 401) {
          logOut();
        }
      }
    },
    onSuccess(data, query) {
      if (query.meta?.refetchSimulatorsHardware) {
        queryClient.refetchQueries({ queryKey: ["get-simulators-hardware"] });
      }
      if (query.meta?.invalidateExercises) {
        queryClient.invalidateQueries({ queryKey: ["exercises"] });
      }
    },
  }),
  defaultOptions: {
    queries: {
      retry(failureCount, error) {
        if (error instanceof NetworkError && error.StatusCode === 401) {
          return false;
        }
        return failureCount < 3;
      },
    },
  },
  mutationCache: new MutationCache({
    onSuccess(data, variables, context, mutation) {
      const successMessage = mutation.meta?.successMessage as
        | { title: string; message: string }
        | undefined;

      if (successMessage) {
        const duplicate = notificationsStore
          .getState()
          .notifications.find(
            (n) =>
              n.title === successMessage.title &&
              typeof n.message === "string" &&
              removeCountFromMessage(n.message) === successMessage.message
          );
        if (duplicate && typeof duplicate.message === "string") {
          notifications.update({
            ...duplicate,
            message: updateMessageCount(duplicate.message),
          });
        } else {
          notifications.show({
            title: successMessage?.title,
            message: successMessage?.message,
            position: "bottom-center",
            classNames: successNotificationClasses,
            icon: checkIcon,
            color: "bluegray.9",
          });
        }
      }
    },
    onError(error: any, variables, context, mutation) {
      const errorMessages = mutation.meta?.errorMessages as
        | Record<string, Record<string, string>>
        | undefined;

      const statusCodesToIgnoreGlobally = mutation.meta
        ?.statusCodesToIgnoreGlobally as number[] | undefined;

      const errorMessage =
        errorMessages?.[error.Response.Reason] ??
        errorMessages?.[error.StatusCode] ??
        {};

      if (statusCodesToIgnoreGlobally?.includes(error.StatusCode)) {
        return;
      }
      console.warn(error);

      const defaultErrorTitle = i18next.t(
        "common:notifications.errorMessages.somethingWentWrong"
      );
      let defaultErrorMessage = i18next.t(
        "common:notifications.errorMessages.pleaseTryAgain"
      );

      if (error instanceof NetworkError) {
        if (error.StatusCode === 403) {
          defaultErrorMessage = i18next.t(
            "common:notifications.errorMessages.missingPermissions"
          );
        }
      }

      notifications.show({
        title: errorMessage.title ?? defaultErrorTitle,
        message: errorMessage.message ?? defaultErrorMessage,
        classNames: errorNotificationClasses,
        color: "red.2",
        icon: xIcon,
        position: "bottom-center",
      });
    },
  }),
});

const resolver: CSSVariablesResolver = (theme) => ({
  variables: {
    "--mantine-color-dimmed": theme.colors.bluegray[5],
  },
  light: {},
  dark: {},
});

const rootElement = document.getElementById("root")!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <MantineProvider
          theme={SKILLSTER_THEME}
          cssVariablesResolver={resolver}
        >
          <Notifications position="top-center" zIndex={1000} />
          <ModalsProvider>
            <NavigationProgress />
            <Suspense fallback={<LoadingScreen />}>
              <AuthProvider>
                <InnerApp />
              </AuthProvider>
            </Suspense>
          </ModalsProvider>
        </MantineProvider>
      </QueryClientProvider>
    </StrictMode>
  );
}

function removeCountFromMessage(message?: string) {
  return message?.replace(/ \(\d+\)$/, "");
}

function updateMessageCount(message: string): string {
  const match = message.match(/ \((?<count>\d+)\)$/);
  if (match && match.groups?.count) {
    const currentCount = parseInt(match.groups.count, 10);
    return `${removeCountFromMessage(message)} (${currentCount + 1})`;
  } else {
    return `${message} (2)`;
  }
}
