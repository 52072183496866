import { Text, Tooltip } from "@mantine/core";
import { useOnlineUsers } from "../../../../../api/useOnlineUsers";
import CircleContainer from "../CircleContainer";
import { Link } from "@tanstack/react-router";
import useOnlineUsersTooltip from "./hooks";
import classes from "./styles.module.css";
import useIsMobile from "../../../../../hooks/useIsMobile";
import useSkillsterStore from "../../../../../store/app";

function OnlineCounter() {
  const onlineUsersQuery = useOnlineUsers();
  const count = onlineUsersQuery.data?.list.length ?? 0;
  const onlineUsersTooltip = useOnlineUsersTooltip();
  const mobile = useIsMobile();
  const setMobileOpened = useSkillsterStore((state) => state.setMobileMenuOpen);

  return (
    <Link
      to="/users"
      search={{
        sort: count > 0 ? "online" : undefined,
      }}
      mask={{
        to: "/users",
      }}
      onClick={() => setMobileOpened(false)}
    >
      <Tooltip label={onlineUsersTooltip} openDelay={500} disabled={mobile}>
        <CircleContainer>
          <>
            <svg
              width="12"
              height="13"
              viewBox="0 0 12 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginTop: -2 }}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.02236 3.00908C9.02236 4.53639 7.78423 5.77452 6.25693 5.77452C4.72962 5.77452 3.4915 4.53639 3.4915 3.00908C3.4915 1.48178 4.72962 0.243652 6.25693 0.243652C7.78423 0.243652 9.02236 1.48178 9.02236 3.00908ZM11.7878 11.3052V12.6879H0.726074V11.3052C0.726074 10.5718 1.01743 9.86838 1.53605 9.34976C2.05467 8.83114 2.75807 8.53978 3.49151 8.53978H9.02237C9.75581 8.53978 10.4592 8.83114 10.9778 9.34976C11.4964 9.86838 11.7878 10.5718 11.7878 11.3052Z"
                fill="white"
              />
            </svg>

            <div
              className={`${classes.sonar} ${count > 0 ? classes.animate : ""}`}
            >
              <Text
                fw={700}
                fz={10}
                c={count > 0 ? "bluegray.6" : "bluegray.9"}
              >
                {count}
              </Text>
            </div>
          </>
        </CircleContainer>
      </Tooltip>
    </Link>
  );
}

export default OnlineCounter;
