import { createFileRoute } from "@tanstack/react-router";
import StylepointToplistPage from "../../../pages/Admin/StylepointToplistPage/StylepointToplistPage";

export const Route = createFileRoute("/(app)/admin/toplist")({
  component: RouteComponent,
});

function RouteComponent() {
  return <StylepointToplistPage />;
}
