import { createFileRoute } from "@tanstack/react-router";
import TicketPage from "../../../../../pages/Admin/TicketPage/TicketPage";

export const Route = createFileRoute("/(app)/admin/tickets/$id/")({
  component: RouteComponent,
});

function RouteComponent() {
  const { id } = Route.useParams();
  return <TicketPage id={Number(id)} />;
}
