import { createFileRoute } from "@tanstack/react-router";
import AllSimulatorsPage from "../../../pages/Admin/AllSimulatorsPage/AllSimulatorsPage";

export const Route = createFileRoute("/(app)/admin/simulators")({
  component: RouteComponent,
});

function RouteComponent() {
  return <AllSimulatorsPage />;
}
