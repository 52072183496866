import { useEffect, useState } from "react";
import { useOnlineUsers } from "../../../../../api/useOnlineUsers";
import { useTranslation } from "react-i18next";
import { useListSchoolUsers } from "../../../../../api/useUsersFromSchool";

function useOnlineUsersTooltip() {
  const { t } = useTranslation();

  const { data: usersData } = useListSchoolUsers();

  const onlineUsersQuery = useOnlineUsers();
  const [onlineUsersTooltip, setOnlineUsersTooltip] = useState<
    string | undefined
  >(undefined);

  const user =
    onlineUsersQuery.data?.list?.length && !!usersData
      ? usersData.map.get(onlineUsersQuery.data.list[0].ID)
      : undefined;

  useEffect(() => {
    if (onlineUsersQuery.isPending || onlineUsersQuery.isError) {
      setOnlineUsersTooltip(undefined);
    } else {
      const count = onlineUsersQuery.data?.list.length ?? 0;
      let string = "";
      if (count === 0 || !count) {
        string = t("header.onlineCounter.noOne");
      } else if (count === 1) {
        string = t("header.onlineCounter.one", {
          name: user?.FirstName,
        });
      } else {
        string = t("header.onlineCounter.twoOrMore", {
          name: user?.FirstName,
          otherCount: count - 1,
        });
      }
      setOnlineUsersTooltip(string);
    }
  }, [t, user, onlineUsersQuery.data]);
  return onlineUsersTooltip;
}

export default useOnlineUsersTooltip;
