import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/(app)/users/$id/")({
  beforeLoad: ({ params }) => {
    throw redirect({
      to: "/users/$id/courses",
      params,
      replace: true,
    });
  },
});
