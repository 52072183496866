import { createFileRoute } from '@tanstack/react-router'
import EditUserAccount from '../../../../../features/User/Edit/EditUserAccount/EditUserAccount'

export const Route = createFileRoute('/(fullscreen)/users/$id/edit/account')({
  component: RouteComponent,
})

function RouteComponent() {
  const { id } = Route.useParams()
  return <EditUserAccount id={Number(id)} />
}
