import { Group, Paper, PaperProps } from "@mantine/core";
import useMenuItems from "../../../../../hooks/useMenuItems";
import MenuItem from "./components/MenuItem";

function HeaderMenuItems(props: PaperProps) {
  const items = useMenuItems();
  return (
    <Paper p="md" bg="bluegray.8" {...props}>
      <Group>
        {items.map((item) => (
          <MenuItem key={item.label} to={item.to} label={item.label} />
        ))}
      </Group>
    </Paper>
  );
}

export default HeaderMenuItems;
