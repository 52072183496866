import { Group, Text } from "@mantine/core";
import { Icon, Icon2fa, IconProps } from "@tabler/icons-react";
import { Link } from "@tanstack/react-router";
import {
  ForwardRefExoticComponent,
  ReactNode,
  RefAttributes,
  forwardRef,
} from "react";
import useSkillsterStore from "../../../../../../../../../store/app";
import classes from "./styles.module.css";

export interface LinkItemProps extends React.HTMLAttributes<HTMLDivElement> {
  icon?: ForwardRefExoticComponent<IconProps & RefAttributes<Icon>>;
  label: string;
  adminItem?: boolean;
  rightSection?: ReactNode;
  to: string;
}

const LinkItem = forwardRef<HTMLAnchorElement, LinkItemProps>(
  (
    { icon: Icon = Icon2fa, rightSection, to, adminItem, label, ...props },
    ref
  ) => {
    const setMobileMenuOpen = useSkillsterStore(
      (state) => state.setMobileMenuOpen
    );

    return (
      <Link ref={ref} to={to} onClick={() => setMobileMenuOpen(false)}>
        <Group {...props} justify="space-between" className={classes.linkItem}>
          <Group gap={8} wrap="nowrap" px="md" py={6} mx={5}>
            <Icon
              width={14}
              color="var(--mantine-color-bluegray-0)"
              strokeWidth={1.5}
            />
            <Text fz={12} fw={500} c="bluegray.0">
              {label}
            </Text>
          </Group>
          {rightSection}
        </Group>
      </Link>
    );
  }
);

export default LinkItem;
