import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/(app)/groups/$id/")({
  beforeLoad: ({ params }) => {
    throw redirect({
      to: "/groups/$id/courses",
      params: { id: params.id },
      replace: true,
    });
  },
});
