import { Box } from "@mantine/core";
import { forwardRef, type JSX } from "react";
import classes from "./styles.module.css"
interface CircleContainerProps extends React.ComponentPropsWithoutRef<'div'> {
    children: JSX.Element;
    onClick?: () => void;
}

const CircleContainer = forwardRef<HTMLDivElement, CircleContainerProps>(
    ({ children, onClick }: CircleContainerProps, ref) => (
        <Box
            ref={ref}
            onClick={onClick}
            className={classes.container}
        >
            {children}
        </Box>
    )
);

export default CircleContainer