import { Center, Loader, Portal, Stack } from "@mantine/core";
import LogoSkillster from "../LogoSkillster";
import classes from "./styles.module.css";

function LoadingScreen() {
  return (
    <Portal>
      <div className={classes.container}>
        <Center style={{ height: "100%" }}>
          <Stack align="center">
            <LogoSkillster width={150} />
            <Loader variant="dots" color="white" />
          </Stack>
        </Center>
      </div>
    </Portal>
  );
}

export default LoadingScreen;
