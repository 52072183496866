import { createFileRoute } from '@tanstack/react-router'
import FirstLoginWelcomeScreen from '../../pages/FirstLoginWelcomeScreen/FirstLoginWelcomeScreen'

export const Route = createFileRoute('/(fullscreen)/welcome')({
  component: RouteComponent,
})

function RouteComponent() {
  return <FirstLoginWelcomeScreen />
}
