import { createFileRoute, Outlet, useMatch } from "@tanstack/react-router";
import SubBar from "../../../features/Layout/SubBar/SubBar";
import SubMenu from "../../../features/Layout/SubMenu/SubMenu";
import { useAdminMenuItems } from "../../../features/Layout/Header/components/ProfileDropdown/components/Dropdown/components/AdminMenu/AdminMenu";
import { licenseUpdatesFilterKey } from "../../../pages/Admin/LicenseUpdatesPage/LicenseUpdatesPage";

export const Route = createFileRoute("/(app)/admin")({
  component: RouteComponent,
});

function RouteComponent() {
  const adminMenuItems = useAdminMenuItems();
  const isLicenseUpdatesPage = !!useMatch({
    from: "/(app)/admin/license-updates",
    shouldThrow: false,
  });
  const isTicketPage = !!useMatch({
    from: "/(app)/admin/tickets/$id/",
    shouldThrow: false,
  });

  return (
    <>
      {!isTicketPage && (
        <>
          <SubBar
            title="Skillster Admin"
            filterKey={
              isLicenseUpdatesPage ? licenseUpdatesFilterKey : undefined
            }
          />
          <SubMenu
            items={adminMenuItems.map((item) => ({
              icon: item.icon,
              label: item.label,
              path: item.to,
            }))}
          />
        </>
      )}
      <Outlet />
    </>
  );
}
