import { createFileRoute } from "@tanstack/react-router";
import CoursePage from "../../../pages/CoursePage/CoursePage";
import { courseQueryOptions } from "../../../api/useCourse";
import { useSuspenseQuery } from "@tanstack/react-query";

export const Route = createFileRoute(
  "/(app)/courses_/$type/$publicCourseUuid/"
)({
  component: RouteComponent,
  loader: ({ params, context }) =>
    context.queryClient.ensureQueryData(
      courseQueryOptions(params.publicCourseUuid)
    ),
  validateSearch: (): {
    o?: string;
  } => ({}),
});

function RouteComponent() {
  const { publicCourseUuid } = Route.useParams();
  const { data } = useSuspenseQuery(courseQueryOptions(publicCourseUuid));
  return <CoursePage course={data} />;
}
