import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { allSchoolsQueryOptions } from "../../../api/useAllSchools";
import SchoolsPage from "../../../pages/Admin/SchoolsPage/SchoolsPage";

export const Route = createFileRoute("/(app)/admin/schools")({
  component: RouteComponent,
  loader: ({ context }) => {
    return context.queryClient.ensureQueryData(allSchoolsQueryOptions);
  },
});

function RouteComponent() {
  const { data } = useSuspenseQuery(allSchoolsQueryOptions);
  return <SchoolsPage list={data.list} />;
}
