import { createFileRoute } from "@tanstack/react-router";
import InstallationGuidePage from "../../../pages/SupportPage/InstallationGuidePage";

export const Route = createFileRoute("/(app)/support/installation")({
  component: RouteComponent,
});

function RouteComponent() {
  return <InstallationGuidePage />;
}
