import { createFileRoute } from '@tanstack/react-router'
import GroupEditSettings from '../../../../../features/Groups/GroupEditSettings/GroupEditSettings'

export const Route = createFileRoute('/(fullscreen)/groups/$id/edit/settings')({
  component: RouteComponent,
})

function RouteComponent() {
  const { id } = Route.useParams()
  return <GroupEditSettings id={Number(id)} />
}
