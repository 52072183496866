import { v4 as uuidv4 } from "uuid";
export class UserProgress {
  CourseID: number = 0;
  PublicCourseUUID: string = "";
  CompletedCount: number = 0;
  ExerciseCount: number = 0;
  Runtime: number = 0;
  Completed: boolean = false;
  Achievements: UserAchievement[] = [];
}

export enum ExerciseRunResult {
  Completed = 1,
  Failed = -1,
  Aborted = -2,
  Timeout = -3,
  FailedToFollowInstructions = -4,
  OutsideExerciseArea = -5,
  FailedPenaltyOverFailureThreshold = -6,
}

class UserAchievement {
  Code: string = "";
  Name: string = "";
  UserID: number = 0;
  ExerciseRunID: number = 0;
  RefID: string = "";
  CreatedAt: string = "";
  HasSeen: boolean = false;
}

export class User {
  ID: number = 0;
  FirstName: string = "";
  LastName: string = "";
  Email: string = "";
  OrganizationID: number = 0;
  SchoolID: number = 0;
  SchoolClassID: number = 0;
  Role: number = 3;
  Active: boolean = false;
  AssignedCourses: string[] = [];

  CreatorName: string = "";
  CreatedBy: number = 0;
  CreationTime: string = "";

  // Lookup these names in the respective structs based on ID
  SchoolClass: string = "";
  School: string = "";
  Organization?: string = "";

  // UserActivity
  RunCount: number = 0;
  LastRun: string = "";
  LastActive: string = "";
  TotalRuntime: number = 0;
  IsOnline: boolean = false;
  CurrentExerciseID: number = 0;
  CurrentCourseID: number = 0;
}

export interface UserInSchoolClass {
  UserID: number;
  Progress: UserProgress[];
}

export class Assessment {
  ID: number = 0;
  UUID: string = "";
  Name: string = "";
  Severity: number = 0;
}

export interface AssessmentLocation {
  x: number;
  y: number;
  z: number;
}
export type ExerciseRunAssessment = {
  AssessmentID: number;
  Name: string;
  Timestamp: number;
};

export class Assessments {
  List: ExerciseRunAssessment[] = [];
  Counts: Record<string, number> = {};
}

export class DrivingRecordChartEntry {
  Timestamp: string = "";
  Runtime: number = 0;
}

export class Outlier {
  userID: number = 0;
  PublicCourseUUID: string = "";
  schoolClassID: number = 0;
  progress: number = 0;
  isBehind?: boolean = false;
  diff: number = 0;
}

export class ImportUser {
  FirstName: string = "";
  LastName: string = "";
  Email: string = "";
  SchoolClassID: number = 0;
  UUID: string = uuidv4();
  Role: number = 3;
}

export type ImportSuccess = {
  ID: number;
  FirstName: string;
  LastName: string;
  Email: string;
};

export class ImportResult {
  Success: ImportSuccess[] = [];
  Failed: string[] = [];
  Conflict: string[] = [];
  CreatedShoolClassId?: number = 0;
}

export class ViewConfig {
  Admin: boolean = false;
  ChangePermissions: boolean = false;
  CourseEditor: boolean = false;
  ShowCustomCourses: boolean = false;
  ShowPermissions: boolean = false;
  CourseAccessList: boolean = false;
  ShowAllExercisesInEditor: boolean = false;
  EditMessages: boolean = false;
  ShowMessages: boolean = false;
  ShowLicenseGenerationPage: boolean = false;
}

export interface Permission {
  ID: number;
  Code: string;
  AdminOnly: boolean;
}

export class SelectedUserPermissions {
  userID: number = 0;
  permissions: Permission[] = [];
}

export class Run {
  UserID: number = 0;
  Runtime: number = 0;
  Timestamp: string = "";
  ID: number = 0;
  Result: ExerciseRunResult = ExerciseRunResult.Failed;
  CourseImageUUID: string = "";
  StylePointEarned: boolean = false;
  StylePointAvailable: boolean = false;
  ExerciseName: string = "";
  CourseName: string = "";
  AssessmentCount: number = 0;
}
