import { Group, Loader, Stack } from "@mantine/core";
import { createFileRoute, useParams } from "@tanstack/react-router";
import { useCourses } from "../../../../api/useCourses";
import UncategorizedCoursesInformation from "../../../../components/UncategorizedCoursesInformation/UncategorizedCoursesInformation";
import AddCategory from "../../../../features/AddCategory/AddCategory";
import CourseCategories from "../../../../features/Courses/CourseCategories/CourseCategories";
import CoursesGrid from "../../../../features/Courses/CoursesGrid/CoursesGrid";
import CustomCoursesEmptyState from "../../../../features/Courses/CustomCoursesEmptyState/CustomCoursesEmptyState";
import AdminOnly from "../../../../features/Layout/AdminOnly/AdminOnly";
import SearchingFor from "../../../../features/Layout/SearchingFor";
import useSkillsterStore from "../../../../store/app";

export const Route = createFileRoute("/(app)/courses/$type/")({
  component: RouteComponent,
});

function RouteComponent() {
  const { type } = useParams({ strict: false });
  return type === "native" ? <NativeCourses /> : <CustomCourses />;
}

function NativeCourses() {
  const filter = useSkillsterStore((state) => state.filters).get("courses");
  const showCategories = filter?.length === 0 || !filter;
  return (
    <Stack>
      {showCategories && (
        <Group justify="space-between">
          <CourseCategories editable />
          <AdminOnly restrictToSkillsterOrg>
            <AddCategory />
          </AdminOnly>
        </Group>
      )}
      <UncategorizedCoursesInformation />
      <SearchingFor filter={filter} />
      <CoursesGrid variant="native" filter={filter} courseType="linear" />
      <CoursesGrid variant="native" filter={filter} courseType="open" />
    </Stack>
  );
}

function CustomCourses() {
  const { data, isPending } = useCourses();
  const filter = useSkillsterStore((state) => state.filters).get("courses");
  const hasCustomCourses = !!data?.list.filter((course) => !course.Native)
    ?.length;
  return (
    <>
      {isPending ? (
        <Loader />
      ) : !hasCustomCourses ? (
        <CustomCoursesEmptyState />
      ) : (
        <Stack>
          <SearchingFor filter={filter} />
          <CoursesGrid variant={"custom"} filter={filter} courseType="linear" />
          <CoursesGrid variant={"custom"} filter={filter} courseType="open" />
        </Stack>
      )}
    </>
  );
}
