import { createFileRoute, SearchSchemaInput } from "@tanstack/react-router";
import TicketsList from "../../../../features/Tickets/TicketsList/TicketsList";
import { PaginationState } from "@tanstack/react-table";

export const Route = createFileRoute("/(app)/admin/tickets/bugreport")({
  component: RouteComponent,
  validateSearch: (search: Record<string, unknown> & SearchSchemaInput) => {
    return {
      status: (search.status as string[]) || [],
      filter: (search.filter as string) || "",
      pagination: (search.pagination as PaginationState) || {
        pageIndex: 0,
        pageSize: 30,
      },
      priority: (search.priority as string[]) || [],
    };
  },
});

function RouteComponent() {
  return <TicketsList />;
}
