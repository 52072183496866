import { createFileRoute } from "@tanstack/react-router";
import CompetitionsPage from "../../../../pages/Admin/Competitions/CompetitionsPage";

export const Route = createFileRoute("/(app)/admin/competitions/")({
  component: RouteComponent,
});

function RouteComponent() {
  return <CompetitionsPage />;
}
