import { createFileRoute, useParams } from "@tanstack/react-router";
import AcademySchoolStats from "../../../../../../features/Academy/AcademyStats/AcademySchoolStats/AcademySchoolStats";

export const Route = createFileRoute(
  "/(app)/admin/academy/stats/$lectureId/$schoolId"
)({
  component: RouteComponent,
});

function RouteComponent() {
  const { lectureId, schoolId } = useParams({ strict: false });
  return (
    <AcademySchoolStats
      lectureId={Number(lectureId)}
      schoolId={Number(schoolId)}
    />
  );
}
