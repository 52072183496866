import { notifications } from "@mantine/notifications";
import { useAuth } from "../AuthContext";
import successNotificationClasses from "../theme/notification.success.module.scss";
import { rem } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";

function useChangeOrganization() {
  const { setSelectedOrganizationId } = useAuth();

  function changeOrganization(id: number | null) {
    if (id === null) return;
    setSelectedOrganizationId(id);
    notifications.show({
      message: "Organisationen är ändrad",
      position: "bottom-center",
      classNames: successNotificationClasses,
      icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
      color: "bluegray.9",
    });
    localStorage.setItem("organization_id", id.toString());
  }
  return changeOrganization;
}

export default useChangeOrganization;
