import { createFileRoute } from "@tanstack/react-router";
import StatsPage from "../../../pages/HomePage/Stats/StatsPage";

export const Route = createFileRoute("/(app)/home/stats")({
  component: RouteComponent,
});

function RouteComponent() {
  return <StatsPage />;
}
