import { createFileRoute } from "@tanstack/react-router";
import OrganizationDetailsPage from "../../../../../pages/Admin/Organizations/OrganizationDetailsPage/OrganizationDetailsPage";

export const Route = createFileRoute(
  "/(app)/admin/organizations/$organizationId/"
)({
  component: RouteComponent,
});

function RouteComponent() {
  const { organizationId } = Route.useParams();
  return <OrganizationDetailsPage id={Number(organizationId)} />;
}
