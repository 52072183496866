import { createFileRoute } from "@tanstack/react-router";
import AllUsersPage from "../../../pages/Admin/AllUsersPage/AllUsersPage";

export const Route = createFileRoute("/(app)/admin/users")({
  component: RouteComponent,
});

function RouteComponent() {
  return <AllUsersPage />;
}
