import { Combobox, Tooltip } from "@mantine/core";
import { useTranslation } from "react-i18next";

interface OrganizationOptionProps {
  organizationId: number;
  schoolCount: number;
  organizationName: string;
  active?: boolean;
  simulatorCount: number;
}

function OrganizationOption({
  organizationId,
  organizationName,
  schoolCount,
  active,
  simulatorCount,
}: OrganizationOptionProps) {
  const { t } = useTranslation();
  return (
    <Tooltip
      label={t("common:tooltips.organizationMissingSchools")}
      withArrow
      disabled={schoolCount > 0}
      position="left"
    >
      <Combobox.Option
        value={organizationId.toString()}
        active={active}
        disabled={schoolCount === 0}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>{organizationName}</span>
          <span>
            {simulatorCount ? <> ({simulatorCount} st)</> : " (0 st)"}
          </span>
        </div>
      </Combobox.Option>
    </Tooltip>
  );
}

export default OrganizationOption;
