import { createFileRoute } from "@tanstack/react-router";
import NewsPage from "../../../pages/HomePage/News/NewsPage";

export const Route = createFileRoute("/(app)/home/news")({
  component: RouteComponent,
  validateSearch: (
    search: Record<string, any>
  ): {
    version?: string;
  } => ({ version: search.version ?? "" }),
});

function RouteComponent() {
  return <NewsPage />;
}
