import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/(app)/courses/")({
  beforeLoad: () => {
    throw redirect({
      to: "/courses/$type",
      params: { type: "native" },
    });
  },
});
