import { useQuery } from "@tanstack/react-query";
import { fetchLang } from "../helpers/language.helpers";
import { apiGet } from "../helpers/request.helpers";
import { useAuth } from "../AuthContext";

export type NormalResponse = {
  ID: number;
  SimulatorSchoolID: number;
  SimulatorSchoolName: string;
  SimulatorName: string;
  IsExternalSimulator: boolean;
};

export function useOnlineUsers() {
  const { isAuthenticated } = useAuth();
  return useQuery({
    queryKey: [
      "online-users-for-school",
      localStorage.getItem("school_id"),
      fetchLang(),
    ],
    queryFn: async () => {
      const response = await apiGet("/online-users-for-school", {
        language: fetchLang(),
      });
      const list: NormalResponse[] = response.OnlineUsersInfo;
      const map = new Map<number, NormalResponse>();
      list.forEach((u) => map.set(u.ID, u));
      return { list, map };
    },
    refetchInterval: 5 * 1000,
    staleTime: 10 * 1000,
    enabled: !!isAuthenticated,
  });
}
