import { Paper, Stack, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconHome } from "@tabler/icons-react";
import { Trans, useTranslation } from "react-i18next";
import { useAuth } from "../../../AuthContext";
import FeedbackForm from "../../../features/Layout/Header/components/FeedbackForm/FeedbackForm";
import { LinkButton } from "../../LinkButton/LinkButton";
import SkillsterTitle from "../../SkillsterTitle/SkillsterTitle";
import classes from "./styles.module.scss";

function PageNotFoundComponent() {
  const { t } = useTranslation();
  const { user } = useAuth();
  function openModal() {
    modals.open({
      title: t("common:feedback.title"),
      children: <FeedbackForm user={user} />,
    });
  }
  return (
    <Paper bg="bluegray.1" py={200}>
      <Stack align="center">
        <SkillsterTitle order={1}>
          {t("common:pageNotFound.title")}
        </SkillsterTitle>

        <Text c="dimmed">
          <Trans i18nKey="common:pageNotFound.description">
            <Text span>1</Text>
            <Text span className={classes.feedbackLink} onClick={openModal}>
              2
            </Text>
          </Trans>
        </Text>

        <LinkButton to="/home" leftSection={<IconHome size={16} />}>
          {t("common:pageNotFound.button")}
        </LinkButton>
      </Stack>
    </Paper>
  );
}

export default PageNotFoundComponent;
