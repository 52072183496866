import { createFileRoute } from "@tanstack/react-router";
import SchoolDetailsPage from "../../../../../../pages/Admin/Organizations/SchoolDetailsPage/SchoolDetailsPage";

export const Route = createFileRoute(
  "/(app)/admin/organizations/$organizationId/schools/$schoolId"
)({
  component: RouteComponent,
});

function RouteComponent() {
  const { organizationId, schoolId } = Route.useParams();
  return (
    <SchoolDetailsPage
      organizationId={Number(organizationId)}
      schoolId={Number(schoolId)}
    />
  );
}
