import { createFileRoute, redirect } from "@tanstack/react-router";
import { fetchLang } from "../../../../helpers/language.helpers";
import AcademyPage from "../../../../pages/HomePage/Academy/AcademyPage";

export const Route = createFileRoute("/(app)/home/academy/")({
  component: RouteComponent,
  beforeLoad: () => {
    if (fetchLang() === "en-US") throw redirect({ to: "/home" });
  },
});

function RouteComponent() {
  return <AcademyPage />;
}
