import { Combobox } from "@mantine/core";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import Base from "../Base/Base";
import {
  changeSchool,
  useSchoolNavigationList,
} from "../../../../../../api/useSchoolNavigationList";
import { useAuth } from "../../../../../../AuthContext";
import AdminOnly from "../../../../AdminOnly/AdminOnly";
import useGoToTopLevelRoute from "../../../../../../hooks/useGoToTopLevelRoute";

function SchoolNavigation({ withLabel }: { withLabel?: boolean }) {
  const { selectedOrganizationId, selectedSchoolId, setSelectedSchoolId } =
    useAuth();
  const { data, isPending, isError } = useSchoolNavigationList(
    selectedOrganizationId
  );
  const goToTopLevelRoute = useGoToTopLevelRoute();

  const queryClient = useQueryClient();
  const selectedSchool = data?.map.get(selectedSchoolId);

  const [search, setSearch] = useState("");

  function onChange(id: number | null) {
    if (id === null) return;
    changeSchool(id, queryClient, setSelectedSchoolId, () => {
      goToTopLevelRoute();
    });
  }

  if (isPending) return null;
  if (isError) return null;

  const options =
    data?.list
      .filter((school) =>
        school.Name.toLowerCase().includes(search.toLowerCase().trim())
      )
      .map((school) => (
        <Combobox.Option
          key={school.ID}
          value={school.ID.toString()}
          active={school.ID === selectedSchool?.ID}
        >
          {school.Name} <AdminOnly>({school.SimulatorCount})</AdminOnly>
        </Combobox.Option>
      )) ?? [];

  return (
    <Base
      options={options}
      onChange={onChange}
      searchProps={options.length > 2 ? { search, setSearch } : undefined}
      label={withLabel ? "Skola" : undefined}
      selectedName={selectedSchool?.Name}
      placeholder="Sök skola"
    />
  );
}

export default SchoolNavigation;
