import { createFileRoute } from "@tanstack/react-router";
import LicenseUpdatesPage from "../../../pages/Admin/LicenseUpdatesPage/LicenseUpdatesPage";
import { schoolLicenseUpdatesQueryOptions } from "../../../api/useSchoolLicenseUpdates";
import { useSuspenseQuery } from "@tanstack/react-query";

export const Route = createFileRoute("/(app)/admin/license-updates")({
  component: RouteComponent,
  loader: ({ context }) => {
    return context.queryClient.ensureQueryData(
      schoolLicenseUpdatesQueryOptions()
    );
  },
});

function RouteComponent() {
  const { data } = useSuspenseQuery(schoolLicenseUpdatesQueryOptions());
  return <LicenseUpdatesPage data={data} />;
}
