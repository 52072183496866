import { createFileRoute, Outlet, useMatch } from "@tanstack/react-router";
import TicketsPage from "../../../../pages/Admin/TicketsPage/TicketsPage";

export const Route = createFileRoute("/(app)/admin/tickets")({
  component: RouteComponent,
});

function RouteComponent() {
  const isTicketDetailsPage = !!useMatch({
    from: "/(app)/admin/tickets/$id/",
    shouldThrow: false,
  });

  return isTicketDetailsPage ? <Outlet /> : <TicketsPage />;
}
