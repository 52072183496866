import { Group, Stack, Text } from "@mantine/core";
import UserBadge from "../../../../../../../../Users/UserBadge/UserBadge";
import { useTranslation } from "react-i18next";

import { useAuth } from "../../../../../../../../../AuthContext";

function ProfileLink() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const role =
    user?.Role === 1 ? t("common:roles.admin") : t("common:roles.teacher");
  return (
    <Group wrap="nowrap" p="sm" pr="xl" gap="xs">
      <UserBadge firstName={user?.FirstName} lastName={user?.LastName} />
      <Stack gap={0}>
        <Text size="xs" fw={700} c="bluegray.3">
          {user?.FirstName} {user?.LastName}
        </Text>
        <Text size="xs" c="bluegray.4">
          {role}
        </Text>
      </Stack>
    </Group>
  );
}

export default ProfileLink;
