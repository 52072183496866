import { HoverCard, Stack } from "@mantine/core";
import {
  IconAward,
  IconBug,
  IconBuilding,
  IconBuildingStore,
  IconCar,
  IconChevronRight,
  IconEdit,
  IconFileInvoice,
  IconGraph,
  IconHistoryToggle,
  IconMap,
  IconMessage,
  IconSchool,
  IconSteeringWheel,
  IconTrophy,
  IconUsers,
} from "@tabler/icons-react";
import { orderBy } from "lodash";
import { useTranslation } from "react-i18next";
import {
  ADMIN_ACADEMY_PATH,
  ADMIN_ALL_SIMULATORS_PATH,
  ADMIN_ALL_USERS_PATH,
  ADMIN_BILLING_PATH,
  ADMIN_COMPETITIONS_PATH,
  ADMIN_COURSE_EDITOR_STATS_PATH,
  ADMIN_EXERCISE_COMMENTS_PATH,
  ADMIN_MAP_PATH,
  ADMIN_ORGANIZATIONS_PATH,
  ADMIN_RUNDATA_PATH,
  ADMIN_SCHOOLS_PATH,
  ADMIN_STATS_PATH,
  ADMIN_TOPLIST_PATH,
} from "../../../../../../../../../routes";
import LinkItem, { LinkItemProps } from "../LinkItem/LinkItem";
import classes from "./styles.module.scss";
import useIsMobile from "../../../../../../../../../hooks/useIsMobile";
import ClickItem from "../LinkItem/ClickItem";

export const useAdminMenuItems = (): Pick<
  LinkItemProps,
  "label" | "icon" | "to"
>[] => {
  const { t } = useTranslation();
  return orderBy(
    [
      { label: "Statistik", icon: IconGraph, to: ADMIN_STATS_PATH },
      { label: "Fakturering", icon: IconFileInvoice, to: ADMIN_BILLING_PATH },
      {
        label: "Simulatorer",
        icon: IconSteeringWheel,
        to: ADMIN_ALL_SIMULATORS_PATH,
      },
      {
        label: t("common:listTitles.users"),
        icon: IconUsers,
        to: ADMIN_ALL_USERS_PATH,
      },
      {
        label: "Organisationer",
        icon: IconBuilding,
        to: ADMIN_ORGANIZATIONS_PATH,
      },
      {
        label: "Skolor",
        icon: IconBuildingStore,
        to: ADMIN_SCHOOLS_PATH,
      },
      { label: "Kartan", icon: IconMap, to: ADMIN_MAP_PATH },
      { label: "Topplistan", icon: IconTrophy, to: ADMIN_TOPLIST_PATH },
      { label: "Ärenden", icon: IconBug, to: "/admin/tickets" },
      { label: "Kördata", icon: IconCar, to: ADMIN_RUNDATA_PATH },
      {
        label: t("courses.subMenu.customCourses"),
        icon: IconEdit,
        to: ADMIN_COURSE_EDITOR_STATS_PATH,
      },
      {
        label: "Lärarutbildningar",
        icon: IconSchool,
        to: `${ADMIN_ACADEMY_PATH}`,
      },

      { label: "Tävlingar", icon: IconAward, to: ADMIN_COMPETITIONS_PATH },
      {
        label: "Övningskommentarer",
        icon: IconMessage,
        to: ADMIN_EXERCISE_COMMENTS_PATH,
      },
      {
        label: "Licenshistorik",
        to: "/admin/license-updates",
        icon: IconHistoryToggle,
      },
    ],
    ["label"]
  );
};
function AdminMenu({ closeDropdown }: { closeDropdown: () => void }) {
  const adminMenuItems = useAdminMenuItems();
  const mobile = useIsMobile();
  return (
    <HoverCard
      width={200}
      position="right-start"
      offset={-10}
      classNames={{
        dropdown: classes.dropdown,
      }}
    >
      <HoverCard.Target>
        {mobile ? (
          <ClickItem
            label="Admin"
            rightSection={<IconChevronRight size={16} color="white" />}
          />
        ) : (
          <LinkItem
            to={"/admin"}
            label="Admin"
            rightSection={<IconChevronRight size={16} color="white" />}
          />
        )}
      </HoverCard.Target>
      <Stack gap={0}>
        <HoverCard.Dropdown>
          <Stack gap={0}>
            {adminMenuItems.map((i) => (
              <LinkItem
                key={i.label}
                adminItem
                {...i}
                onClick={closeDropdown}
              />
            ))}
          </Stack>
        </HoverCard.Dropdown>
      </Stack>
    </HoverCard>
  );
}

export default AdminMenu;
