import { createFileRoute } from '@tanstack/react-router'
import EditUserPersonalInfo from '../../../../../features/User/Edit/EditUserPersonalInfo/EditUserPersonalInfo'

export const Route = createFileRoute(
  '/(fullscreen)/users/$id/edit/personal-info',
)({
  component: RouteComponent,
})

function RouteComponent() {
  const { id } = Route.useParams()
  return <EditUserPersonalInfo id={Number(id)} />
}
