import { createFileRoute } from "@tanstack/react-router";
import UserDrivingRecord from "../../../../features/User/UserDrivingRecord/UserDrivingRecord";

export const Route = createFileRoute("/(app)/users/$id/driving-record")({
  component: RouteComponent,
});

function RouteComponent() {
  const { id } = Route.useParams();
  return <UserDrivingRecord id={Number(id)} />;
}
