import { Anchor } from "@mantine/core";

import classes from "./styles.module.css";
import { Link } from "@tanstack/react-router";

interface MenuItemProps {
  to: string;
  label: string;
  onClick?: () => void;
}

function MenuItem(props: MenuItemProps) {
  return (
    <Anchor
      component={Link}
      to={props.to}
      className={classes.menuItem}
      onClick={props.onClick}
    >
      {props.label}
    </Anchor>
  );
}

export default MenuItem;
