import {
  Combobox,
  InputBase,
  ScrollArea,
  Text,
  useCombobox,
} from "@mantine/core";
import clsx from "clsx";
import { Dispatch, SetStateAction, type JSX } from "react";
import classes from "./styles.module.scss";
import { useTranslation } from "react-i18next";

interface BaseProps {
  options: JSX.Element[];
  selectedName?: string;
  isPending?: boolean;
  searchProps?: {
    search: string;
    setSearch: Dispatch<SetStateAction<string>>;
  };
  placeholder: string;
  onChange: (id: number | null) => void;
  disabled?: boolean;
  highlight?: boolean;
  label?: string;
}

function Base(props: BaseProps) {
  const { t } = useTranslation();
  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      combobox.focusTarget();
      props.searchProps && props.searchProps.setSearch("");
    },
    onDropdownOpen: () => {
      combobox.focusSearchInput();
    },
  });
  function onOptionSubmit(id: string | null) {
    props.onChange(Number(id));
    combobox.closeDropdown();
  }
  return (
    <Combobox
      store={combobox}
      shadow="md"
      width={300}
      position="bottom"
      middlewares={{ flip: false, shift: false }}
      onOptionSubmit={onOptionSubmit}
      classNames={{
        dropdown: classes.comboboxDropdown,
        options: classes.comboboxOptions,
        search: classes.search,
        option: classes.option,
        empty: classes.empty,
      }}
    >
      <Combobox.Target>
        <InputBase
          component="button"
          disabled={props.disabled || props.options.length === 0}
          type="button"
          label={props.label}
          pointer
          rightSection={<Combobox.Chevron />}
          onClick={() => combobox.toggleDropdown()}
          rightSectionPointerEvents="none"
          classNames={{
            wrapper: classes.inputWrapper,
            input: clsx([classes.input, props.highlight && classes.highlight]),
            section: classes.inputSection,
            label: classes.label,
          }}
        >
          {props.options.length === 0 && !props.isPending ? (
            <Text c="bluegray.5" fz="sm">
              Inga skolor
            </Text>
          ) : (
            <Text truncate="end" c="bluegray.3" fz="sm" fw="bold">
              {props.selectedName}
            </Text>
          )}
        </InputBase>
      </Combobox.Target>

      <Combobox.Dropdown>
        {props.searchProps && (
          <Combobox.Search
            value={props.searchProps.search}
            onChange={(event) =>
              props.searchProps?.setSearch(event.currentTarget.value)
            }
            placeholder={props.placeholder}
          />
        )}
        <Combobox.Options>
          <ScrollArea.Autosize type="scroll" mah={"80vh"}>
            {props.options.length > 0 ? (
              props.options
            ) : (
              <Combobox.Empty>{t("common:misc.noSearchResult")}</Combobox.Empty>
            )}
          </ScrollArea.Autosize>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}

export default Base;
