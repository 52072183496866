import * as React from "react";
import { createLink, LinkComponent } from "@tanstack/react-router";
import { Button, ButtonProps } from "@mantine/core";

const MantineLinkComponent = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    return <Button ref={ref} {...props} />;
  }
);

const LinkButtonComponent = createLink(MantineLinkComponent);

export const LinkButton: LinkComponent<typeof MantineLinkComponent> = (
  props
) => {
  return <LinkButtonComponent {...props} />;
};
