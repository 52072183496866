import i18n from "../i18n";
import { ExerciseRunResult, Permission } from "../types/user.types";
import { createRoles } from "./constants";

export const firstChar = (text: string) => text.charAt(0);

export const searchString = (source: string, searchString: string) =>
  searchString
    .split(" ")
    .every((s: string) =>
      source.trim().toLowerCase().includes(s.toLowerCase())
    );

export const trimCourseName = (name?: string) => {
  if (!name) return "";
  return name.replace(
    / - Upplåst| - Fri| - Linjär| - Linear| - Free| - Open| - Vapaa| - Lineaarinen| - Open| - Grund/g,
    ""
  );
};

export const trimInactive = (text?: string) => {
  if (!text) return "";
  return text.replace(/(-@@-)(.+)(-@@-)/g, "");
};

export const nameToInitials = (name: string): string =>
  name
    .split(" ")
    .map((word) => word[0])
    .join("")
    .toUpperCase()
    .slice(0, 2);

export const incrementString = (text: string) =>
  text === ""
    ? text
    : /^\d+$/.test(text.charAt(text.length - 1))
      ? text.replace(/(\d*)$/, (_, t) => (+t + 1).toString())
      : `${text} 2`;

export const isEmptyOrOnlySpaces = (string: string): boolean =>
  string.replace(/\s+/g, "") === "";

export function permissionToReadable(permission: Permission): string {
  switch (permission.Code) {
    case "permissions:read":
      return "Se användares behörigheter";
    case "permissions:write":
      return "Ändra användares behörigheter";
    case "cache:read":
      return "Läsa cache";
    case "cache:write":
      return "Skriva cache";
    case "simulators:write":
      return "Redigera alla simulatorer";
    case "lecture:read":
      return "Se lärarutbildningar";
    case "lecture:write":
      return "Skapa lärarutbildningar";
    case "billing:readwrite":
      return "Hantera fakturering";
    case "offlinelicense:readwrite":
      return "Hantera offline-licenser";
  }
  return permission.Code;
}

export function getCloudinaryVehicleUrl(
  id: string,
  width?: number,
  height?: number,
  aspectRatio?: string,
  customTransformation?: string
) {
  const base = import.meta.env.VITE_CLOUDINARY_BASE ?? "";
  const breakPoint = "upload";
  const part1 = base.slice(0, base.indexOf(breakPoint) + breakPoint.length);
  const part2 = base.slice(
    base.indexOf(breakPoint) + breakPoint.length,
    base.length
  );
  const ar = aspectRatio ?? "16:9";
  let transformation = width || height ? `/ar_${ar},c_fill,` : "";
  if (width) transformation += `w_${width}`;
  if (width && height) transformation += ",";
  if (height) transformation += `h_${height}`;
  if (customTransformation) transformation += `,${customTransformation}`;
  return `${part1}${transformation}${part2}/${id}`;
}

export function getCloudinaryExerciseUrl(
  id?: string,
  width?: number,
  height?: number,
  aspectRatio?: string,
  customTransformation?: string
) {
  if (!id) return null;
  const base = import.meta.env.VITE_CLOUDINARY_BASE_EXERCISE ?? "";
  const breakPoint = "upload";
  const part1 = base.slice(0, base.indexOf(breakPoint) + breakPoint.length);
  const part2 = base.slice(
    base.indexOf(breakPoint) + breakPoint.length,
    base.length
  );
  const ar = aspectRatio ?? "16:9";
  const improve = "e_improve:50,";
  let transformation =
    width || height ? `/ar_${ar},c_fill,${improve}` : improve;
  if (width) transformation += `w_${width}`;
  if (width && height) transformation += ",";
  if (height) transformation += `h_${height}`;
  if (customTransformation) transformation += `,${customTransformation}`;
  return `${part1}${transformation}${part2}/${id}`;
}

export function getCloudinaryLectureUrl(
  lectureUuid: string,
  imageId: string,
  width?: number,
  height?: number,
  aspectRatio?: string,
  customTransformation?: string
) {
  const base = import.meta.env.VITE_CLOUDINARY_BASE_LECTURES ?? "";
  const breakPoint = "upload";
  const part1 = base.slice(0, base.indexOf(breakPoint) + breakPoint.length);
  const part2 = base.slice(
    base.indexOf(breakPoint) + breakPoint.length,
    base.length
  );
  const ar = aspectRatio ?? "16:9";
  let transformation = `/ar_${ar},c_fill`;
  if (width) transformation += `,w_${width}`;
  if (width && height) transformation += ",";
  if (height) {
    if (width) transformation += `h_${height}`;
    else transformation += `,h_${height}`;
  }
  if (customTransformation) transformation += `,${customTransformation}`;
  return `${part1}${transformation}${part2}/${lectureUuid}/${imageId}`;
}

export function expandVersionNumber(version: string): string {
  return version
    .split(".")
    .map((n) => +n + 100000)
    .join(".");
}

export const compareSemanticVersions = (a: string, b: string) => {
  const a1 = a.split(".");
  const b1 = b.split(".");
  const len = Math.min(a1.length, b1.length);
  for (let i = 0; i < len; i++) {
    const a2 = +a1[i] || 0;
    const b2 = +b1[i] || 0;
    if (a2 !== b2) {
      return a2 > b2 ? 1 : -1;
    }
  }
  return b1.length - a1.length;
};

export const isDeactivatedName = (name: string): boolean =>
  name.includes("-@@-DEACTIVATED-") && name.endsWith("-@@-");
export const trimDeactivatedName = (name?: string): string =>
  name?.replace(/-\@@-DEACTIVATED-\S*-\@@-/g, "") ?? "";
export const trimAnonymizedName = (name: string): string =>
  name.replace(/^ANONYMIZED_\d{4}-\d{2}-\d{2}_[a-zA-Z0-9-]+/g, "**********");

export function exerciseResultToText(result: ExerciseRunResult): string {
  switch (result) {
    case ExerciseRunResult.FailedPenaltyOverFailureThreshold:
      return i18n.t(
        "common:exerciseRunStatuses.failedPenaltyOverFailureThreshold"
      );
    case ExerciseRunResult.FailedToFollowInstructions:
      return i18n.t("common:exerciseRunStatuses.failedToFollowInstructions");
    case ExerciseRunResult.OutsideExerciseArea:
      return i18n.t("common:exerciseRunStatuses.outsideExerciseArea");
    case ExerciseRunResult.Timeout:
      return i18n.t("common:exerciseRunStatuses.timeput");
    case ExerciseRunResult.Aborted:
      return i18n.t("common:exerciseRunStatuses.aborted");
    case ExerciseRunResult.Failed:
      return i18n.t("common:exerciseRunStatuses.failed");
    default:
      return i18n.t("common:exerciseRunStatuses.completed");
  }
}

export function getRoleName(role: number) {
  const roles = createRoles({ includeAdmin: true });
  return roles.find((r) => r.value === role.toString())?.label;
}

export function downloadStringAsLog(text: string, filename: string) {
  const blob = new Blob([text], { type: "text/plain" });

  const anchor = document.createElement("a");
  anchor.download = filename;
  anchor.href = window.URL.createObjectURL(blob);
  anchor.style.display = "none";

  document.body.appendChild(anchor);
  anchor.click();

  document.body.removeChild(anchor);
  window.URL.revokeObjectURL(anchor.href);
}

export function downloadFileFromBase64(base64Data: string, filename: string) {
  const blob = base64ToBlob(base64Data, "application/gzip");

  const anchor = document.createElement("a");
  anchor.download = filename;
  anchor.href = window.URL.createObjectURL(blob);
  anchor.style.display = "none";

  document.body.appendChild(anchor);
  anchor.click();

  document.body.removeChild(anchor);
  window.URL.revokeObjectURL(anchor.href);
}

function base64ToBlob(base64Data: string, type: string) {
  const binaryString = atob(base64Data);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return new Blob([bytes], { type: type });
}

export function createSlug(input: string): string {
  return input
    .toLowerCase()
    .replace(/å/g, "a")
    .replace(/ä/g, "a")
    .replace(/ö/g, "o")
    .replace(/[^a-z0-9-]+/g, "-")
    .replace(/-+/g, "-")
    .replace(/^-|-$/g, "");
}

export function getServerName(): string | null {
  const regex = /^https:\/\/(.+)\.skillster\.se$/;
  const match = import.meta.env.VITE_API_URI.match(regex);

  if (match && match[1]) {
    return match[1]; // Returnerar servernamnet (admin, devel, devel2 etc)
  } else {
    return null;
  }
}

export function stripHtmlTags(text: string = ""): string {
  return text.replace(/<[^>]*>/g, "");
}
