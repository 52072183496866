import { useMutation } from "@tanstack/react-query";
import { apiPost } from "../helpers/request.helpers";
import { useTranslation } from "react-i18next";

type FeedbackMutation = {
  email: string;
  name: string;
  id: number;
  message: string;
  fromPage: string;
  tags: string[];
  fromSupportPage?: boolean;
};

function useSendFeedback({ onSuccess }: { onSuccess?: () => void }) {
  const { t } = useTranslation();
  return useMutation({
    mutationKey: ["send-feedback"],
    mutationFn: (mutation: FeedbackMutation) =>
      apiPost("/tickets/create-feedback", {
        Subject: "Feedback",
        Message: mutation.message,
        Source: mutation.fromPage,
        Tags: mutation.tags,
        RespondTo: mutation.email,
      }),
    meta: {
      successMessage: {
        title: t("common:successMessages.done"),
        message: t("common:feedback.success"),
      },
    },
    onSuccess: (data, variables) => {
      onSuccess && onSuccess();
      postToSlack({
        name: variables.name,
        email: variables.email,
        message: variables.message,
        userId: variables.id.toString(),
        fromPage: variables.fromPage,
      });
    },
  });
}

function postToSlack({
  name,
  email,
  message,
  userId,
  fromPage,
}: {
  userId: string;
  name: string;
  email: string;
  message: string;
  fromPage: string;
}) {
  fetch(`${import.meta.env.VITE_PUBLIC_SLACK_WEBHOOK}`, {
    method: "post",
    headers: { "Content-type": "application/x-www-form-urlencoded" },
    body: JSON.stringify({
      blocks: [
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: "*Feedback [3.5]*",
          },
        },
        {
          type: "section",
          fields: [
            {
              type: "mrkdwn",
              text: `*Name:*\n${name}`,
            },
            {
              type: "mrkdwn",
              text: `*User ID:*\n${userId}`,
            },
            {
              type: "mrkdwn",
              text: `*Email:*\n${email}`,
            },
            {
              type: "mrkdwn",
              text: `*From page:*\n${fromPage}`,
            },
            {
              type: "mrkdwn",
              text: `*Message:*\n${message}`,
            },
          ],
        },
      ],
    }),
  });
}

export default useSendFeedback;
