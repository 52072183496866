import { createFileRoute } from "@tanstack/react-router";
import AcademyStats from "../../../../../features/Academy/AcademyStats/AcademyStats";

export const Route = createFileRoute("/(app)/admin/academy/stats/")({
  component: RouteComponent,
});

function RouteComponent() {
  return <AcademyStats />;
}
