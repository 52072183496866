import { createFileRoute } from "@tanstack/react-router";
import MapPage from "../../../pages/Admin/MapPage/MapPage";

export const Route = createFileRoute("/(app)/admin/map")({
  component: RouteComponent,
});

function RouteComponent() {
  return <MapPage />;
}
