/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as LoginImport } from './routes/login'
import { Route as ActivateImport } from './routes/activate'
import { Route as appRouteImport } from './routes/(app)/route'
import { Route as IndexImport } from './routes/index'
import { Route as CourseEditorPublicUuidImport } from './routes/course-editor/$publicUuid'
import { Route as fullscreenWelcomeImport } from './routes/(fullscreen)/welcome'
import { Route as appSimulatorsImport } from './routes/(app)/simulators'
import { Route as appAboutImport } from './routes/(app)/about'
import { Route as appHomeRouteImport } from './routes/(app)/home/route'
import { Route as appCoursesRouteImport } from './routes/(app)/courses/route'
import { Route as appAdminRouteImport } from './routes/(app)/admin/route'
import { Route as appUsersIndexImport } from './routes/(app)/users/index'
import { Route as appSupportIndexImport } from './routes/(app)/support/index'
import { Route as appHomeIndexImport } from './routes/(app)/home/index'
import { Route as appGroupsIndexImport } from './routes/(app)/groups/index'
import { Route as appCoursesIndexImport } from './routes/(app)/courses/index'
import { Route as appAdminIndexImport } from './routes/(app)/admin/index'
import { Route as OauthGithubCallbackImport } from './routes/oauth/github.callback'
import { Route as OauthFortnoxCallbackImport } from './routes/oauth/fortnox.callback'
import { Route as appSupportInstallationImport } from './routes/(app)/support/installation'
import { Route as appSupportContactImport } from './routes/(app)/support/contact'
import { Route as appHomeYoutubeImport } from './routes/(app)/home/youtube'
import { Route as appHomeStatsImport } from './routes/(app)/home/stats'
import { Route as appHomeStartImport } from './routes/(app)/home/start'
import { Route as appHomeNewsImport } from './routes/(app)/home/news'
import { Route as appGroupsCreateImport } from './routes/(app)/groups/create'
import { Route as appAdminUsersImport } from './routes/(app)/admin/users'
import { Route as appAdminToplistImport } from './routes/(app)/admin/toplist'
import { Route as appAdminStatsImport } from './routes/(app)/admin/stats'
import { Route as appAdminSimulatorsImport } from './routes/(app)/admin/simulators'
import { Route as appAdminSchoolsImport } from './routes/(app)/admin/schools'
import { Route as appAdminRundataImport } from './routes/(app)/admin/rundata'
import { Route as appAdminMapImport } from './routes/(app)/admin/map'
import { Route as appAdminLicenseUpdatesImport } from './routes/(app)/admin/license-updates'
import { Route as appAdminExerciseCommentsImport } from './routes/(app)/admin/exercise-comments'
import { Route as appAdminCourseEditorStatsImport } from './routes/(app)/admin/course-editor-stats'
import { Route as appAdminBillingImport } from './routes/(app)/admin/billing'
import { Route as appUsersCreateRouteImport } from './routes/(app)/users/create/route'
import { Route as appUsersIdRouteImport } from './routes/(app)/users/$id/route'
import { Route as appGroupsIdRouteImport } from './routes/(app)/groups/$id/route'
import { Route as appAdminTicketsRouteImport } from './routes/(app)/admin/tickets/route'
import { Route as appAdminAcademyRouteImport } from './routes/(app)/admin/academy/route'
import { Route as appUsersCreateIndexImport } from './routes/(app)/users/create/index'
import { Route as appUsersIdIndexImport } from './routes/(app)/users/$id/index'
import { Route as appHomeAcademyIndexImport } from './routes/(app)/home/academy/index'
import { Route as appGroupsIdIndexImport } from './routes/(app)/groups/$id/index'
import { Route as appCoursesTypeIndexImport } from './routes/(app)/courses/$type/index'
import { Route as appAdminTicketsIndexImport } from './routes/(app)/admin/tickets/index'
import { Route as appAdminOrganizationsIndexImport } from './routes/(app)/admin/organizations/index'
import { Route as appAdminCompetitionsIndexImport } from './routes/(app)/admin/competitions/index'
import { Route as appAdminAcademyIndexImport } from './routes/(app)/admin/academy/index'
import { Route as appUsersCreateNewImport } from './routes/(app)/users/create/new'
import { Route as appUsersCreateImportImport } from './routes/(app)/users/create/import'
import { Route as appUsersIdTachographImport } from './routes/(app)/users/$id/tachograph'
import { Route as appUsersIdProfileImport } from './routes/(app)/users/$id/profile'
import { Route as appUsersIdDrivingRecordImport } from './routes/(app)/users/$id/driving-record'
import { Route as appUsersIdCoursesImport } from './routes/(app)/users/$id/courses'
import { Route as appGroupsIdTachographImport } from './routes/(app)/groups/$id/tachograph'
import { Route as appGroupsIdDrivingHistoryImport } from './routes/(app)/groups/$id/driving-history'
import { Route as appGroupsIdCoursesImport } from './routes/(app)/groups/$id/courses'
import { Route as appAdminTicketsFeedbackImport } from './routes/(app)/admin/tickets/feedback'
import { Route as appAdminTicketsBugreportImport } from './routes/(app)/admin/tickets/bugreport'
import { Route as appAdminCompetitionsCreateImport } from './routes/(app)/admin/competitions/create'
import { Route as appAdminCompetitionsIdImport } from './routes/(app)/admin/competitions/$id'
import { Route as appAdminAcademyCoursesImport } from './routes/(app)/admin/academy/courses'
import { Route as fullscreenUsersIdEditRouteImport } from './routes/(fullscreen)/users/$id/edit/route'
import { Route as fullscreenGroupsIdEditRouteImport } from './routes/(fullscreen)/groups/$id/edit/route'
import { Route as appCoursesTypePublicCourseUuidIndexImport } from './routes/(app)/courses_.$type.$publicCourseUuid/index'
import { Route as appAdminTicketsIdIndexImport } from './routes/(app)/admin/tickets/$id/index'
import { Route as appAdminOrganizationsOrganizationIdIndexImport } from './routes/(app)/admin/organizations/$organizationId/index'
import { Route as appAdminAcademyStatsIndexImport } from './routes/(app)/admin/academy/stats/index'
import { Route as fullscreenUsersIdEditPersonalInfoImport } from './routes/(fullscreen)/users/$id/edit/personal-info'
import { Route as fullscreenUsersIdEditCoursesImport } from './routes/(fullscreen)/users/$id/edit/courses'
import { Route as fullscreenUsersIdEditAccountImport } from './routes/(fullscreen)/users/$id/edit/account'
import { Route as fullscreenHomeAcademyLectureIdSegmentIdImport } from './routes/(fullscreen)/home/academy/$lectureId/$segmentId'
import { Route as fullscreenGroupsIdEditUsersImport } from './routes/(fullscreen)/groups/$id/edit/users'
import { Route as fullscreenGroupsIdEditSettingsImport } from './routes/(fullscreen)/groups/$id/edit/settings'
import { Route as fullscreenGroupsIdEditCoursesImport } from './routes/(fullscreen)/groups/$id/edit/courses'
import { Route as appAdminAcademyStatsLectureIdIndexImport } from './routes/(app)/admin/academy/stats/$lectureId/index'
import { Route as fullscreenAdminTicketsIdScreenshotBugIdImport } from './routes/(fullscreen)/admin.tickets.$id.screenshot.$bugId'
import { Route as appCoursesTypePublicCourseUuidLessonsPublicLessonUuidImport } from './routes/(app)/courses_.$type.$publicCourseUuid/lessons.$publicLessonUuid'
import { Route as appAdminOrganizationsOrganizationIdSchoolsSchoolIdImport } from './routes/(app)/admin/organizations/$organizationId/schools/$schoolId'
import { Route as appAdminAcademyStatsLectureIdSchoolIdImport } from './routes/(app)/admin/academy/stats/$lectureId/$schoolId'

// Create/Update Routes

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const ActivateRoute = ActivateImport.update({
  id: '/activate',
  path: '/activate',
  getParentRoute: () => rootRoute,
} as any)

const appRouteRoute = appRouteImport.update({
  id: '/(app)',
  getParentRoute: () => IndexRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const CourseEditorPublicUuidRoute = CourseEditorPublicUuidImport.update({
  id: '/course-editor/$publicUuid',
  path: '/course-editor/$publicUuid',
  getParentRoute: () => rootRoute,
} as any)

const fullscreenWelcomeRoute = fullscreenWelcomeImport.update({
  id: '/(fullscreen)/welcome',
  path: '/welcome',
  getParentRoute: () => rootRoute,
} as any)

const appSimulatorsRoute = appSimulatorsImport.update({
  id: '/simulators',
  path: '/simulators',
  getParentRoute: () => appRouteRoute,
} as any)

const appAboutRoute = appAboutImport.update({
  id: '/about',
  path: '/about',
  getParentRoute: () => appRouteRoute,
} as any)

const appHomeRouteRoute = appHomeRouteImport.update({
  id: '/home',
  path: '/home',
  getParentRoute: () => appRouteRoute,
} as any)

const appCoursesRouteRoute = appCoursesRouteImport.update({
  id: '/courses',
  path: '/courses',
  getParentRoute: () => appRouteRoute,
} as any)

const appAdminRouteRoute = appAdminRouteImport.update({
  id: '/admin',
  path: '/admin',
  getParentRoute: () => appRouteRoute,
} as any)

const appUsersIndexRoute = appUsersIndexImport.update({
  id: '/users/',
  path: '/users/',
  getParentRoute: () => appRouteRoute,
} as any)

const appSupportIndexRoute = appSupportIndexImport.update({
  id: '/support/',
  path: '/support/',
  getParentRoute: () => appRouteRoute,
} as any)

const appHomeIndexRoute = appHomeIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => appHomeRouteRoute,
} as any)

const appGroupsIndexRoute = appGroupsIndexImport.update({
  id: '/groups/',
  path: '/groups/',
  getParentRoute: () => appRouteRoute,
} as any)

const appCoursesIndexRoute = appCoursesIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => appCoursesRouteRoute,
} as any)

const appAdminIndexRoute = appAdminIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => appAdminRouteRoute,
} as any)

const OauthGithubCallbackRoute = OauthGithubCallbackImport.update({
  id: '/oauth/github/callback',
  path: '/oauth/github/callback',
  getParentRoute: () => rootRoute,
} as any)

const OauthFortnoxCallbackRoute = OauthFortnoxCallbackImport.update({
  id: '/oauth/fortnox/callback',
  path: '/oauth/fortnox/callback',
  getParentRoute: () => rootRoute,
} as any)

const appSupportInstallationRoute = appSupportInstallationImport.update({
  id: '/support/installation',
  path: '/support/installation',
  getParentRoute: () => appRouteRoute,
} as any)

const appSupportContactRoute = appSupportContactImport.update({
  id: '/support/contact',
  path: '/support/contact',
  getParentRoute: () => appRouteRoute,
} as any)

const appHomeYoutubeRoute = appHomeYoutubeImport.update({
  id: '/youtube',
  path: '/youtube',
  getParentRoute: () => appHomeRouteRoute,
} as any)

const appHomeStatsRoute = appHomeStatsImport.update({
  id: '/stats',
  path: '/stats',
  getParentRoute: () => appHomeRouteRoute,
} as any)

const appHomeStartRoute = appHomeStartImport.update({
  id: '/start',
  path: '/start',
  getParentRoute: () => appHomeRouteRoute,
} as any)

const appHomeNewsRoute = appHomeNewsImport.update({
  id: '/news',
  path: '/news',
  getParentRoute: () => appHomeRouteRoute,
} as any)

const appGroupsCreateRoute = appGroupsCreateImport.update({
  id: '/groups/create',
  path: '/groups/create',
  getParentRoute: () => appRouteRoute,
} as any)

const appAdminUsersRoute = appAdminUsersImport.update({
  id: '/users',
  path: '/users',
  getParentRoute: () => appAdminRouteRoute,
} as any)

const appAdminToplistRoute = appAdminToplistImport.update({
  id: '/toplist',
  path: '/toplist',
  getParentRoute: () => appAdminRouteRoute,
} as any)

const appAdminStatsRoute = appAdminStatsImport.update({
  id: '/stats',
  path: '/stats',
  getParentRoute: () => appAdminRouteRoute,
} as any)

const appAdminSimulatorsRoute = appAdminSimulatorsImport.update({
  id: '/simulators',
  path: '/simulators',
  getParentRoute: () => appAdminRouteRoute,
} as any)

const appAdminSchoolsRoute = appAdminSchoolsImport.update({
  id: '/schools',
  path: '/schools',
  getParentRoute: () => appAdminRouteRoute,
} as any)

const appAdminRundataRoute = appAdminRundataImport.update({
  id: '/rundata',
  path: '/rundata',
  getParentRoute: () => appAdminRouteRoute,
} as any)

const appAdminMapRoute = appAdminMapImport.update({
  id: '/map',
  path: '/map',
  getParentRoute: () => appAdminRouteRoute,
} as any)

const appAdminLicenseUpdatesRoute = appAdminLicenseUpdatesImport.update({
  id: '/license-updates',
  path: '/license-updates',
  getParentRoute: () => appAdminRouteRoute,
} as any)

const appAdminExerciseCommentsRoute = appAdminExerciseCommentsImport.update({
  id: '/exercise-comments',
  path: '/exercise-comments',
  getParentRoute: () => appAdminRouteRoute,
} as any)

const appAdminCourseEditorStatsRoute = appAdminCourseEditorStatsImport.update({
  id: '/course-editor-stats',
  path: '/course-editor-stats',
  getParentRoute: () => appAdminRouteRoute,
} as any)

const appAdminBillingRoute = appAdminBillingImport.update({
  id: '/billing',
  path: '/billing',
  getParentRoute: () => appAdminRouteRoute,
} as any)

const appUsersCreateRouteRoute = appUsersCreateRouteImport.update({
  id: '/users/create',
  path: '/users/create',
  getParentRoute: () => appRouteRoute,
} as any)

const appUsersIdRouteRoute = appUsersIdRouteImport.update({
  id: '/users/$id',
  path: '/users/$id',
  getParentRoute: () => appRouteRoute,
} as any)

const appGroupsIdRouteRoute = appGroupsIdRouteImport.update({
  id: '/groups/$id',
  path: '/groups/$id',
  getParentRoute: () => appRouteRoute,
} as any)

const appAdminTicketsRouteRoute = appAdminTicketsRouteImport.update({
  id: '/tickets',
  path: '/tickets',
  getParentRoute: () => appAdminRouteRoute,
} as any)

const appAdminAcademyRouteRoute = appAdminAcademyRouteImport.update({
  id: '/academy',
  path: '/academy',
  getParentRoute: () => appAdminRouteRoute,
} as any)

const appUsersCreateIndexRoute = appUsersCreateIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => appUsersCreateRouteRoute,
} as any)

const appUsersIdIndexRoute = appUsersIdIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => appUsersIdRouteRoute,
} as any)

const appHomeAcademyIndexRoute = appHomeAcademyIndexImport.update({
  id: '/academy/',
  path: '/academy/',
  getParentRoute: () => appHomeRouteRoute,
} as any)

const appGroupsIdIndexRoute = appGroupsIdIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => appGroupsIdRouteRoute,
} as any)

const appCoursesTypeIndexRoute = appCoursesTypeIndexImport.update({
  id: '/$type/',
  path: '/$type/',
  getParentRoute: () => appCoursesRouteRoute,
} as any)

const appAdminTicketsIndexRoute = appAdminTicketsIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => appAdminTicketsRouteRoute,
} as any)

const appAdminOrganizationsIndexRoute = appAdminOrganizationsIndexImport.update(
  {
    id: '/organizations/',
    path: '/organizations/',
    getParentRoute: () => appAdminRouteRoute,
  } as any,
)

const appAdminCompetitionsIndexRoute = appAdminCompetitionsIndexImport.update({
  id: '/competitions/',
  path: '/competitions/',
  getParentRoute: () => appAdminRouteRoute,
} as any)

const appAdminAcademyIndexRoute = appAdminAcademyIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => appAdminAcademyRouteRoute,
} as any)

const appUsersCreateNewRoute = appUsersCreateNewImport.update({
  id: '/new',
  path: '/new',
  getParentRoute: () => appUsersCreateRouteRoute,
} as any)

const appUsersCreateImportRoute = appUsersCreateImportImport.update({
  id: '/import',
  path: '/import',
  getParentRoute: () => appUsersCreateRouteRoute,
} as any)

const appUsersIdTachographRoute = appUsersIdTachographImport.update({
  id: '/tachograph',
  path: '/tachograph',
  getParentRoute: () => appUsersIdRouteRoute,
} as any)

const appUsersIdProfileRoute = appUsersIdProfileImport.update({
  id: '/profile',
  path: '/profile',
  getParentRoute: () => appUsersIdRouteRoute,
} as any)

const appUsersIdDrivingRecordRoute = appUsersIdDrivingRecordImport.update({
  id: '/driving-record',
  path: '/driving-record',
  getParentRoute: () => appUsersIdRouteRoute,
} as any)

const appUsersIdCoursesRoute = appUsersIdCoursesImport.update({
  id: '/courses',
  path: '/courses',
  getParentRoute: () => appUsersIdRouteRoute,
} as any)

const appGroupsIdTachographRoute = appGroupsIdTachographImport.update({
  id: '/tachograph',
  path: '/tachograph',
  getParentRoute: () => appGroupsIdRouteRoute,
} as any)

const appGroupsIdDrivingHistoryRoute = appGroupsIdDrivingHistoryImport.update({
  id: '/driving-history',
  path: '/driving-history',
  getParentRoute: () => appGroupsIdRouteRoute,
} as any)

const appGroupsIdCoursesRoute = appGroupsIdCoursesImport.update({
  id: '/courses',
  path: '/courses',
  getParentRoute: () => appGroupsIdRouteRoute,
} as any)

const appAdminTicketsFeedbackRoute = appAdminTicketsFeedbackImport.update({
  id: '/feedback',
  path: '/feedback',
  getParentRoute: () => appAdminTicketsRouteRoute,
} as any)

const appAdminTicketsBugreportRoute = appAdminTicketsBugreportImport.update({
  id: '/bugreport',
  path: '/bugreport',
  getParentRoute: () => appAdminTicketsRouteRoute,
} as any)

const appAdminCompetitionsCreateRoute = appAdminCompetitionsCreateImport.update(
  {
    id: '/competitions/create',
    path: '/competitions/create',
    getParentRoute: () => appAdminRouteRoute,
  } as any,
)

const appAdminCompetitionsIdRoute = appAdminCompetitionsIdImport.update({
  id: '/competitions/$id',
  path: '/competitions/$id',
  getParentRoute: () => appAdminRouteRoute,
} as any)

const appAdminAcademyCoursesRoute = appAdminAcademyCoursesImport.update({
  id: '/courses',
  path: '/courses',
  getParentRoute: () => appAdminAcademyRouteRoute,
} as any)

const fullscreenUsersIdEditRouteRoute = fullscreenUsersIdEditRouteImport.update(
  {
    id: '/(fullscreen)/users/$id/edit',
    path: '/users/$id/edit',
    getParentRoute: () => rootRoute,
  } as any,
)

const fullscreenGroupsIdEditRouteRoute =
  fullscreenGroupsIdEditRouteImport.update({
    id: '/(fullscreen)/groups/$id/edit',
    path: '/groups/$id/edit',
    getParentRoute: () => rootRoute,
  } as any)

const appCoursesTypePublicCourseUuidIndexRoute =
  appCoursesTypePublicCourseUuidIndexImport.update({
    id: '/courses_/$type/$publicCourseUuid/',
    path: '/courses/$type/$publicCourseUuid/',
    getParentRoute: () => appRouteRoute,
  } as any)

const appAdminTicketsIdIndexRoute = appAdminTicketsIdIndexImport.update({
  id: '/$id/',
  path: '/$id/',
  getParentRoute: () => appAdminTicketsRouteRoute,
} as any)

const appAdminOrganizationsOrganizationIdIndexRoute =
  appAdminOrganizationsOrganizationIdIndexImport.update({
    id: '/organizations/$organizationId/',
    path: '/organizations/$organizationId/',
    getParentRoute: () => appAdminRouteRoute,
  } as any)

const appAdminAcademyStatsIndexRoute = appAdminAcademyStatsIndexImport.update({
  id: '/stats/',
  path: '/stats/',
  getParentRoute: () => appAdminAcademyRouteRoute,
} as any)

const fullscreenUsersIdEditPersonalInfoRoute =
  fullscreenUsersIdEditPersonalInfoImport.update({
    id: '/personal-info',
    path: '/personal-info',
    getParentRoute: () => fullscreenUsersIdEditRouteRoute,
  } as any)

const fullscreenUsersIdEditCoursesRoute =
  fullscreenUsersIdEditCoursesImport.update({
    id: '/courses',
    path: '/courses',
    getParentRoute: () => fullscreenUsersIdEditRouteRoute,
  } as any)

const fullscreenUsersIdEditAccountRoute =
  fullscreenUsersIdEditAccountImport.update({
    id: '/account',
    path: '/account',
    getParentRoute: () => fullscreenUsersIdEditRouteRoute,
  } as any)

const fullscreenHomeAcademyLectureIdSegmentIdRoute =
  fullscreenHomeAcademyLectureIdSegmentIdImport.update({
    id: '/(fullscreen)/home/academy/$lectureId/$segmentId',
    path: '/home/academy/$lectureId/$segmentId',
    getParentRoute: () => rootRoute,
  } as any)

const fullscreenGroupsIdEditUsersRoute =
  fullscreenGroupsIdEditUsersImport.update({
    id: '/users',
    path: '/users',
    getParentRoute: () => fullscreenGroupsIdEditRouteRoute,
  } as any)

const fullscreenGroupsIdEditSettingsRoute =
  fullscreenGroupsIdEditSettingsImport.update({
    id: '/settings',
    path: '/settings',
    getParentRoute: () => fullscreenGroupsIdEditRouteRoute,
  } as any)

const fullscreenGroupsIdEditCoursesRoute =
  fullscreenGroupsIdEditCoursesImport.update({
    id: '/courses',
    path: '/courses',
    getParentRoute: () => fullscreenGroupsIdEditRouteRoute,
  } as any)

const appAdminAcademyStatsLectureIdIndexRoute =
  appAdminAcademyStatsLectureIdIndexImport.update({
    id: '/stats/$lectureId/',
    path: '/stats/$lectureId/',
    getParentRoute: () => appAdminAcademyRouteRoute,
  } as any)

const fullscreenAdminTicketsIdScreenshotBugIdRoute =
  fullscreenAdminTicketsIdScreenshotBugIdImport.update({
    id: '/(fullscreen)/admin/tickets/$id/screenshot/$bugId',
    path: '/admin/tickets/$id/screenshot/$bugId',
    getParentRoute: () => rootRoute,
  } as any)

const appCoursesTypePublicCourseUuidLessonsPublicLessonUuidRoute =
  appCoursesTypePublicCourseUuidLessonsPublicLessonUuidImport.update({
    id: '/courses_/$type/$publicCourseUuid/lessons/$publicLessonUuid',
    path: '/courses/$type/$publicCourseUuid/lessons/$publicLessonUuid',
    getParentRoute: () => appRouteRoute,
  } as any)

const appAdminOrganizationsOrganizationIdSchoolsSchoolIdRoute =
  appAdminOrganizationsOrganizationIdSchoolsSchoolIdImport.update({
    id: '/organizations/$organizationId/schools/$schoolId',
    path: '/organizations/$organizationId/schools/$schoolId',
    getParentRoute: () => appAdminRouteRoute,
  } as any)

const appAdminAcademyStatsLectureIdSchoolIdRoute =
  appAdminAcademyStatsLectureIdSchoolIdImport.update({
    id: '/stats/$lectureId/$schoolId',
    path: '/stats/$lectureId/$schoolId',
    getParentRoute: () => appAdminAcademyRouteRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/(app)': {
      id: '/(app)'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof appRouteImport
      parentRoute: typeof IndexRoute
    }
    '/activate': {
      id: '/activate'
      path: '/activate'
      fullPath: '/activate'
      preLoaderRoute: typeof ActivateImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/(app)/admin': {
      id: '/(app)/admin'
      path: '/admin'
      fullPath: '/admin'
      preLoaderRoute: typeof appAdminRouteImport
      parentRoute: typeof appRouteImport
    }
    '/(app)/courses': {
      id: '/(app)/courses'
      path: '/courses'
      fullPath: '/courses'
      preLoaderRoute: typeof appCoursesRouteImport
      parentRoute: typeof appRouteImport
    }
    '/(app)/home': {
      id: '/(app)/home'
      path: '/home'
      fullPath: '/home'
      preLoaderRoute: typeof appHomeRouteImport
      parentRoute: typeof appRouteImport
    }
    '/(app)/about': {
      id: '/(app)/about'
      path: '/about'
      fullPath: '/about'
      preLoaderRoute: typeof appAboutImport
      parentRoute: typeof appRouteImport
    }
    '/(app)/simulators': {
      id: '/(app)/simulators'
      path: '/simulators'
      fullPath: '/simulators'
      preLoaderRoute: typeof appSimulatorsImport
      parentRoute: typeof appRouteImport
    }
    '/(fullscreen)/welcome': {
      id: '/(fullscreen)/welcome'
      path: '/welcome'
      fullPath: '/welcome'
      preLoaderRoute: typeof fullscreenWelcomeImport
      parentRoute: typeof rootRoute
    }
    '/course-editor/$publicUuid': {
      id: '/course-editor/$publicUuid'
      path: '/course-editor/$publicUuid'
      fullPath: '/course-editor/$publicUuid'
      preLoaderRoute: typeof CourseEditorPublicUuidImport
      parentRoute: typeof rootRoute
    }
    '/(app)/admin/academy': {
      id: '/(app)/admin/academy'
      path: '/academy'
      fullPath: '/admin/academy'
      preLoaderRoute: typeof appAdminAcademyRouteImport
      parentRoute: typeof appAdminRouteImport
    }
    '/(app)/admin/tickets': {
      id: '/(app)/admin/tickets'
      path: '/tickets'
      fullPath: '/admin/tickets'
      preLoaderRoute: typeof appAdminTicketsRouteImport
      parentRoute: typeof appAdminRouteImport
    }
    '/(app)/groups/$id': {
      id: '/(app)/groups/$id'
      path: '/groups/$id'
      fullPath: '/groups/$id'
      preLoaderRoute: typeof appGroupsIdRouteImport
      parentRoute: typeof appRouteImport
    }
    '/(app)/users/$id': {
      id: '/(app)/users/$id'
      path: '/users/$id'
      fullPath: '/users/$id'
      preLoaderRoute: typeof appUsersIdRouteImport
      parentRoute: typeof appRouteImport
    }
    '/(app)/users/create': {
      id: '/(app)/users/create'
      path: '/users/create'
      fullPath: '/users/create'
      preLoaderRoute: typeof appUsersCreateRouteImport
      parentRoute: typeof appRouteImport
    }
    '/(app)/admin/billing': {
      id: '/(app)/admin/billing'
      path: '/billing'
      fullPath: '/admin/billing'
      preLoaderRoute: typeof appAdminBillingImport
      parentRoute: typeof appAdminRouteImport
    }
    '/(app)/admin/course-editor-stats': {
      id: '/(app)/admin/course-editor-stats'
      path: '/course-editor-stats'
      fullPath: '/admin/course-editor-stats'
      preLoaderRoute: typeof appAdminCourseEditorStatsImport
      parentRoute: typeof appAdminRouteImport
    }
    '/(app)/admin/exercise-comments': {
      id: '/(app)/admin/exercise-comments'
      path: '/exercise-comments'
      fullPath: '/admin/exercise-comments'
      preLoaderRoute: typeof appAdminExerciseCommentsImport
      parentRoute: typeof appAdminRouteImport
    }
    '/(app)/admin/license-updates': {
      id: '/(app)/admin/license-updates'
      path: '/license-updates'
      fullPath: '/admin/license-updates'
      preLoaderRoute: typeof appAdminLicenseUpdatesImport
      parentRoute: typeof appAdminRouteImport
    }
    '/(app)/admin/map': {
      id: '/(app)/admin/map'
      path: '/map'
      fullPath: '/admin/map'
      preLoaderRoute: typeof appAdminMapImport
      parentRoute: typeof appAdminRouteImport
    }
    '/(app)/admin/rundata': {
      id: '/(app)/admin/rundata'
      path: '/rundata'
      fullPath: '/admin/rundata'
      preLoaderRoute: typeof appAdminRundataImport
      parentRoute: typeof appAdminRouteImport
    }
    '/(app)/admin/schools': {
      id: '/(app)/admin/schools'
      path: '/schools'
      fullPath: '/admin/schools'
      preLoaderRoute: typeof appAdminSchoolsImport
      parentRoute: typeof appAdminRouteImport
    }
    '/(app)/admin/simulators': {
      id: '/(app)/admin/simulators'
      path: '/simulators'
      fullPath: '/admin/simulators'
      preLoaderRoute: typeof appAdminSimulatorsImport
      parentRoute: typeof appAdminRouteImport
    }
    '/(app)/admin/stats': {
      id: '/(app)/admin/stats'
      path: '/stats'
      fullPath: '/admin/stats'
      preLoaderRoute: typeof appAdminStatsImport
      parentRoute: typeof appAdminRouteImport
    }
    '/(app)/admin/toplist': {
      id: '/(app)/admin/toplist'
      path: '/toplist'
      fullPath: '/admin/toplist'
      preLoaderRoute: typeof appAdminToplistImport
      parentRoute: typeof appAdminRouteImport
    }
    '/(app)/admin/users': {
      id: '/(app)/admin/users'
      path: '/users'
      fullPath: '/admin/users'
      preLoaderRoute: typeof appAdminUsersImport
      parentRoute: typeof appAdminRouteImport
    }
    '/(app)/groups/create': {
      id: '/(app)/groups/create'
      path: '/groups/create'
      fullPath: '/groups/create'
      preLoaderRoute: typeof appGroupsCreateImport
      parentRoute: typeof appRouteImport
    }
    '/(app)/home/news': {
      id: '/(app)/home/news'
      path: '/news'
      fullPath: '/home/news'
      preLoaderRoute: typeof appHomeNewsImport
      parentRoute: typeof appHomeRouteImport
    }
    '/(app)/home/start': {
      id: '/(app)/home/start'
      path: '/start'
      fullPath: '/home/start'
      preLoaderRoute: typeof appHomeStartImport
      parentRoute: typeof appHomeRouteImport
    }
    '/(app)/home/stats': {
      id: '/(app)/home/stats'
      path: '/stats'
      fullPath: '/home/stats'
      preLoaderRoute: typeof appHomeStatsImport
      parentRoute: typeof appHomeRouteImport
    }
    '/(app)/home/youtube': {
      id: '/(app)/home/youtube'
      path: '/youtube'
      fullPath: '/home/youtube'
      preLoaderRoute: typeof appHomeYoutubeImport
      parentRoute: typeof appHomeRouteImport
    }
    '/(app)/support/contact': {
      id: '/(app)/support/contact'
      path: '/support/contact'
      fullPath: '/support/contact'
      preLoaderRoute: typeof appSupportContactImport
      parentRoute: typeof appRouteImport
    }
    '/(app)/support/installation': {
      id: '/(app)/support/installation'
      path: '/support/installation'
      fullPath: '/support/installation'
      preLoaderRoute: typeof appSupportInstallationImport
      parentRoute: typeof appRouteImport
    }
    '/oauth/fortnox/callback': {
      id: '/oauth/fortnox/callback'
      path: '/oauth/fortnox/callback'
      fullPath: '/oauth/fortnox/callback'
      preLoaderRoute: typeof OauthFortnoxCallbackImport
      parentRoute: typeof rootRoute
    }
    '/oauth/github/callback': {
      id: '/oauth/github/callback'
      path: '/oauth/github/callback'
      fullPath: '/oauth/github/callback'
      preLoaderRoute: typeof OauthGithubCallbackImport
      parentRoute: typeof rootRoute
    }
    '/(app)/admin/': {
      id: '/(app)/admin/'
      path: '/'
      fullPath: '/admin/'
      preLoaderRoute: typeof appAdminIndexImport
      parentRoute: typeof appAdminRouteImport
    }
    '/(app)/courses/': {
      id: '/(app)/courses/'
      path: '/'
      fullPath: '/courses/'
      preLoaderRoute: typeof appCoursesIndexImport
      parentRoute: typeof appCoursesRouteImport
    }
    '/(app)/groups/': {
      id: '/(app)/groups/'
      path: '/groups'
      fullPath: '/groups'
      preLoaderRoute: typeof appGroupsIndexImport
      parentRoute: typeof appRouteImport
    }
    '/(app)/home/': {
      id: '/(app)/home/'
      path: '/'
      fullPath: '/home/'
      preLoaderRoute: typeof appHomeIndexImport
      parentRoute: typeof appHomeRouteImport
    }
    '/(app)/support/': {
      id: '/(app)/support/'
      path: '/support'
      fullPath: '/support'
      preLoaderRoute: typeof appSupportIndexImport
      parentRoute: typeof appRouteImport
    }
    '/(app)/users/': {
      id: '/(app)/users/'
      path: '/users'
      fullPath: '/users'
      preLoaderRoute: typeof appUsersIndexImport
      parentRoute: typeof appRouteImport
    }
    '/(fullscreen)/groups/$id/edit': {
      id: '/(fullscreen)/groups/$id/edit'
      path: '/groups/$id/edit'
      fullPath: '/groups/$id/edit'
      preLoaderRoute: typeof fullscreenGroupsIdEditRouteImport
      parentRoute: typeof rootRoute
    }
    '/(fullscreen)/users/$id/edit': {
      id: '/(fullscreen)/users/$id/edit'
      path: '/users/$id/edit'
      fullPath: '/users/$id/edit'
      preLoaderRoute: typeof fullscreenUsersIdEditRouteImport
      parentRoute: typeof rootRoute
    }
    '/(app)/admin/academy/courses': {
      id: '/(app)/admin/academy/courses'
      path: '/courses'
      fullPath: '/admin/academy/courses'
      preLoaderRoute: typeof appAdminAcademyCoursesImport
      parentRoute: typeof appAdminAcademyRouteImport
    }
    '/(app)/admin/competitions/$id': {
      id: '/(app)/admin/competitions/$id'
      path: '/competitions/$id'
      fullPath: '/admin/competitions/$id'
      preLoaderRoute: typeof appAdminCompetitionsIdImport
      parentRoute: typeof appAdminRouteImport
    }
    '/(app)/admin/competitions/create': {
      id: '/(app)/admin/competitions/create'
      path: '/competitions/create'
      fullPath: '/admin/competitions/create'
      preLoaderRoute: typeof appAdminCompetitionsCreateImport
      parentRoute: typeof appAdminRouteImport
    }
    '/(app)/admin/tickets/bugreport': {
      id: '/(app)/admin/tickets/bugreport'
      path: '/bugreport'
      fullPath: '/admin/tickets/bugreport'
      preLoaderRoute: typeof appAdminTicketsBugreportImport
      parentRoute: typeof appAdminTicketsRouteImport
    }
    '/(app)/admin/tickets/feedback': {
      id: '/(app)/admin/tickets/feedback'
      path: '/feedback'
      fullPath: '/admin/tickets/feedback'
      preLoaderRoute: typeof appAdminTicketsFeedbackImport
      parentRoute: typeof appAdminTicketsRouteImport
    }
    '/(app)/groups/$id/courses': {
      id: '/(app)/groups/$id/courses'
      path: '/courses'
      fullPath: '/groups/$id/courses'
      preLoaderRoute: typeof appGroupsIdCoursesImport
      parentRoute: typeof appGroupsIdRouteImport
    }
    '/(app)/groups/$id/driving-history': {
      id: '/(app)/groups/$id/driving-history'
      path: '/driving-history'
      fullPath: '/groups/$id/driving-history'
      preLoaderRoute: typeof appGroupsIdDrivingHistoryImport
      parentRoute: typeof appGroupsIdRouteImport
    }
    '/(app)/groups/$id/tachograph': {
      id: '/(app)/groups/$id/tachograph'
      path: '/tachograph'
      fullPath: '/groups/$id/tachograph'
      preLoaderRoute: typeof appGroupsIdTachographImport
      parentRoute: typeof appGroupsIdRouteImport
    }
    '/(app)/users/$id/courses': {
      id: '/(app)/users/$id/courses'
      path: '/courses'
      fullPath: '/users/$id/courses'
      preLoaderRoute: typeof appUsersIdCoursesImport
      parentRoute: typeof appUsersIdRouteImport
    }
    '/(app)/users/$id/driving-record': {
      id: '/(app)/users/$id/driving-record'
      path: '/driving-record'
      fullPath: '/users/$id/driving-record'
      preLoaderRoute: typeof appUsersIdDrivingRecordImport
      parentRoute: typeof appUsersIdRouteImport
    }
    '/(app)/users/$id/profile': {
      id: '/(app)/users/$id/profile'
      path: '/profile'
      fullPath: '/users/$id/profile'
      preLoaderRoute: typeof appUsersIdProfileImport
      parentRoute: typeof appUsersIdRouteImport
    }
    '/(app)/users/$id/tachograph': {
      id: '/(app)/users/$id/tachograph'
      path: '/tachograph'
      fullPath: '/users/$id/tachograph'
      preLoaderRoute: typeof appUsersIdTachographImport
      parentRoute: typeof appUsersIdRouteImport
    }
    '/(app)/users/create/import': {
      id: '/(app)/users/create/import'
      path: '/import'
      fullPath: '/users/create/import'
      preLoaderRoute: typeof appUsersCreateImportImport
      parentRoute: typeof appUsersCreateRouteImport
    }
    '/(app)/users/create/new': {
      id: '/(app)/users/create/new'
      path: '/new'
      fullPath: '/users/create/new'
      preLoaderRoute: typeof appUsersCreateNewImport
      parentRoute: typeof appUsersCreateRouteImport
    }
    '/(app)/admin/academy/': {
      id: '/(app)/admin/academy/'
      path: '/'
      fullPath: '/admin/academy/'
      preLoaderRoute: typeof appAdminAcademyIndexImport
      parentRoute: typeof appAdminAcademyRouteImport
    }
    '/(app)/admin/competitions/': {
      id: '/(app)/admin/competitions/'
      path: '/competitions'
      fullPath: '/admin/competitions'
      preLoaderRoute: typeof appAdminCompetitionsIndexImport
      parentRoute: typeof appAdminRouteImport
    }
    '/(app)/admin/organizations/': {
      id: '/(app)/admin/organizations/'
      path: '/organizations'
      fullPath: '/admin/organizations'
      preLoaderRoute: typeof appAdminOrganizationsIndexImport
      parentRoute: typeof appAdminRouteImport
    }
    '/(app)/admin/tickets/': {
      id: '/(app)/admin/tickets/'
      path: '/'
      fullPath: '/admin/tickets/'
      preLoaderRoute: typeof appAdminTicketsIndexImport
      parentRoute: typeof appAdminTicketsRouteImport
    }
    '/(app)/courses/$type/': {
      id: '/(app)/courses/$type/'
      path: '/$type'
      fullPath: '/courses/$type'
      preLoaderRoute: typeof appCoursesTypeIndexImport
      parentRoute: typeof appCoursesRouteImport
    }
    '/(app)/groups/$id/': {
      id: '/(app)/groups/$id/'
      path: '/'
      fullPath: '/groups/$id/'
      preLoaderRoute: typeof appGroupsIdIndexImport
      parentRoute: typeof appGroupsIdRouteImport
    }
    '/(app)/home/academy/': {
      id: '/(app)/home/academy/'
      path: '/academy'
      fullPath: '/home/academy'
      preLoaderRoute: typeof appHomeAcademyIndexImport
      parentRoute: typeof appHomeRouteImport
    }
    '/(app)/users/$id/': {
      id: '/(app)/users/$id/'
      path: '/'
      fullPath: '/users/$id/'
      preLoaderRoute: typeof appUsersIdIndexImport
      parentRoute: typeof appUsersIdRouteImport
    }
    '/(app)/users/create/': {
      id: '/(app)/users/create/'
      path: '/'
      fullPath: '/users/create/'
      preLoaderRoute: typeof appUsersCreateIndexImport
      parentRoute: typeof appUsersCreateRouteImport
    }
    '/(fullscreen)/groups/$id/edit/courses': {
      id: '/(fullscreen)/groups/$id/edit/courses'
      path: '/courses'
      fullPath: '/groups/$id/edit/courses'
      preLoaderRoute: typeof fullscreenGroupsIdEditCoursesImport
      parentRoute: typeof fullscreenGroupsIdEditRouteImport
    }
    '/(fullscreen)/groups/$id/edit/settings': {
      id: '/(fullscreen)/groups/$id/edit/settings'
      path: '/settings'
      fullPath: '/groups/$id/edit/settings'
      preLoaderRoute: typeof fullscreenGroupsIdEditSettingsImport
      parentRoute: typeof fullscreenGroupsIdEditRouteImport
    }
    '/(fullscreen)/groups/$id/edit/users': {
      id: '/(fullscreen)/groups/$id/edit/users'
      path: '/users'
      fullPath: '/groups/$id/edit/users'
      preLoaderRoute: typeof fullscreenGroupsIdEditUsersImport
      parentRoute: typeof fullscreenGroupsIdEditRouteImport
    }
    '/(fullscreen)/home/academy/$lectureId/$segmentId': {
      id: '/(fullscreen)/home/academy/$lectureId/$segmentId'
      path: '/home/academy/$lectureId/$segmentId'
      fullPath: '/home/academy/$lectureId/$segmentId'
      preLoaderRoute: typeof fullscreenHomeAcademyLectureIdSegmentIdImport
      parentRoute: typeof rootRoute
    }
    '/(fullscreen)/users/$id/edit/account': {
      id: '/(fullscreen)/users/$id/edit/account'
      path: '/account'
      fullPath: '/users/$id/edit/account'
      preLoaderRoute: typeof fullscreenUsersIdEditAccountImport
      parentRoute: typeof fullscreenUsersIdEditRouteImport
    }
    '/(fullscreen)/users/$id/edit/courses': {
      id: '/(fullscreen)/users/$id/edit/courses'
      path: '/courses'
      fullPath: '/users/$id/edit/courses'
      preLoaderRoute: typeof fullscreenUsersIdEditCoursesImport
      parentRoute: typeof fullscreenUsersIdEditRouteImport
    }
    '/(fullscreen)/users/$id/edit/personal-info': {
      id: '/(fullscreen)/users/$id/edit/personal-info'
      path: '/personal-info'
      fullPath: '/users/$id/edit/personal-info'
      preLoaderRoute: typeof fullscreenUsersIdEditPersonalInfoImport
      parentRoute: typeof fullscreenUsersIdEditRouteImport
    }
    '/(app)/admin/academy/stats/': {
      id: '/(app)/admin/academy/stats/'
      path: '/stats'
      fullPath: '/admin/academy/stats'
      preLoaderRoute: typeof appAdminAcademyStatsIndexImport
      parentRoute: typeof appAdminAcademyRouteImport
    }
    '/(app)/admin/organizations/$organizationId/': {
      id: '/(app)/admin/organizations/$organizationId/'
      path: '/organizations/$organizationId'
      fullPath: '/admin/organizations/$organizationId'
      preLoaderRoute: typeof appAdminOrganizationsOrganizationIdIndexImport
      parentRoute: typeof appAdminRouteImport
    }
    '/(app)/admin/tickets/$id/': {
      id: '/(app)/admin/tickets/$id/'
      path: '/$id'
      fullPath: '/admin/tickets/$id'
      preLoaderRoute: typeof appAdminTicketsIdIndexImport
      parentRoute: typeof appAdminTicketsRouteImport
    }
    '/(app)/courses_/$type/$publicCourseUuid/': {
      id: '/(app)/courses_/$type/$publicCourseUuid/'
      path: '/courses/$type/$publicCourseUuid'
      fullPath: '/courses/$type/$publicCourseUuid'
      preLoaderRoute: typeof appCoursesTypePublicCourseUuidIndexImport
      parentRoute: typeof appRouteImport
    }
    '/(app)/admin/academy/stats/$lectureId/$schoolId': {
      id: '/(app)/admin/academy/stats/$lectureId/$schoolId'
      path: '/stats/$lectureId/$schoolId'
      fullPath: '/admin/academy/stats/$lectureId/$schoolId'
      preLoaderRoute: typeof appAdminAcademyStatsLectureIdSchoolIdImport
      parentRoute: typeof appAdminAcademyRouteImport
    }
    '/(app)/admin/organizations/$organizationId/schools/$schoolId': {
      id: '/(app)/admin/organizations/$organizationId/schools/$schoolId'
      path: '/organizations/$organizationId/schools/$schoolId'
      fullPath: '/admin/organizations/$organizationId/schools/$schoolId'
      preLoaderRoute: typeof appAdminOrganizationsOrganizationIdSchoolsSchoolIdImport
      parentRoute: typeof appAdminRouteImport
    }
    '/(app)/courses_/$type/$publicCourseUuid/lessons/$publicLessonUuid': {
      id: '/(app)/courses_/$type/$publicCourseUuid/lessons/$publicLessonUuid'
      path: '/courses/$type/$publicCourseUuid/lessons/$publicLessonUuid'
      fullPath: '/courses/$type/$publicCourseUuid/lessons/$publicLessonUuid'
      preLoaderRoute: typeof appCoursesTypePublicCourseUuidLessonsPublicLessonUuidImport
      parentRoute: typeof appRouteImport
    }
    '/(fullscreen)/admin/tickets/$id/screenshot/$bugId': {
      id: '/(fullscreen)/admin/tickets/$id/screenshot/$bugId'
      path: '/admin/tickets/$id/screenshot/$bugId'
      fullPath: '/admin/tickets/$id/screenshot/$bugId'
      preLoaderRoute: typeof fullscreenAdminTicketsIdScreenshotBugIdImport
      parentRoute: typeof rootRoute
    }
    '/(app)/admin/academy/stats/$lectureId/': {
      id: '/(app)/admin/academy/stats/$lectureId/'
      path: '/stats/$lectureId'
      fullPath: '/admin/academy/stats/$lectureId'
      preLoaderRoute: typeof appAdminAcademyStatsLectureIdIndexImport
      parentRoute: typeof appAdminAcademyRouteImport
    }
  }
}

// Create and export the route tree

interface appAdminAcademyRouteRouteChildren {
  appAdminAcademyCoursesRoute: typeof appAdminAcademyCoursesRoute
  appAdminAcademyIndexRoute: typeof appAdminAcademyIndexRoute
  appAdminAcademyStatsIndexRoute: typeof appAdminAcademyStatsIndexRoute
  appAdminAcademyStatsLectureIdSchoolIdRoute: typeof appAdminAcademyStatsLectureIdSchoolIdRoute
  appAdminAcademyStatsLectureIdIndexRoute: typeof appAdminAcademyStatsLectureIdIndexRoute
}

const appAdminAcademyRouteRouteChildren: appAdminAcademyRouteRouteChildren = {
  appAdminAcademyCoursesRoute: appAdminAcademyCoursesRoute,
  appAdminAcademyIndexRoute: appAdminAcademyIndexRoute,
  appAdminAcademyStatsIndexRoute: appAdminAcademyStatsIndexRoute,
  appAdminAcademyStatsLectureIdSchoolIdRoute:
    appAdminAcademyStatsLectureIdSchoolIdRoute,
  appAdminAcademyStatsLectureIdIndexRoute:
    appAdminAcademyStatsLectureIdIndexRoute,
}

const appAdminAcademyRouteRouteWithChildren =
  appAdminAcademyRouteRoute._addFileChildren(appAdminAcademyRouteRouteChildren)

interface appAdminTicketsRouteRouteChildren {
  appAdminTicketsBugreportRoute: typeof appAdminTicketsBugreportRoute
  appAdminTicketsFeedbackRoute: typeof appAdminTicketsFeedbackRoute
  appAdminTicketsIndexRoute: typeof appAdminTicketsIndexRoute
  appAdminTicketsIdIndexRoute: typeof appAdminTicketsIdIndexRoute
}

const appAdminTicketsRouteRouteChildren: appAdminTicketsRouteRouteChildren = {
  appAdminTicketsBugreportRoute: appAdminTicketsBugreportRoute,
  appAdminTicketsFeedbackRoute: appAdminTicketsFeedbackRoute,
  appAdminTicketsIndexRoute: appAdminTicketsIndexRoute,
  appAdminTicketsIdIndexRoute: appAdminTicketsIdIndexRoute,
}

const appAdminTicketsRouteRouteWithChildren =
  appAdminTicketsRouteRoute._addFileChildren(appAdminTicketsRouteRouteChildren)

interface appAdminRouteRouteChildren {
  appAdminAcademyRouteRoute: typeof appAdminAcademyRouteRouteWithChildren
  appAdminTicketsRouteRoute: typeof appAdminTicketsRouteRouteWithChildren
  appAdminBillingRoute: typeof appAdminBillingRoute
  appAdminCourseEditorStatsRoute: typeof appAdminCourseEditorStatsRoute
  appAdminExerciseCommentsRoute: typeof appAdminExerciseCommentsRoute
  appAdminLicenseUpdatesRoute: typeof appAdminLicenseUpdatesRoute
  appAdminMapRoute: typeof appAdminMapRoute
  appAdminRundataRoute: typeof appAdminRundataRoute
  appAdminSchoolsRoute: typeof appAdminSchoolsRoute
  appAdminSimulatorsRoute: typeof appAdminSimulatorsRoute
  appAdminStatsRoute: typeof appAdminStatsRoute
  appAdminToplistRoute: typeof appAdminToplistRoute
  appAdminUsersRoute: typeof appAdminUsersRoute
  appAdminIndexRoute: typeof appAdminIndexRoute
  appAdminCompetitionsIdRoute: typeof appAdminCompetitionsIdRoute
  appAdminCompetitionsCreateRoute: typeof appAdminCompetitionsCreateRoute
  appAdminCompetitionsIndexRoute: typeof appAdminCompetitionsIndexRoute
  appAdminOrganizationsIndexRoute: typeof appAdminOrganizationsIndexRoute
  appAdminOrganizationsOrganizationIdIndexRoute: typeof appAdminOrganizationsOrganizationIdIndexRoute
  appAdminOrganizationsOrganizationIdSchoolsSchoolIdRoute: typeof appAdminOrganizationsOrganizationIdSchoolsSchoolIdRoute
}

const appAdminRouteRouteChildren: appAdminRouteRouteChildren = {
  appAdminAcademyRouteRoute: appAdminAcademyRouteRouteWithChildren,
  appAdminTicketsRouteRoute: appAdminTicketsRouteRouteWithChildren,
  appAdminBillingRoute: appAdminBillingRoute,
  appAdminCourseEditorStatsRoute: appAdminCourseEditorStatsRoute,
  appAdminExerciseCommentsRoute: appAdminExerciseCommentsRoute,
  appAdminLicenseUpdatesRoute: appAdminLicenseUpdatesRoute,
  appAdminMapRoute: appAdminMapRoute,
  appAdminRundataRoute: appAdminRundataRoute,
  appAdminSchoolsRoute: appAdminSchoolsRoute,
  appAdminSimulatorsRoute: appAdminSimulatorsRoute,
  appAdminStatsRoute: appAdminStatsRoute,
  appAdminToplistRoute: appAdminToplistRoute,
  appAdminUsersRoute: appAdminUsersRoute,
  appAdminIndexRoute: appAdminIndexRoute,
  appAdminCompetitionsIdRoute: appAdminCompetitionsIdRoute,
  appAdminCompetitionsCreateRoute: appAdminCompetitionsCreateRoute,
  appAdminCompetitionsIndexRoute: appAdminCompetitionsIndexRoute,
  appAdminOrganizationsIndexRoute: appAdminOrganizationsIndexRoute,
  appAdminOrganizationsOrganizationIdIndexRoute:
    appAdminOrganizationsOrganizationIdIndexRoute,
  appAdminOrganizationsOrganizationIdSchoolsSchoolIdRoute:
    appAdminOrganizationsOrganizationIdSchoolsSchoolIdRoute,
}

const appAdminRouteRouteWithChildren = appAdminRouteRoute._addFileChildren(
  appAdminRouteRouteChildren,
)

interface appCoursesRouteRouteChildren {
  appCoursesIndexRoute: typeof appCoursesIndexRoute
  appCoursesTypeIndexRoute: typeof appCoursesTypeIndexRoute
}

const appCoursesRouteRouteChildren: appCoursesRouteRouteChildren = {
  appCoursesIndexRoute: appCoursesIndexRoute,
  appCoursesTypeIndexRoute: appCoursesTypeIndexRoute,
}

const appCoursesRouteRouteWithChildren = appCoursesRouteRoute._addFileChildren(
  appCoursesRouteRouteChildren,
)

interface appHomeRouteRouteChildren {
  appHomeNewsRoute: typeof appHomeNewsRoute
  appHomeStartRoute: typeof appHomeStartRoute
  appHomeStatsRoute: typeof appHomeStatsRoute
  appHomeYoutubeRoute: typeof appHomeYoutubeRoute
  appHomeIndexRoute: typeof appHomeIndexRoute
  appHomeAcademyIndexRoute: typeof appHomeAcademyIndexRoute
}

const appHomeRouteRouteChildren: appHomeRouteRouteChildren = {
  appHomeNewsRoute: appHomeNewsRoute,
  appHomeStartRoute: appHomeStartRoute,
  appHomeStatsRoute: appHomeStatsRoute,
  appHomeYoutubeRoute: appHomeYoutubeRoute,
  appHomeIndexRoute: appHomeIndexRoute,
  appHomeAcademyIndexRoute: appHomeAcademyIndexRoute,
}

const appHomeRouteRouteWithChildren = appHomeRouteRoute._addFileChildren(
  appHomeRouteRouteChildren,
)

interface appGroupsIdRouteRouteChildren {
  appGroupsIdCoursesRoute: typeof appGroupsIdCoursesRoute
  appGroupsIdDrivingHistoryRoute: typeof appGroupsIdDrivingHistoryRoute
  appGroupsIdTachographRoute: typeof appGroupsIdTachographRoute
  appGroupsIdIndexRoute: typeof appGroupsIdIndexRoute
}

const appGroupsIdRouteRouteChildren: appGroupsIdRouteRouteChildren = {
  appGroupsIdCoursesRoute: appGroupsIdCoursesRoute,
  appGroupsIdDrivingHistoryRoute: appGroupsIdDrivingHistoryRoute,
  appGroupsIdTachographRoute: appGroupsIdTachographRoute,
  appGroupsIdIndexRoute: appGroupsIdIndexRoute,
}

const appGroupsIdRouteRouteWithChildren =
  appGroupsIdRouteRoute._addFileChildren(appGroupsIdRouteRouteChildren)

interface appUsersIdRouteRouteChildren {
  appUsersIdCoursesRoute: typeof appUsersIdCoursesRoute
  appUsersIdDrivingRecordRoute: typeof appUsersIdDrivingRecordRoute
  appUsersIdProfileRoute: typeof appUsersIdProfileRoute
  appUsersIdTachographRoute: typeof appUsersIdTachographRoute
  appUsersIdIndexRoute: typeof appUsersIdIndexRoute
}

const appUsersIdRouteRouteChildren: appUsersIdRouteRouteChildren = {
  appUsersIdCoursesRoute: appUsersIdCoursesRoute,
  appUsersIdDrivingRecordRoute: appUsersIdDrivingRecordRoute,
  appUsersIdProfileRoute: appUsersIdProfileRoute,
  appUsersIdTachographRoute: appUsersIdTachographRoute,
  appUsersIdIndexRoute: appUsersIdIndexRoute,
}

const appUsersIdRouteRouteWithChildren = appUsersIdRouteRoute._addFileChildren(
  appUsersIdRouteRouteChildren,
)

interface appUsersCreateRouteRouteChildren {
  appUsersCreateImportRoute: typeof appUsersCreateImportRoute
  appUsersCreateNewRoute: typeof appUsersCreateNewRoute
  appUsersCreateIndexRoute: typeof appUsersCreateIndexRoute
}

const appUsersCreateRouteRouteChildren: appUsersCreateRouteRouteChildren = {
  appUsersCreateImportRoute: appUsersCreateImportRoute,
  appUsersCreateNewRoute: appUsersCreateNewRoute,
  appUsersCreateIndexRoute: appUsersCreateIndexRoute,
}

const appUsersCreateRouteRouteWithChildren =
  appUsersCreateRouteRoute._addFileChildren(appUsersCreateRouteRouteChildren)

interface appRouteRouteChildren {
  appAdminRouteRoute: typeof appAdminRouteRouteWithChildren
  appCoursesRouteRoute: typeof appCoursesRouteRouteWithChildren
  appHomeRouteRoute: typeof appHomeRouteRouteWithChildren
  appAboutRoute: typeof appAboutRoute
  appSimulatorsRoute: typeof appSimulatorsRoute
  appGroupsIdRouteRoute: typeof appGroupsIdRouteRouteWithChildren
  appUsersIdRouteRoute: typeof appUsersIdRouteRouteWithChildren
  appUsersCreateRouteRoute: typeof appUsersCreateRouteRouteWithChildren
  appGroupsCreateRoute: typeof appGroupsCreateRoute
  appSupportContactRoute: typeof appSupportContactRoute
  appSupportInstallationRoute: typeof appSupportInstallationRoute
  appGroupsIndexRoute: typeof appGroupsIndexRoute
  appSupportIndexRoute: typeof appSupportIndexRoute
  appUsersIndexRoute: typeof appUsersIndexRoute
  appCoursesTypePublicCourseUuidIndexRoute: typeof appCoursesTypePublicCourseUuidIndexRoute
  appCoursesTypePublicCourseUuidLessonsPublicLessonUuidRoute: typeof appCoursesTypePublicCourseUuidLessonsPublicLessonUuidRoute
}

const appRouteRouteChildren: appRouteRouteChildren = {
  appAdminRouteRoute: appAdminRouteRouteWithChildren,
  appCoursesRouteRoute: appCoursesRouteRouteWithChildren,
  appHomeRouteRoute: appHomeRouteRouteWithChildren,
  appAboutRoute: appAboutRoute,
  appSimulatorsRoute: appSimulatorsRoute,
  appGroupsIdRouteRoute: appGroupsIdRouteRouteWithChildren,
  appUsersIdRouteRoute: appUsersIdRouteRouteWithChildren,
  appUsersCreateRouteRoute: appUsersCreateRouteRouteWithChildren,
  appGroupsCreateRoute: appGroupsCreateRoute,
  appSupportContactRoute: appSupportContactRoute,
  appSupportInstallationRoute: appSupportInstallationRoute,
  appGroupsIndexRoute: appGroupsIndexRoute,
  appSupportIndexRoute: appSupportIndexRoute,
  appUsersIndexRoute: appUsersIndexRoute,
  appCoursesTypePublicCourseUuidIndexRoute:
    appCoursesTypePublicCourseUuidIndexRoute,
  appCoursesTypePublicCourseUuidLessonsPublicLessonUuidRoute:
    appCoursesTypePublicCourseUuidLessonsPublicLessonUuidRoute,
}

const appRouteRouteWithChildren = appRouteRoute._addFileChildren(
  appRouteRouteChildren,
)

interface IndexRouteChildren {
  appRouteRoute: typeof appRouteRouteWithChildren
}

const IndexRouteChildren: IndexRouteChildren = {
  appRouteRoute: appRouteRouteWithChildren,
}

const IndexRouteWithChildren = IndexRoute._addFileChildren(IndexRouteChildren)

interface fullscreenGroupsIdEditRouteRouteChildren {
  fullscreenGroupsIdEditCoursesRoute: typeof fullscreenGroupsIdEditCoursesRoute
  fullscreenGroupsIdEditSettingsRoute: typeof fullscreenGroupsIdEditSettingsRoute
  fullscreenGroupsIdEditUsersRoute: typeof fullscreenGroupsIdEditUsersRoute
}

const fullscreenGroupsIdEditRouteRouteChildren: fullscreenGroupsIdEditRouteRouteChildren =
  {
    fullscreenGroupsIdEditCoursesRoute: fullscreenGroupsIdEditCoursesRoute,
    fullscreenGroupsIdEditSettingsRoute: fullscreenGroupsIdEditSettingsRoute,
    fullscreenGroupsIdEditUsersRoute: fullscreenGroupsIdEditUsersRoute,
  }

const fullscreenGroupsIdEditRouteRouteWithChildren =
  fullscreenGroupsIdEditRouteRoute._addFileChildren(
    fullscreenGroupsIdEditRouteRouteChildren,
  )

interface fullscreenUsersIdEditRouteRouteChildren {
  fullscreenUsersIdEditAccountRoute: typeof fullscreenUsersIdEditAccountRoute
  fullscreenUsersIdEditCoursesRoute: typeof fullscreenUsersIdEditCoursesRoute
  fullscreenUsersIdEditPersonalInfoRoute: typeof fullscreenUsersIdEditPersonalInfoRoute
}

const fullscreenUsersIdEditRouteRouteChildren: fullscreenUsersIdEditRouteRouteChildren =
  {
    fullscreenUsersIdEditAccountRoute: fullscreenUsersIdEditAccountRoute,
    fullscreenUsersIdEditCoursesRoute: fullscreenUsersIdEditCoursesRoute,
    fullscreenUsersIdEditPersonalInfoRoute:
      fullscreenUsersIdEditPersonalInfoRoute,
  }

const fullscreenUsersIdEditRouteRouteWithChildren =
  fullscreenUsersIdEditRouteRoute._addFileChildren(
    fullscreenUsersIdEditRouteRouteChildren,
  )

export interface FileRoutesByFullPath {
  '/': typeof appRouteRouteWithChildren
  '/activate': typeof ActivateRoute
  '/login': typeof LoginRoute
  '/admin': typeof appAdminRouteRouteWithChildren
  '/courses': typeof appCoursesRouteRouteWithChildren
  '/home': typeof appHomeRouteRouteWithChildren
  '/about': typeof appAboutRoute
  '/simulators': typeof appSimulatorsRoute
  '/welcome': typeof fullscreenWelcomeRoute
  '/course-editor/$publicUuid': typeof CourseEditorPublicUuidRoute
  '/admin/academy': typeof appAdminAcademyRouteRouteWithChildren
  '/admin/tickets': typeof appAdminTicketsRouteRouteWithChildren
  '/groups/$id': typeof appGroupsIdRouteRouteWithChildren
  '/users/$id': typeof appUsersIdRouteRouteWithChildren
  '/users/create': typeof appUsersCreateRouteRouteWithChildren
  '/admin/billing': typeof appAdminBillingRoute
  '/admin/course-editor-stats': typeof appAdminCourseEditorStatsRoute
  '/admin/exercise-comments': typeof appAdminExerciseCommentsRoute
  '/admin/license-updates': typeof appAdminLicenseUpdatesRoute
  '/admin/map': typeof appAdminMapRoute
  '/admin/rundata': typeof appAdminRundataRoute
  '/admin/schools': typeof appAdminSchoolsRoute
  '/admin/simulators': typeof appAdminSimulatorsRoute
  '/admin/stats': typeof appAdminStatsRoute
  '/admin/toplist': typeof appAdminToplistRoute
  '/admin/users': typeof appAdminUsersRoute
  '/groups/create': typeof appGroupsCreateRoute
  '/home/news': typeof appHomeNewsRoute
  '/home/start': typeof appHomeStartRoute
  '/home/stats': typeof appHomeStatsRoute
  '/home/youtube': typeof appHomeYoutubeRoute
  '/support/contact': typeof appSupportContactRoute
  '/support/installation': typeof appSupportInstallationRoute
  '/oauth/fortnox/callback': typeof OauthFortnoxCallbackRoute
  '/oauth/github/callback': typeof OauthGithubCallbackRoute
  '/admin/': typeof appAdminIndexRoute
  '/courses/': typeof appCoursesIndexRoute
  '/groups': typeof appGroupsIndexRoute
  '/home/': typeof appHomeIndexRoute
  '/support': typeof appSupportIndexRoute
  '/users': typeof appUsersIndexRoute
  '/groups/$id/edit': typeof fullscreenGroupsIdEditRouteRouteWithChildren
  '/users/$id/edit': typeof fullscreenUsersIdEditRouteRouteWithChildren
  '/admin/academy/courses': typeof appAdminAcademyCoursesRoute
  '/admin/competitions/$id': typeof appAdminCompetitionsIdRoute
  '/admin/competitions/create': typeof appAdminCompetitionsCreateRoute
  '/admin/tickets/bugreport': typeof appAdminTicketsBugreportRoute
  '/admin/tickets/feedback': typeof appAdminTicketsFeedbackRoute
  '/groups/$id/courses': typeof appGroupsIdCoursesRoute
  '/groups/$id/driving-history': typeof appGroupsIdDrivingHistoryRoute
  '/groups/$id/tachograph': typeof appGroupsIdTachographRoute
  '/users/$id/courses': typeof appUsersIdCoursesRoute
  '/users/$id/driving-record': typeof appUsersIdDrivingRecordRoute
  '/users/$id/profile': typeof appUsersIdProfileRoute
  '/users/$id/tachograph': typeof appUsersIdTachographRoute
  '/users/create/import': typeof appUsersCreateImportRoute
  '/users/create/new': typeof appUsersCreateNewRoute
  '/admin/academy/': typeof appAdminAcademyIndexRoute
  '/admin/competitions': typeof appAdminCompetitionsIndexRoute
  '/admin/organizations': typeof appAdminOrganizationsIndexRoute
  '/admin/tickets/': typeof appAdminTicketsIndexRoute
  '/courses/$type': typeof appCoursesTypeIndexRoute
  '/groups/$id/': typeof appGroupsIdIndexRoute
  '/home/academy': typeof appHomeAcademyIndexRoute
  '/users/$id/': typeof appUsersIdIndexRoute
  '/users/create/': typeof appUsersCreateIndexRoute
  '/groups/$id/edit/courses': typeof fullscreenGroupsIdEditCoursesRoute
  '/groups/$id/edit/settings': typeof fullscreenGroupsIdEditSettingsRoute
  '/groups/$id/edit/users': typeof fullscreenGroupsIdEditUsersRoute
  '/home/academy/$lectureId/$segmentId': typeof fullscreenHomeAcademyLectureIdSegmentIdRoute
  '/users/$id/edit/account': typeof fullscreenUsersIdEditAccountRoute
  '/users/$id/edit/courses': typeof fullscreenUsersIdEditCoursesRoute
  '/users/$id/edit/personal-info': typeof fullscreenUsersIdEditPersonalInfoRoute
  '/admin/academy/stats': typeof appAdminAcademyStatsIndexRoute
  '/admin/organizations/$organizationId': typeof appAdminOrganizationsOrganizationIdIndexRoute
  '/admin/tickets/$id': typeof appAdminTicketsIdIndexRoute
  '/courses/$type/$publicCourseUuid': typeof appCoursesTypePublicCourseUuidIndexRoute
  '/admin/academy/stats/$lectureId/$schoolId': typeof appAdminAcademyStatsLectureIdSchoolIdRoute
  '/admin/organizations/$organizationId/schools/$schoolId': typeof appAdminOrganizationsOrganizationIdSchoolsSchoolIdRoute
  '/courses/$type/$publicCourseUuid/lessons/$publicLessonUuid': typeof appCoursesTypePublicCourseUuidLessonsPublicLessonUuidRoute
  '/admin/tickets/$id/screenshot/$bugId': typeof fullscreenAdminTicketsIdScreenshotBugIdRoute
  '/admin/academy/stats/$lectureId': typeof appAdminAcademyStatsLectureIdIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof appRouteRouteWithChildren
  '/activate': typeof ActivateRoute
  '/login': typeof LoginRoute
  '/about': typeof appAboutRoute
  '/simulators': typeof appSimulatorsRoute
  '/welcome': typeof fullscreenWelcomeRoute
  '/course-editor/$publicUuid': typeof CourseEditorPublicUuidRoute
  '/admin/billing': typeof appAdminBillingRoute
  '/admin/course-editor-stats': typeof appAdminCourseEditorStatsRoute
  '/admin/exercise-comments': typeof appAdminExerciseCommentsRoute
  '/admin/license-updates': typeof appAdminLicenseUpdatesRoute
  '/admin/map': typeof appAdminMapRoute
  '/admin/rundata': typeof appAdminRundataRoute
  '/admin/schools': typeof appAdminSchoolsRoute
  '/admin/simulators': typeof appAdminSimulatorsRoute
  '/admin/stats': typeof appAdminStatsRoute
  '/admin/toplist': typeof appAdminToplistRoute
  '/admin/users': typeof appAdminUsersRoute
  '/groups/create': typeof appGroupsCreateRoute
  '/home/news': typeof appHomeNewsRoute
  '/home/start': typeof appHomeStartRoute
  '/home/stats': typeof appHomeStatsRoute
  '/home/youtube': typeof appHomeYoutubeRoute
  '/support/contact': typeof appSupportContactRoute
  '/support/installation': typeof appSupportInstallationRoute
  '/oauth/fortnox/callback': typeof OauthFortnoxCallbackRoute
  '/oauth/github/callback': typeof OauthGithubCallbackRoute
  '/admin': typeof appAdminIndexRoute
  '/courses': typeof appCoursesIndexRoute
  '/groups': typeof appGroupsIndexRoute
  '/home': typeof appHomeIndexRoute
  '/support': typeof appSupportIndexRoute
  '/users': typeof appUsersIndexRoute
  '/groups/$id/edit': typeof fullscreenGroupsIdEditRouteRouteWithChildren
  '/users/$id/edit': typeof fullscreenUsersIdEditRouteRouteWithChildren
  '/admin/academy/courses': typeof appAdminAcademyCoursesRoute
  '/admin/competitions/$id': typeof appAdminCompetitionsIdRoute
  '/admin/competitions/create': typeof appAdminCompetitionsCreateRoute
  '/admin/tickets/bugreport': typeof appAdminTicketsBugreportRoute
  '/admin/tickets/feedback': typeof appAdminTicketsFeedbackRoute
  '/groups/$id/courses': typeof appGroupsIdCoursesRoute
  '/groups/$id/driving-history': typeof appGroupsIdDrivingHistoryRoute
  '/groups/$id/tachograph': typeof appGroupsIdTachographRoute
  '/users/$id/courses': typeof appUsersIdCoursesRoute
  '/users/$id/driving-record': typeof appUsersIdDrivingRecordRoute
  '/users/$id/profile': typeof appUsersIdProfileRoute
  '/users/$id/tachograph': typeof appUsersIdTachographRoute
  '/users/create/import': typeof appUsersCreateImportRoute
  '/users/create/new': typeof appUsersCreateNewRoute
  '/admin/academy': typeof appAdminAcademyIndexRoute
  '/admin/competitions': typeof appAdminCompetitionsIndexRoute
  '/admin/organizations': typeof appAdminOrganizationsIndexRoute
  '/admin/tickets': typeof appAdminTicketsIndexRoute
  '/courses/$type': typeof appCoursesTypeIndexRoute
  '/groups/$id': typeof appGroupsIdIndexRoute
  '/home/academy': typeof appHomeAcademyIndexRoute
  '/users/$id': typeof appUsersIdIndexRoute
  '/users/create': typeof appUsersCreateIndexRoute
  '/groups/$id/edit/courses': typeof fullscreenGroupsIdEditCoursesRoute
  '/groups/$id/edit/settings': typeof fullscreenGroupsIdEditSettingsRoute
  '/groups/$id/edit/users': typeof fullscreenGroupsIdEditUsersRoute
  '/home/academy/$lectureId/$segmentId': typeof fullscreenHomeAcademyLectureIdSegmentIdRoute
  '/users/$id/edit/account': typeof fullscreenUsersIdEditAccountRoute
  '/users/$id/edit/courses': typeof fullscreenUsersIdEditCoursesRoute
  '/users/$id/edit/personal-info': typeof fullscreenUsersIdEditPersonalInfoRoute
  '/admin/academy/stats': typeof appAdminAcademyStatsIndexRoute
  '/admin/organizations/$organizationId': typeof appAdminOrganizationsOrganizationIdIndexRoute
  '/admin/tickets/$id': typeof appAdminTicketsIdIndexRoute
  '/courses/$type/$publicCourseUuid': typeof appCoursesTypePublicCourseUuidIndexRoute
  '/admin/academy/stats/$lectureId/$schoolId': typeof appAdminAcademyStatsLectureIdSchoolIdRoute
  '/admin/organizations/$organizationId/schools/$schoolId': typeof appAdminOrganizationsOrganizationIdSchoolsSchoolIdRoute
  '/courses/$type/$publicCourseUuid/lessons/$publicLessonUuid': typeof appCoursesTypePublicCourseUuidLessonsPublicLessonUuidRoute
  '/admin/tickets/$id/screenshot/$bugId': typeof fullscreenAdminTicketsIdScreenshotBugIdRoute
  '/admin/academy/stats/$lectureId': typeof appAdminAcademyStatsLectureIdIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRouteWithChildren
  '/(app)': typeof appRouteRouteWithChildren
  '/activate': typeof ActivateRoute
  '/login': typeof LoginRoute
  '/(app)/admin': typeof appAdminRouteRouteWithChildren
  '/(app)/courses': typeof appCoursesRouteRouteWithChildren
  '/(app)/home': typeof appHomeRouteRouteWithChildren
  '/(app)/about': typeof appAboutRoute
  '/(app)/simulators': typeof appSimulatorsRoute
  '/(fullscreen)/welcome': typeof fullscreenWelcomeRoute
  '/course-editor/$publicUuid': typeof CourseEditorPublicUuidRoute
  '/(app)/admin/academy': typeof appAdminAcademyRouteRouteWithChildren
  '/(app)/admin/tickets': typeof appAdminTicketsRouteRouteWithChildren
  '/(app)/groups/$id': typeof appGroupsIdRouteRouteWithChildren
  '/(app)/users/$id': typeof appUsersIdRouteRouteWithChildren
  '/(app)/users/create': typeof appUsersCreateRouteRouteWithChildren
  '/(app)/admin/billing': typeof appAdminBillingRoute
  '/(app)/admin/course-editor-stats': typeof appAdminCourseEditorStatsRoute
  '/(app)/admin/exercise-comments': typeof appAdminExerciseCommentsRoute
  '/(app)/admin/license-updates': typeof appAdminLicenseUpdatesRoute
  '/(app)/admin/map': typeof appAdminMapRoute
  '/(app)/admin/rundata': typeof appAdminRundataRoute
  '/(app)/admin/schools': typeof appAdminSchoolsRoute
  '/(app)/admin/simulators': typeof appAdminSimulatorsRoute
  '/(app)/admin/stats': typeof appAdminStatsRoute
  '/(app)/admin/toplist': typeof appAdminToplistRoute
  '/(app)/admin/users': typeof appAdminUsersRoute
  '/(app)/groups/create': typeof appGroupsCreateRoute
  '/(app)/home/news': typeof appHomeNewsRoute
  '/(app)/home/start': typeof appHomeStartRoute
  '/(app)/home/stats': typeof appHomeStatsRoute
  '/(app)/home/youtube': typeof appHomeYoutubeRoute
  '/(app)/support/contact': typeof appSupportContactRoute
  '/(app)/support/installation': typeof appSupportInstallationRoute
  '/oauth/fortnox/callback': typeof OauthFortnoxCallbackRoute
  '/oauth/github/callback': typeof OauthGithubCallbackRoute
  '/(app)/admin/': typeof appAdminIndexRoute
  '/(app)/courses/': typeof appCoursesIndexRoute
  '/(app)/groups/': typeof appGroupsIndexRoute
  '/(app)/home/': typeof appHomeIndexRoute
  '/(app)/support/': typeof appSupportIndexRoute
  '/(app)/users/': typeof appUsersIndexRoute
  '/(fullscreen)/groups/$id/edit': typeof fullscreenGroupsIdEditRouteRouteWithChildren
  '/(fullscreen)/users/$id/edit': typeof fullscreenUsersIdEditRouteRouteWithChildren
  '/(app)/admin/academy/courses': typeof appAdminAcademyCoursesRoute
  '/(app)/admin/competitions/$id': typeof appAdminCompetitionsIdRoute
  '/(app)/admin/competitions/create': typeof appAdminCompetitionsCreateRoute
  '/(app)/admin/tickets/bugreport': typeof appAdminTicketsBugreportRoute
  '/(app)/admin/tickets/feedback': typeof appAdminTicketsFeedbackRoute
  '/(app)/groups/$id/courses': typeof appGroupsIdCoursesRoute
  '/(app)/groups/$id/driving-history': typeof appGroupsIdDrivingHistoryRoute
  '/(app)/groups/$id/tachograph': typeof appGroupsIdTachographRoute
  '/(app)/users/$id/courses': typeof appUsersIdCoursesRoute
  '/(app)/users/$id/driving-record': typeof appUsersIdDrivingRecordRoute
  '/(app)/users/$id/profile': typeof appUsersIdProfileRoute
  '/(app)/users/$id/tachograph': typeof appUsersIdTachographRoute
  '/(app)/users/create/import': typeof appUsersCreateImportRoute
  '/(app)/users/create/new': typeof appUsersCreateNewRoute
  '/(app)/admin/academy/': typeof appAdminAcademyIndexRoute
  '/(app)/admin/competitions/': typeof appAdminCompetitionsIndexRoute
  '/(app)/admin/organizations/': typeof appAdminOrganizationsIndexRoute
  '/(app)/admin/tickets/': typeof appAdminTicketsIndexRoute
  '/(app)/courses/$type/': typeof appCoursesTypeIndexRoute
  '/(app)/groups/$id/': typeof appGroupsIdIndexRoute
  '/(app)/home/academy/': typeof appHomeAcademyIndexRoute
  '/(app)/users/$id/': typeof appUsersIdIndexRoute
  '/(app)/users/create/': typeof appUsersCreateIndexRoute
  '/(fullscreen)/groups/$id/edit/courses': typeof fullscreenGroupsIdEditCoursesRoute
  '/(fullscreen)/groups/$id/edit/settings': typeof fullscreenGroupsIdEditSettingsRoute
  '/(fullscreen)/groups/$id/edit/users': typeof fullscreenGroupsIdEditUsersRoute
  '/(fullscreen)/home/academy/$lectureId/$segmentId': typeof fullscreenHomeAcademyLectureIdSegmentIdRoute
  '/(fullscreen)/users/$id/edit/account': typeof fullscreenUsersIdEditAccountRoute
  '/(fullscreen)/users/$id/edit/courses': typeof fullscreenUsersIdEditCoursesRoute
  '/(fullscreen)/users/$id/edit/personal-info': typeof fullscreenUsersIdEditPersonalInfoRoute
  '/(app)/admin/academy/stats/': typeof appAdminAcademyStatsIndexRoute
  '/(app)/admin/organizations/$organizationId/': typeof appAdminOrganizationsOrganizationIdIndexRoute
  '/(app)/admin/tickets/$id/': typeof appAdminTicketsIdIndexRoute
  '/(app)/courses_/$type/$publicCourseUuid/': typeof appCoursesTypePublicCourseUuidIndexRoute
  '/(app)/admin/academy/stats/$lectureId/$schoolId': typeof appAdminAcademyStatsLectureIdSchoolIdRoute
  '/(app)/admin/organizations/$organizationId/schools/$schoolId': typeof appAdminOrganizationsOrganizationIdSchoolsSchoolIdRoute
  '/(app)/courses_/$type/$publicCourseUuid/lessons/$publicLessonUuid': typeof appCoursesTypePublicCourseUuidLessonsPublicLessonUuidRoute
  '/(fullscreen)/admin/tickets/$id/screenshot/$bugId': typeof fullscreenAdminTicketsIdScreenshotBugIdRoute
  '/(app)/admin/academy/stats/$lectureId/': typeof appAdminAcademyStatsLectureIdIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/activate'
    | '/login'
    | '/admin'
    | '/courses'
    | '/home'
    | '/about'
    | '/simulators'
    | '/welcome'
    | '/course-editor/$publicUuid'
    | '/admin/academy'
    | '/admin/tickets'
    | '/groups/$id'
    | '/users/$id'
    | '/users/create'
    | '/admin/billing'
    | '/admin/course-editor-stats'
    | '/admin/exercise-comments'
    | '/admin/license-updates'
    | '/admin/map'
    | '/admin/rundata'
    | '/admin/schools'
    | '/admin/simulators'
    | '/admin/stats'
    | '/admin/toplist'
    | '/admin/users'
    | '/groups/create'
    | '/home/news'
    | '/home/start'
    | '/home/stats'
    | '/home/youtube'
    | '/support/contact'
    | '/support/installation'
    | '/oauth/fortnox/callback'
    | '/oauth/github/callback'
    | '/admin/'
    | '/courses/'
    | '/groups'
    | '/home/'
    | '/support'
    | '/users'
    | '/groups/$id/edit'
    | '/users/$id/edit'
    | '/admin/academy/courses'
    | '/admin/competitions/$id'
    | '/admin/competitions/create'
    | '/admin/tickets/bugreport'
    | '/admin/tickets/feedback'
    | '/groups/$id/courses'
    | '/groups/$id/driving-history'
    | '/groups/$id/tachograph'
    | '/users/$id/courses'
    | '/users/$id/driving-record'
    | '/users/$id/profile'
    | '/users/$id/tachograph'
    | '/users/create/import'
    | '/users/create/new'
    | '/admin/academy/'
    | '/admin/competitions'
    | '/admin/organizations'
    | '/admin/tickets/'
    | '/courses/$type'
    | '/groups/$id/'
    | '/home/academy'
    | '/users/$id/'
    | '/users/create/'
    | '/groups/$id/edit/courses'
    | '/groups/$id/edit/settings'
    | '/groups/$id/edit/users'
    | '/home/academy/$lectureId/$segmentId'
    | '/users/$id/edit/account'
    | '/users/$id/edit/courses'
    | '/users/$id/edit/personal-info'
    | '/admin/academy/stats'
    | '/admin/organizations/$organizationId'
    | '/admin/tickets/$id'
    | '/courses/$type/$publicCourseUuid'
    | '/admin/academy/stats/$lectureId/$schoolId'
    | '/admin/organizations/$organizationId/schools/$schoolId'
    | '/courses/$type/$publicCourseUuid/lessons/$publicLessonUuid'
    | '/admin/tickets/$id/screenshot/$bugId'
    | '/admin/academy/stats/$lectureId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/activate'
    | '/login'
    | '/about'
    | '/simulators'
    | '/welcome'
    | '/course-editor/$publicUuid'
    | '/admin/billing'
    | '/admin/course-editor-stats'
    | '/admin/exercise-comments'
    | '/admin/license-updates'
    | '/admin/map'
    | '/admin/rundata'
    | '/admin/schools'
    | '/admin/simulators'
    | '/admin/stats'
    | '/admin/toplist'
    | '/admin/users'
    | '/groups/create'
    | '/home/news'
    | '/home/start'
    | '/home/stats'
    | '/home/youtube'
    | '/support/contact'
    | '/support/installation'
    | '/oauth/fortnox/callback'
    | '/oauth/github/callback'
    | '/admin'
    | '/courses'
    | '/groups'
    | '/home'
    | '/support'
    | '/users'
    | '/groups/$id/edit'
    | '/users/$id/edit'
    | '/admin/academy/courses'
    | '/admin/competitions/$id'
    | '/admin/competitions/create'
    | '/admin/tickets/bugreport'
    | '/admin/tickets/feedback'
    | '/groups/$id/courses'
    | '/groups/$id/driving-history'
    | '/groups/$id/tachograph'
    | '/users/$id/courses'
    | '/users/$id/driving-record'
    | '/users/$id/profile'
    | '/users/$id/tachograph'
    | '/users/create/import'
    | '/users/create/new'
    | '/admin/academy'
    | '/admin/competitions'
    | '/admin/organizations'
    | '/admin/tickets'
    | '/courses/$type'
    | '/groups/$id'
    | '/home/academy'
    | '/users/$id'
    | '/users/create'
    | '/groups/$id/edit/courses'
    | '/groups/$id/edit/settings'
    | '/groups/$id/edit/users'
    | '/home/academy/$lectureId/$segmentId'
    | '/users/$id/edit/account'
    | '/users/$id/edit/courses'
    | '/users/$id/edit/personal-info'
    | '/admin/academy/stats'
    | '/admin/organizations/$organizationId'
    | '/admin/tickets/$id'
    | '/courses/$type/$publicCourseUuid'
    | '/admin/academy/stats/$lectureId/$schoolId'
    | '/admin/organizations/$organizationId/schools/$schoolId'
    | '/courses/$type/$publicCourseUuid/lessons/$publicLessonUuid'
    | '/admin/tickets/$id/screenshot/$bugId'
    | '/admin/academy/stats/$lectureId'
  id:
    | '__root__'
    | '/'
    | '/(app)'
    | '/activate'
    | '/login'
    | '/(app)/admin'
    | '/(app)/courses'
    | '/(app)/home'
    | '/(app)/about'
    | '/(app)/simulators'
    | '/(fullscreen)/welcome'
    | '/course-editor/$publicUuid'
    | '/(app)/admin/academy'
    | '/(app)/admin/tickets'
    | '/(app)/groups/$id'
    | '/(app)/users/$id'
    | '/(app)/users/create'
    | '/(app)/admin/billing'
    | '/(app)/admin/course-editor-stats'
    | '/(app)/admin/exercise-comments'
    | '/(app)/admin/license-updates'
    | '/(app)/admin/map'
    | '/(app)/admin/rundata'
    | '/(app)/admin/schools'
    | '/(app)/admin/simulators'
    | '/(app)/admin/stats'
    | '/(app)/admin/toplist'
    | '/(app)/admin/users'
    | '/(app)/groups/create'
    | '/(app)/home/news'
    | '/(app)/home/start'
    | '/(app)/home/stats'
    | '/(app)/home/youtube'
    | '/(app)/support/contact'
    | '/(app)/support/installation'
    | '/oauth/fortnox/callback'
    | '/oauth/github/callback'
    | '/(app)/admin/'
    | '/(app)/courses/'
    | '/(app)/groups/'
    | '/(app)/home/'
    | '/(app)/support/'
    | '/(app)/users/'
    | '/(fullscreen)/groups/$id/edit'
    | '/(fullscreen)/users/$id/edit'
    | '/(app)/admin/academy/courses'
    | '/(app)/admin/competitions/$id'
    | '/(app)/admin/competitions/create'
    | '/(app)/admin/tickets/bugreport'
    | '/(app)/admin/tickets/feedback'
    | '/(app)/groups/$id/courses'
    | '/(app)/groups/$id/driving-history'
    | '/(app)/groups/$id/tachograph'
    | '/(app)/users/$id/courses'
    | '/(app)/users/$id/driving-record'
    | '/(app)/users/$id/profile'
    | '/(app)/users/$id/tachograph'
    | '/(app)/users/create/import'
    | '/(app)/users/create/new'
    | '/(app)/admin/academy/'
    | '/(app)/admin/competitions/'
    | '/(app)/admin/organizations/'
    | '/(app)/admin/tickets/'
    | '/(app)/courses/$type/'
    | '/(app)/groups/$id/'
    | '/(app)/home/academy/'
    | '/(app)/users/$id/'
    | '/(app)/users/create/'
    | '/(fullscreen)/groups/$id/edit/courses'
    | '/(fullscreen)/groups/$id/edit/settings'
    | '/(fullscreen)/groups/$id/edit/users'
    | '/(fullscreen)/home/academy/$lectureId/$segmentId'
    | '/(fullscreen)/users/$id/edit/account'
    | '/(fullscreen)/users/$id/edit/courses'
    | '/(fullscreen)/users/$id/edit/personal-info'
    | '/(app)/admin/academy/stats/'
    | '/(app)/admin/organizations/$organizationId/'
    | '/(app)/admin/tickets/$id/'
    | '/(app)/courses_/$type/$publicCourseUuid/'
    | '/(app)/admin/academy/stats/$lectureId/$schoolId'
    | '/(app)/admin/organizations/$organizationId/schools/$schoolId'
    | '/(app)/courses_/$type/$publicCourseUuid/lessons/$publicLessonUuid'
    | '/(fullscreen)/admin/tickets/$id/screenshot/$bugId'
    | '/(app)/admin/academy/stats/$lectureId/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRouteWithChildren
  ActivateRoute: typeof ActivateRoute
  LoginRoute: typeof LoginRoute
  fullscreenWelcomeRoute: typeof fullscreenWelcomeRoute
  CourseEditorPublicUuidRoute: typeof CourseEditorPublicUuidRoute
  OauthFortnoxCallbackRoute: typeof OauthFortnoxCallbackRoute
  OauthGithubCallbackRoute: typeof OauthGithubCallbackRoute
  fullscreenGroupsIdEditRouteRoute: typeof fullscreenGroupsIdEditRouteRouteWithChildren
  fullscreenUsersIdEditRouteRoute: typeof fullscreenUsersIdEditRouteRouteWithChildren
  fullscreenHomeAcademyLectureIdSegmentIdRoute: typeof fullscreenHomeAcademyLectureIdSegmentIdRoute
  fullscreenAdminTicketsIdScreenshotBugIdRoute: typeof fullscreenAdminTicketsIdScreenshotBugIdRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRouteWithChildren,
  ActivateRoute: ActivateRoute,
  LoginRoute: LoginRoute,
  fullscreenWelcomeRoute: fullscreenWelcomeRoute,
  CourseEditorPublicUuidRoute: CourseEditorPublicUuidRoute,
  OauthFortnoxCallbackRoute: OauthFortnoxCallbackRoute,
  OauthGithubCallbackRoute: OauthGithubCallbackRoute,
  fullscreenGroupsIdEditRouteRoute:
    fullscreenGroupsIdEditRouteRouteWithChildren,
  fullscreenUsersIdEditRouteRoute: fullscreenUsersIdEditRouteRouteWithChildren,
  fullscreenHomeAcademyLectureIdSegmentIdRoute:
    fullscreenHomeAcademyLectureIdSegmentIdRoute,
  fullscreenAdminTicketsIdScreenshotBugIdRoute:
    fullscreenAdminTicketsIdScreenshotBugIdRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/activate",
        "/login",
        "/(fullscreen)/welcome",
        "/course-editor/$publicUuid",
        "/oauth/fortnox/callback",
        "/oauth/github/callback",
        "/(fullscreen)/groups/$id/edit",
        "/(fullscreen)/users/$id/edit",
        "/(fullscreen)/home/academy/$lectureId/$segmentId",
        "/(fullscreen)/admin/tickets/$id/screenshot/$bugId"
      ]
    },
    "/": {
      "filePath": "index.tsx",
      "children": [
        "/(app)"
      ]
    },
    "/(app)": {
      "filePath": "(app)/route.tsx",
      "parent": "/",
      "children": [
        "/(app)/admin",
        "/(app)/courses",
        "/(app)/home",
        "/(app)/about",
        "/(app)/simulators",
        "/(app)/groups/$id",
        "/(app)/users/$id",
        "/(app)/users/create",
        "/(app)/groups/create",
        "/(app)/support/contact",
        "/(app)/support/installation",
        "/(app)/groups/",
        "/(app)/support/",
        "/(app)/users/",
        "/(app)/courses_/$type/$publicCourseUuid/",
        "/(app)/courses_/$type/$publicCourseUuid/lessons/$publicLessonUuid"
      ]
    },
    "/activate": {
      "filePath": "activate.tsx"
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/(app)/admin": {
      "filePath": "(app)/admin/route.tsx",
      "parent": "/(app)",
      "children": [
        "/(app)/admin/academy",
        "/(app)/admin/tickets",
        "/(app)/admin/billing",
        "/(app)/admin/course-editor-stats",
        "/(app)/admin/exercise-comments",
        "/(app)/admin/license-updates",
        "/(app)/admin/map",
        "/(app)/admin/rundata",
        "/(app)/admin/schools",
        "/(app)/admin/simulators",
        "/(app)/admin/stats",
        "/(app)/admin/toplist",
        "/(app)/admin/users",
        "/(app)/admin/",
        "/(app)/admin/competitions/$id",
        "/(app)/admin/competitions/create",
        "/(app)/admin/competitions/",
        "/(app)/admin/organizations/",
        "/(app)/admin/organizations/$organizationId/",
        "/(app)/admin/organizations/$organizationId/schools/$schoolId"
      ]
    },
    "/(app)/courses": {
      "filePath": "(app)/courses/route.tsx",
      "parent": "/(app)",
      "children": [
        "/(app)/courses/",
        "/(app)/courses/$type/"
      ]
    },
    "/(app)/home": {
      "filePath": "(app)/home/route.tsx",
      "parent": "/(app)",
      "children": [
        "/(app)/home/news",
        "/(app)/home/start",
        "/(app)/home/stats",
        "/(app)/home/youtube",
        "/(app)/home/",
        "/(app)/home/academy/"
      ]
    },
    "/(app)/about": {
      "filePath": "(app)/about.tsx",
      "parent": "/(app)"
    },
    "/(app)/simulators": {
      "filePath": "(app)/simulators.tsx",
      "parent": "/(app)"
    },
    "/(fullscreen)/welcome": {
      "filePath": "(fullscreen)/welcome.tsx"
    },
    "/course-editor/$publicUuid": {
      "filePath": "course-editor/$publicUuid.tsx"
    },
    "/(app)/admin/academy": {
      "filePath": "(app)/admin/academy/route.tsx",
      "parent": "/(app)/admin",
      "children": [
        "/(app)/admin/academy/courses",
        "/(app)/admin/academy/",
        "/(app)/admin/academy/stats/",
        "/(app)/admin/academy/stats/$lectureId/$schoolId",
        "/(app)/admin/academy/stats/$lectureId/"
      ]
    },
    "/(app)/admin/tickets": {
      "filePath": "(app)/admin/tickets/route.tsx",
      "parent": "/(app)/admin",
      "children": [
        "/(app)/admin/tickets/bugreport",
        "/(app)/admin/tickets/feedback",
        "/(app)/admin/tickets/",
        "/(app)/admin/tickets/$id/"
      ]
    },
    "/(app)/groups/$id": {
      "filePath": "(app)/groups/$id/route.tsx",
      "parent": "/(app)",
      "children": [
        "/(app)/groups/$id/courses",
        "/(app)/groups/$id/driving-history",
        "/(app)/groups/$id/tachograph",
        "/(app)/groups/$id/"
      ]
    },
    "/(app)/users/$id": {
      "filePath": "(app)/users/$id/route.tsx",
      "parent": "/(app)",
      "children": [
        "/(app)/users/$id/courses",
        "/(app)/users/$id/driving-record",
        "/(app)/users/$id/profile",
        "/(app)/users/$id/tachograph",
        "/(app)/users/$id/"
      ]
    },
    "/(app)/users/create": {
      "filePath": "(app)/users/create/route.tsx",
      "parent": "/(app)",
      "children": [
        "/(app)/users/create/import",
        "/(app)/users/create/new",
        "/(app)/users/create/"
      ]
    },
    "/(app)/admin/billing": {
      "filePath": "(app)/admin/billing.tsx",
      "parent": "/(app)/admin"
    },
    "/(app)/admin/course-editor-stats": {
      "filePath": "(app)/admin/course-editor-stats.tsx",
      "parent": "/(app)/admin"
    },
    "/(app)/admin/exercise-comments": {
      "filePath": "(app)/admin/exercise-comments.tsx",
      "parent": "/(app)/admin"
    },
    "/(app)/admin/license-updates": {
      "filePath": "(app)/admin/license-updates.tsx",
      "parent": "/(app)/admin"
    },
    "/(app)/admin/map": {
      "filePath": "(app)/admin/map.tsx",
      "parent": "/(app)/admin"
    },
    "/(app)/admin/rundata": {
      "filePath": "(app)/admin/rundata.tsx",
      "parent": "/(app)/admin"
    },
    "/(app)/admin/schools": {
      "filePath": "(app)/admin/schools.tsx",
      "parent": "/(app)/admin"
    },
    "/(app)/admin/simulators": {
      "filePath": "(app)/admin/simulators.tsx",
      "parent": "/(app)/admin"
    },
    "/(app)/admin/stats": {
      "filePath": "(app)/admin/stats.tsx",
      "parent": "/(app)/admin"
    },
    "/(app)/admin/toplist": {
      "filePath": "(app)/admin/toplist.tsx",
      "parent": "/(app)/admin"
    },
    "/(app)/admin/users": {
      "filePath": "(app)/admin/users.tsx",
      "parent": "/(app)/admin"
    },
    "/(app)/groups/create": {
      "filePath": "(app)/groups/create.tsx",
      "parent": "/(app)"
    },
    "/(app)/home/news": {
      "filePath": "(app)/home/news.tsx",
      "parent": "/(app)/home"
    },
    "/(app)/home/start": {
      "filePath": "(app)/home/start.tsx",
      "parent": "/(app)/home"
    },
    "/(app)/home/stats": {
      "filePath": "(app)/home/stats.tsx",
      "parent": "/(app)/home"
    },
    "/(app)/home/youtube": {
      "filePath": "(app)/home/youtube.tsx",
      "parent": "/(app)/home"
    },
    "/(app)/support/contact": {
      "filePath": "(app)/support/contact.tsx",
      "parent": "/(app)"
    },
    "/(app)/support/installation": {
      "filePath": "(app)/support/installation.tsx",
      "parent": "/(app)"
    },
    "/oauth/fortnox/callback": {
      "filePath": "oauth/fortnox.callback.tsx"
    },
    "/oauth/github/callback": {
      "filePath": "oauth/github.callback.tsx"
    },
    "/(app)/admin/": {
      "filePath": "(app)/admin/index.tsx",
      "parent": "/(app)/admin"
    },
    "/(app)/courses/": {
      "filePath": "(app)/courses/index.tsx",
      "parent": "/(app)/courses"
    },
    "/(app)/groups/": {
      "filePath": "(app)/groups/index.tsx",
      "parent": "/(app)"
    },
    "/(app)/home/": {
      "filePath": "(app)/home/index.tsx",
      "parent": "/(app)/home"
    },
    "/(app)/support/": {
      "filePath": "(app)/support/index.tsx",
      "parent": "/(app)"
    },
    "/(app)/users/": {
      "filePath": "(app)/users/index.tsx",
      "parent": "/(app)"
    },
    "/(fullscreen)/groups/$id/edit": {
      "filePath": "(fullscreen)/groups/$id/edit/route.tsx",
      "children": [
        "/(fullscreen)/groups/$id/edit/courses",
        "/(fullscreen)/groups/$id/edit/settings",
        "/(fullscreen)/groups/$id/edit/users"
      ]
    },
    "/(fullscreen)/users/$id/edit": {
      "filePath": "(fullscreen)/users/$id/edit/route.tsx",
      "children": [
        "/(fullscreen)/users/$id/edit/account",
        "/(fullscreen)/users/$id/edit/courses",
        "/(fullscreen)/users/$id/edit/personal-info"
      ]
    },
    "/(app)/admin/academy/courses": {
      "filePath": "(app)/admin/academy/courses.tsx",
      "parent": "/(app)/admin/academy"
    },
    "/(app)/admin/competitions/$id": {
      "filePath": "(app)/admin/competitions/$id.tsx",
      "parent": "/(app)/admin"
    },
    "/(app)/admin/competitions/create": {
      "filePath": "(app)/admin/competitions/create.tsx",
      "parent": "/(app)/admin"
    },
    "/(app)/admin/tickets/bugreport": {
      "filePath": "(app)/admin/tickets/bugreport.tsx",
      "parent": "/(app)/admin/tickets"
    },
    "/(app)/admin/tickets/feedback": {
      "filePath": "(app)/admin/tickets/feedback.tsx",
      "parent": "/(app)/admin/tickets"
    },
    "/(app)/groups/$id/courses": {
      "filePath": "(app)/groups/$id/courses.tsx",
      "parent": "/(app)/groups/$id"
    },
    "/(app)/groups/$id/driving-history": {
      "filePath": "(app)/groups/$id/driving-history.tsx",
      "parent": "/(app)/groups/$id"
    },
    "/(app)/groups/$id/tachograph": {
      "filePath": "(app)/groups/$id/tachograph.tsx",
      "parent": "/(app)/groups/$id"
    },
    "/(app)/users/$id/courses": {
      "filePath": "(app)/users/$id/courses.tsx",
      "parent": "/(app)/users/$id"
    },
    "/(app)/users/$id/driving-record": {
      "filePath": "(app)/users/$id/driving-record.tsx",
      "parent": "/(app)/users/$id"
    },
    "/(app)/users/$id/profile": {
      "filePath": "(app)/users/$id/profile.tsx",
      "parent": "/(app)/users/$id"
    },
    "/(app)/users/$id/tachograph": {
      "filePath": "(app)/users/$id/tachograph.tsx",
      "parent": "/(app)/users/$id"
    },
    "/(app)/users/create/import": {
      "filePath": "(app)/users/create/import.tsx",
      "parent": "/(app)/users/create"
    },
    "/(app)/users/create/new": {
      "filePath": "(app)/users/create/new.tsx",
      "parent": "/(app)/users/create"
    },
    "/(app)/admin/academy/": {
      "filePath": "(app)/admin/academy/index.tsx",
      "parent": "/(app)/admin/academy"
    },
    "/(app)/admin/competitions/": {
      "filePath": "(app)/admin/competitions/index.tsx",
      "parent": "/(app)/admin"
    },
    "/(app)/admin/organizations/": {
      "filePath": "(app)/admin/organizations/index.tsx",
      "parent": "/(app)/admin"
    },
    "/(app)/admin/tickets/": {
      "filePath": "(app)/admin/tickets/index.tsx",
      "parent": "/(app)/admin/tickets"
    },
    "/(app)/courses/$type/": {
      "filePath": "(app)/courses/$type/index.tsx",
      "parent": "/(app)/courses"
    },
    "/(app)/groups/$id/": {
      "filePath": "(app)/groups/$id/index.tsx",
      "parent": "/(app)/groups/$id"
    },
    "/(app)/home/academy/": {
      "filePath": "(app)/home/academy/index.tsx",
      "parent": "/(app)/home"
    },
    "/(app)/users/$id/": {
      "filePath": "(app)/users/$id/index.tsx",
      "parent": "/(app)/users/$id"
    },
    "/(app)/users/create/": {
      "filePath": "(app)/users/create/index.tsx",
      "parent": "/(app)/users/create"
    },
    "/(fullscreen)/groups/$id/edit/courses": {
      "filePath": "(fullscreen)/groups/$id/edit/courses.tsx",
      "parent": "/(fullscreen)/groups/$id/edit"
    },
    "/(fullscreen)/groups/$id/edit/settings": {
      "filePath": "(fullscreen)/groups/$id/edit/settings.tsx",
      "parent": "/(fullscreen)/groups/$id/edit"
    },
    "/(fullscreen)/groups/$id/edit/users": {
      "filePath": "(fullscreen)/groups/$id/edit/users.tsx",
      "parent": "/(fullscreen)/groups/$id/edit"
    },
    "/(fullscreen)/home/academy/$lectureId/$segmentId": {
      "filePath": "(fullscreen)/home/academy/$lectureId/$segmentId.tsx"
    },
    "/(fullscreen)/users/$id/edit/account": {
      "filePath": "(fullscreen)/users/$id/edit/account.tsx",
      "parent": "/(fullscreen)/users/$id/edit"
    },
    "/(fullscreen)/users/$id/edit/courses": {
      "filePath": "(fullscreen)/users/$id/edit/courses.tsx",
      "parent": "/(fullscreen)/users/$id/edit"
    },
    "/(fullscreen)/users/$id/edit/personal-info": {
      "filePath": "(fullscreen)/users/$id/edit/personal-info.tsx",
      "parent": "/(fullscreen)/users/$id/edit"
    },
    "/(app)/admin/academy/stats/": {
      "filePath": "(app)/admin/academy/stats/index.tsx",
      "parent": "/(app)/admin/academy"
    },
    "/(app)/admin/organizations/$organizationId/": {
      "filePath": "(app)/admin/organizations/$organizationId/index.tsx",
      "parent": "/(app)/admin"
    },
    "/(app)/admin/tickets/$id/": {
      "filePath": "(app)/admin/tickets/$id/index.tsx",
      "parent": "/(app)/admin/tickets"
    },
    "/(app)/courses_/$type/$publicCourseUuid/": {
      "filePath": "(app)/courses_.$type.$publicCourseUuid/index.tsx",
      "parent": "/(app)"
    },
    "/(app)/admin/academy/stats/$lectureId/$schoolId": {
      "filePath": "(app)/admin/academy/stats/$lectureId/$schoolId.tsx",
      "parent": "/(app)/admin/academy"
    },
    "/(app)/admin/organizations/$organizationId/schools/$schoolId": {
      "filePath": "(app)/admin/organizations/$organizationId/schools/$schoolId.tsx",
      "parent": "/(app)/admin"
    },
    "/(app)/courses_/$type/$publicCourseUuid/lessons/$publicLessonUuid": {
      "filePath": "(app)/courses_.$type.$publicCourseUuid/lessons.$publicLessonUuid.tsx",
      "parent": "/(app)"
    },
    "/(fullscreen)/admin/tickets/$id/screenshot/$bugId": {
      "filePath": "(fullscreen)/admin.tickets.$id.screenshot.$bugId.tsx"
    },
    "/(app)/admin/academy/stats/$lectureId/": {
      "filePath": "(app)/admin/academy/stats/$lectureId/index.tsx",
      "parent": "/(app)/admin/academy"
    }
  }
}
ROUTE_MANIFEST_END */
