import { createFileRoute } from "@tanstack/react-router";
import AdminAcademyPage from "../../../../pages/Admin/AdminAcademyPage/AdminAcademyPage";

export const Route = createFileRoute("/(app)/admin/academy")({
  component: RouteComponent,
});

function RouteComponent() {
  return <AdminAcademyPage />;
}
