import { createFileRoute } from "@tanstack/react-router";
import CompetitionPage from "../../../../pages/Admin/Competitions/CompetitionPage";

export const Route = createFileRoute("/(app)/admin/competitions/$id")({
  component: RouteComponent,
});

function RouteComponent() {
  const { id } = Route.useParams();
  return <CompetitionPage id={Number(id)} />;
}
