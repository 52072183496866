import { createFileRoute } from "@tanstack/react-router";
import CreateUser from "../../../../features/Users/CreateUser/CreateUser";

export const Route = createFileRoute("/(app)/users/create/new")({
  component: RouteComponent,
});

function RouteComponent() {
  return <CreateUser />;
}
