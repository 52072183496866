function FeedbackIcon({ hidePlus }: { hidePlus?: boolean }) {
  return (
    <>
      <svg
        width="16"
        height="15"
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 2C0 0.895431 0.895431 0 2 0H14C15.1046 0 16 0.895431 16 2V9C16 10.1046 15.1046 11 14 11H11L6 15L6.5 11H2C0.895431 11 0 10.1046 0 9V2ZM3 3.5C3 3.22386 3.22386 3 3.5 3H12.5C12.7761 3 13 3.22386 13 3.5C13 3.77614 12.7761 4 12.5 4H3.5C3.22386 4 3 3.77614 3 3.5ZM3 5.5C3 5.22386 3.22386 5 3.5 5H12.5C12.7761 5 13 5.22386 13 5.5C13 5.77614 12.7761 6 12.5 6H3.5C3.22386 6 3 5.77614 3 5.5ZM8 7.5C8 7.22386 7.77614 7 7.5 7H3.5C3.22386 7 3 7.22386 3 7.5C3 7.77614 3.22386 8 3.5 8H7.5C7.77614 8 8 7.77614 8 7.5Z"
          fill="#DAE1EC"
        />
      </svg>

      {!hidePlus && (
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ position: "absolute", top: -3, right: -3 }}
        >
          <mask
            id="path-1-outside-1"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="14"
            height="14"
            fill="black"
          >
            <rect fill="white" width="14" height="14" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7 2C6.44772 2 6 2.44772 6 3V6L3 6C2.44772 6 2 6.44771 2 7C2 7.55228 2.44772 8 3 8H6V11C6 11.5523 6.44772 12 7 12C7.55228 12 8 11.5523 8 11V8H11C11.5523 8 12 7.55228 12 7C12 6.44772 11.5523 6 11 6L8 6V3C8 2.44772 7.55228 2 7 2Z"
            />
          </mask>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 2C6.44772 2 6 2.44772 6 3V6L3 6C2.44772 6 2 6.44771 2 7C2 7.55228 2.44772 8 3 8H6V11C6 11.5523 6.44772 12 7 12C7.55228 12 8 11.5523 8 11V8H11C11.5523 8 12 7.55228 12 7C12 6.44772 11.5523 6 11 6L8 6V3C8 2.44772 7.55228 2 7 2Z"
            fill="#DAE1EC"
          />
          <path
            d="M6 6L6 8L8 8V6H6ZM3 6L3 4H3V6ZM6 8H8V6H6V8ZM8 8V6H6V8H8ZM11 6V8V6ZM8 6H6V8H8V6ZM8 3C8 3.55228 7.55229 4 7 4V0C5.34315 0 4 1.34315 4 3H8ZM8 6V3H4V6H8ZM3 8H6L6 4L3 4L3 8ZM4 7C4 7.55228 3.55228 8 3 8V4C1.34315 4 0 5.34315 0 7H4ZM3 6C3.55228 6 4 6.44771 4 7H0C0 8.65685 1.34315 10 3 10V6ZM6 6H3V10H6V6ZM8 11V8H4V11H8ZM7 10C7.55228 10 8 10.4477 8 11H4C4 12.6569 5.34315 14 7 14V10ZM6 11C6 10.4477 6.44771 10 7 10V14C8.65685 14 10 12.6569 10 11H6ZM6 8V11H10V8H6ZM11 6H8V10H11V6ZM10 7C10 6.44771 10.4477 6 11 6V10C12.6569 10 14 8.65685 14 7H10ZM11 8C10.4477 8 10 7.55229 10 7H14C14 5.34315 12.6569 4 11 4V8ZM8 8L11 8V4L8 4V8ZM6 3V6H10V3H6ZM7 4C6.44772 4 6 3.55228 6 3H10C10 1.34315 8.65685 0 7 0V4Z"
            fill="#282E3F"
            mask="url(#path-1-outside-1)"
          />
        </svg>
      )}
    </>
  );
}

export default FeedbackIcon;
