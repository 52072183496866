import { createFileRoute } from "@tanstack/react-router";
import CourseEditorStatsPage from "../../../pages/Admin/CourseEditorStatsPage/CourseEditorStatsPage";

export const Route = createFileRoute("/(app)/admin/course-editor-stats")({
  component: RouteComponent,
});

function RouteComponent() {
  return <CourseEditorStatsPage />;
}
