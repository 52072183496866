import { createFileRoute } from "@tanstack/react-router";
import UserCourses from "../../../../features/User/UserCourses/UserCourses";
import { userCoursesProgressQueryOptions } from "../../../../api/useUserCoursesProgress";
import { userProgressSummaryQueryOptions } from "../../../../api/useUserProgressSummary";
import { useSuspenseQuery } from "@tanstack/react-query";

export const Route = createFileRoute("/(app)/users/$id/courses")({
  component: RouteComponent,
  loader: ({ params, context }) => {
    context.queryClient.ensureQueryData(
      userCoursesProgressQueryOptions({
        userId: Number(params.id),
        enabled: !!context.auth.isAuthenticated,
      })
    );
    context.queryClient.ensureQueryData(
      userProgressSummaryQueryOptions({
        userId: Number(params.id),
        enabled: !!context.auth.isAuthenticated,
      })
    );
  },
});

function RouteComponent() {
  const { id } = Route.useParams();
  const isAuthenticated = Route.useRouteContext().auth.isAuthenticated;

  const {
    data: { list },
  } = useSuspenseQuery(
    userCoursesProgressQueryOptions({
      userId: Number(id),
      enabled: !!isAuthenticated,
    })
  );
  const { data } = useSuspenseQuery(
    userProgressSummaryQueryOptions({
      userId: Number(id),
      enabled: !!isAuthenticated,
    })
  );
  return (
    <UserCourses
      userId={Number(id)}
      userCourseProgress={list}
      userSummary={data}
    />
  );
}
