import { createFileRoute, getRouteApi } from "@tanstack/react-router";
import GroupTacographPage from "../../../../pages/GroupPage/GroupTachographPage/GroupTacographPage";

export const Route = createFileRoute("/(app)/groups/$id/tachograph")({
  component: RouteComponent,
});

const routeApi = getRouteApi("/(app)/groups/$id");

function RouteComponent() {
  const group = routeApi.useLoaderData();
  return <GroupTacographPage group={group} />;
}
