import { createFileRoute, getRouteApi } from "@tanstack/react-router";
import GroupDrivingHistoryPage from "../../../../pages/GroupPage/GroupDrivingHistoryPage/GroupDrivingHistoryPage";

export const Route = createFileRoute("/(app)/groups/$id/driving-history")({
  component: RouteComponent,
});

const routeApi = getRouteApi("/(app)/groups/$id");

function RouteComponent() {
  const group = routeApi.useLoaderData();
  return <GroupDrivingHistoryPage group={group} />;
}
