import { createFileRoute, getRouteApi } from "@tanstack/react-router";
import GroupProgressPage from "../../../../pages/GroupPage/GroupProgressPage/GroupProgressPage";
import { groupQueryOptions } from "../../../../api/useGroup";
import { useSuspenseQuery } from "@tanstack/react-query";

export const Route = createFileRoute("/(app)/groups/$id/courses")({
  component: RouteComponent,
  loader: ({ params, context }) => {
    return context.queryClient.ensureQueryData(
      groupQueryOptions(Number(params.id))
    );
  },
  validateSearch: (): {
    c?: string;
  } => ({}),
});

function RouteComponent() {
  const { id } = Route.useParams();
  const { data: group } = useSuspenseQuery(groupQueryOptions(Number(id)));
  return <GroupProgressPage group={group} />;
}
