import { createFileRoute, Outlet } from "@tanstack/react-router";
import CreateUsersPage from "../../../../pages/UsersPage/CreateUsersPage";

export const Route = createFileRoute("/(app)/users/create")({
  component: RouteComponent,
});

function RouteComponent() {
  return <CreateUsersPage />;
}
