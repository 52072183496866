import { createFileRoute } from "@tanstack/react-router";
import YoutubePage from "../../../pages/HomePage/Youtube/YoutubePage";

export const Route = createFileRoute("/(app)/home/youtube")({
  component: RouteComponent,
});

function RouteComponent() {
  return <YoutubePage />;
}
