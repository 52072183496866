import { QueryClient, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "../AuthContext";
import { apiGet } from "../helpers/request.helpers";
import useGoToTopLevelRoute from "../hooks/useGoToTopLevelRoute";

const queryKey = "school-list";

export type SchoolListOption = {
  ID: number;
  Name: string;
  SimulatorCount: number;
};

export function useSchoolNavigationList(organizationId?: number | null) {
  const queryClient = useQueryClient();
  const organization_id =
    organizationId ?? Number(localStorage.getItem("organization_id"));
  const { selectedSchoolId, setSelectedSchoolId, isAuthenticated } = useAuth();
  const goToTopLevelRoute = useGoToTopLevelRoute();

  return useQuery({
    queryKey: [queryKey, organization_id],
    queryFn: async () => {
      const json = await apiGet("/list-organization-schools", {
        organization_id,
      });
      const list: SchoolListOption[] = json.Schools;
      const map = new Map<number, SchoolListOption>();
      list.forEach((school) => {
        map.set(school.ID, school);
      });
      if (list.length && !list.some((s) => s.ID === selectedSchoolId)) {
        changeSchool(
          list[0].ID,
          queryClient,
          setSelectedSchoolId,
          goToTopLevelRoute
        );
      }
      return { list, map };
    },
    gcTime: Infinity,
    enabled: !!isAuthenticated,
  });
}

export function changeSchool(
  schoolId: number,
  queryClient: QueryClient,
  handleStore: (schoolId: number) => void,
  goToTopLevelRoute: () => void
) {
  handleStore(schoolId);
  localStorage.setItem("school_id", schoolId.toString());
  const queriesToKeep = [
    queryKey,
    "list-school-users",
    "get-session-user",
    "organization-list",
    "list-school-users-progress",
    "school-classes",
    "all-simulators",
    "all-schools",
    "get-simulators-hardware",
    "simulators",
    "exercises",
    "courses",
    "course-categories",
  ];
  queryClient.resetQueries({
    predicate: (query) =>
      queriesToKeep.every((qkey) => qkey !== query.queryKey[0]),
  });
  goToTopLevelRoute();
}
