import { createFileRoute, redirect } from "@tanstack/react-router";
import LoginPage from "../pages/LoginPage/LoginPage";
import { z } from "zod";

export const Route = createFileRoute("/login")({
  component: RouteComponent,
  validateSearch: z.object({
    redirect: z.string().optional().catch(""),
  }),
  beforeLoad: ({ context, search }) => {
    if (context.auth.isAuthenticated) {
      throw redirect({ to: search.redirect || "/home" });
    }
  },
});

function RouteComponent() {
  const { redirect } = Route.useSearch();
  return <LoginPage redirect={redirect} />;
}
