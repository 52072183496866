import { createFileRoute } from "@tanstack/react-router";
import UserTachographProgress from "../../../../features/User/UserTachographProgress/UserTachographProgress";

export const Route = createFileRoute("/(app)/users/$id/tachograph")({
  component: RouteComponent,
});

function RouteComponent() {
  const { id } = Route.useParams();
  return <UserTachographProgress id={Number(id)} />;
}
