import { IconAlignCenter } from "@tabler/icons-react";
import LinkItem from "../LinkItem/LinkItem";
import { useAuth } from "../../../../../../../../../AuthContext";
import { USERS_PATH } from "../../../../../../../../../routes";
import { useTranslation } from "react-i18next";

function DrivingRecordLink() {
  const { t } = useTranslation();
  const { user } = useAuth();
  return (
    <LinkItem
      label={t("user.subMenu.drivingRecord")}
      icon={IconAlignCenter}
      to={`${USERS_PATH}/${user?.ID}/driving-record`}
    />
  );
}

export default DrivingRecordLink;
