import { useTranslation } from "react-i18next";
import {
  COURSES_PATH,
  SUPPORT_PATH,
  GROUPS_PATH,
  SIMULATORS_PATH,
  HOME_START_PATH,
  USERS_PATH,
} from "../routes";

function useMenuItems() {
  const { t } = useTranslation("common");
  const items = [
    {
      label: t("menu.home"),
      to: HOME_START_PATH,
    },
    {
      label: t("menu.schoolClasses"),
      to: GROUPS_PATH,
    },
    {
      label: t("menu.users"),
      to: USERS_PATH,
    },
    {
      label: t("menu.courses"),
      to: COURSES_PATH,
    },
    {
      label: t("menu.simulators"),
      to: SIMULATORS_PATH,
    },
    {
      label: t("menu.support"),
      to: SUPPORT_PATH,
    },
  ];
  return items;
}

export default useMenuItems;
