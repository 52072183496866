import { createFileRoute } from "@tanstack/react-router";
import CreateGroupWizard from "../../../features/Groups/CreateGroupWizard/CreateGroupWizard";

export const Route = createFileRoute("/(app)/groups/create")({
  component: RouteComponent,
});

function RouteComponent() {
  return <CreateGroupWizard wizardStep={1} />;
}
