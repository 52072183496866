import { useAuth } from "../AuthContext";
import {
  ADMIN_ROLE,
  SKILLSTER_ORGANIZATION_ID,
  SKILLSTER_SCHOOL_ID,
} from "./constants";

export function useIsAdminUser() {
  const { user } = useAuth();
  return user?.Role === Number(ADMIN_ROLE.value);
}

export function useIsAdminUserOnSkillsterOrganization() {
  const isAdminUser = useIsAdminUser();
  const { selectedOrganizationId } = useAuth();
  return isAdminUser && selectedOrganizationId === SKILLSTER_ORGANIZATION_ID;
}

export function useIsAdminUserOnSkillsterSchool() {
  const isAdminUser = useIsAdminUser();
  const { selectedSchoolId } = useAuth();
  return isAdminUser && selectedSchoolId === SKILLSTER_SCHOOL_ID;
}
