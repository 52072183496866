import { createFileRoute } from "@tanstack/react-router";
import { useGithubCallback } from "../../api/tickets/useGithubCallback";
import { useEffect } from "react";
import LoadingScreen from "../../features/Layout/LoadingScreen/LoadingScreen";

type GithubSearch = {
  code: string;
  state: string;
};

export const Route = createFileRoute("/oauth/github/callback")({
  component: RouteComponent,
  validateSearch: (search: Record<string, unknown>): GithubSearch => {
    return {
      code: (search.code as string) || "",
      state: (search.state as string) || "",
    };
  },
});

function RouteComponent() {
  const { code, state } = Route.useSearch();

  const { mutate } = useGithubCallback();

  useEffect(() => {
    mutate({ code, state });
  }, []);

  return <LoadingScreen />;
}
