import { createFileRoute } from "@tanstack/react-router";
import ActivationPage from "../pages/ActivationPage/ActivationPage";

export const Route = createFileRoute("/activate")({
  component: RouteComponent,
});

function RouteComponent() {
  const token = window.location.search.substring(1);

  return <ActivationPage token={token} />;
}
