import { createFileRoute, useParams } from "@tanstack/react-router";
import UserProfile from "../../../../features/User/UserProfile/UserProfile";

export const Route = createFileRoute("/(app)/users/$id/profile")({
  component: RouteComponent,
});

function RouteComponent() {
  const { id } = Route.useParams();
  return <UserProfile id={Number(id)} />;
}
