import { createFileRoute, useParams } from "@tanstack/react-router";
import AcademyCourseStats from "../../../../../../features/Academy/AcademyStats/AcademyCourseStats/AcademyCourseStats";

export const Route = createFileRoute("/(app)/admin/academy/stats/$lectureId/")({
  component: RouteComponent,
});

function RouteComponent() {
  const { lectureId } = useParams({ strict: false });
  return <AcademyCourseStats id={Number(lectureId)} />;
}
