import { IconCircle } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../../../../../../AuthContext";
import { USERS_PATH } from "../../../../../../../../../routes";
import LinkItem from "../LinkItem/LinkItem";

function TachographLink() {
  const { t } = useTranslation();
  const { user } = useAuth();
  return (
    <LinkItem
      label={t("user.subMenu.tachograph")}
      icon={IconCircle}
      to={`${USERS_PATH}/${user?.ID}/tachograph`}
    />
  );
}

export default TachographLink;
