import { createFileRoute } from "@tanstack/react-router";
import ImportUsers from "../../../../features/Users/ImportUsers/ImportUsers";

export const Route = createFileRoute("/(app)/users/create/import")({
  component: RouteComponent,
});

function RouteComponent() {
  return <ImportUsers />;
}
