import { Button, Stack, Text, Textarea, TextInput, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import useSendFeedback from "../../../../../api/useSendFeedback";
import { modals } from "@mantine/modals";
import { SessionUser } from "../../../../../store/auth";

function FeedbackForm({ user }: { user: SessionUser | null }) {
  const { t } = useTranslation();
  const [email, setEmail] = useState(user?.Email ?? "");
  const [message, setMessage] = useState("");
  const { mutate, isPending } = useSendFeedback({
    onSuccess: () => modals.closeAll(),
  });

  const handleSubmit = () => {
    mutate({
      id: user?.ID ?? 0,
      name: `${user?.FirstName} ${user?.LastName}`,
      email,
      message,
      fromPage: window.location.href,
      tags: ["teacher", "feedback"],
    });
  };
  return (
    <Stack gap="xs">
      <Title order={4}>
        {t("common:feedback.subTitle")} {user?.FirstName}
      </Title>
      <Text>{t("common:feedback.description")}</Text>
      <TextInput
        label={t("common:inputLabels.email")}
        placeholder={t("common:inputLabels.email") ?? ""}
        value={email}
        onChange={(e) => setEmail(e.currentTarget.value)}
      />
      <Textarea
        label={t("common:inputLabels.message")}
        minRows={5}
        data-autofocus
        rows={10}
        value={message}
        onChange={(e) => setMessage(e.currentTarget.value)}
      />
      <Button
        fullWidth
        mt="md"
        disabled={message === ""}
        loading={isPending}
        onClick={handleSubmit}
      >
        {t("common:buttons.send")}
      </Button>
    </Stack>
  );
}

export default FeedbackForm;
