import { createFileRoute } from "@tanstack/react-router";
import CreateCompetitionPage from "../../../../pages/Admin/Competitions/CreateCompetitionPage";

export const Route = createFileRoute("/(app)/admin/competitions/create")({
  component: RouteComponent,
});

function RouteComponent() {
  return <CreateCompetitionPage />;
}
