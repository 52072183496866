import { createFileRoute } from "@tanstack/react-router";
import UserPage from "../../../../pages/UserPage/UserPage";
import PageContainer from "../../../../components/PageContainer/PageContainer";
import ErrorComponent from "../../../../components/RoutingErrors/ErrorComponent/ErrorComponent";
import { Loader } from "@mantine/core";
import i18next from "../../../../i18n";
import { LinkButton } from "../../../../components/LinkButton/LinkButton";

export const Route = createFileRoute("/(app)/users/$id")({
  component: RouteComponent,
  errorComponent: () => (
    <PageContainer>
      <ErrorComponent
        title={i18next.t("common:emptyStates.userNotFound.title")}
        description={i18next.t("common:emptyStates.userNotFound.description")}
        bottomComponent={
          <LinkButton to="/users">
            {i18next.t("common:emptyStates.userNotFound.button")}
          </LinkButton>
        }
      />
    </PageContainer>
  ),
  pendingComponent: () => (
    <PageContainer>
      <Loader color="blue.5" />
    </PageContainer>
  ),
});

function RouteComponent() {
  const { id } = Route.useParams();
  return <UserPage id={Number(id)} />;
}
