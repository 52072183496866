import { createFileRoute } from "@tanstack/react-router";
import LessonPage from "../../../pages/LessonPage/LessonPage";

export const Route = createFileRoute(
  "/(app)/courses_/$type/$publicCourseUuid/lessons/$publicLessonUuid"
)({
  component: RouteComponent,
  validateSearch: (): {
    e?: string;
  } => ({}),
});

function RouteComponent() {
  return <LessonPage />;
}
