import { Button, Stack, TextInput } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { MultipleSurveyQuestion } from "posthog-js";
import { useState } from "react";
import classes from "./styles.module.scss";
import clsx from "clsx";

function MultipleChoice({
  question,
  onSubmit,
}: {
  question: MultipleSurveyQuestion;
  onSubmit: (value: string[]) => void;
}) {
  const [selected, setSelected] = useState<string>("");
  const [customText, setCustomText] = useState("");
  function handleTextInput(event: React.ChangeEvent<HTMLInputElement>) {
    setSelected(event.target.value);
    setCustomText(event.target.value);
  }
  function handleChoiceClick(choice: string) {
    setSelected(choice);
    setCustomText("");
  }

  const checkIcon = <IconCheck size={16} color="white" />;
  return (
    <Stack gap="xs">
      {question.choices.map((choice, index) => {
        if (question.hasOpenChoice && index === question.choices.length - 1)
          return (
            <TextInput
              key="custom"
              placeholder={choice}
              classNames={{
                input: clsx({
                  [classes.inputWrapper]: true,
                  [classes.selected]: customText !== "",
                }),
              }}
              value={customText}
              rightSection={customText !== "" ? checkIcon : null}
              onChange={handleTextInput}
            />
          );
        return (
          <Button
            key={choice}
            size="xs"
            variant="outline"
            classNames={{
              root: clsx({
                [classes.choiceRoot]: true,
                [classes.selected]: selected === choice,
              }),
              inner: clsx({
                [classes.choiceInner]: true,
                [classes.selected]: selected === choice,
              }),
            }}
            h={36}
            rightSection={selected === choice ? checkIcon : null}
            onClick={() => handleChoiceClick(choice)}
          >
            {choice}
          </Button>
        );
      })}
      <Button
        disabled={selected === ""}
        onClick={() => onSubmit(customText === "" ? [selected] : [customText])}
        classNames={{ root: classes.button }}
      >
        {question.buttonText || "Skicka"}
      </Button>
    </Stack>
  );
}

export default MultipleChoice;
