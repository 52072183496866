import { createFileRoute } from "@tanstack/react-router";
import StartPage from "../../../pages/HomePage/Start/StartPage";

export const Route = createFileRoute("/(app)/home/start")({
  component: RouteComponent,
});

function RouteComponent() {
  return <StartPage />;
}
