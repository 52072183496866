import { useTranslation } from "react-i18next";

export const svLocale = () =>
  localStorage.getItem("i18nextLng")?.startsWith("sv");
export const fiLocale = () =>
  localStorage.getItem("i18nextLng")?.startsWith("fi");
export const enLocale = () =>
  localStorage.getItem("i18nextLng")?.startsWith("en");
export const fetchLang = () =>
  svLocale() ? "sv-SE" : fiLocale() ? "fi-FI" : "en-US";
export const useFetchLang = () => {
  const { i18n } = useTranslation();
  return i18n.language.startsWith("sv")
    ? "sv-SE"
    : i18n.language.startsWith("fi")
    ? "fi-FI"
    : "en-US";
};
