import { Box, Menu, Title } from "@mantine/core";
import { useAuth } from "../../../../../AuthContext";
import { nameToInitials } from "../../../../../helpers/string.helpers";
import CircleContainer from "../CircleContainer";
import Dropdown from "./components/Dropdown/DropDown";
import { useState } from "react";

function ProfileDropdown() {
  const { user } = useAuth();
  const [opened, setOpened] = useState(false);
  return (
    <Menu
      styles={(theme) => ({
        dropdown: {
          backgroundColor: theme.colors.bluegray[8],
          borderColor: theme.colors.bluegray[7],
          padding: 0,
        },
      })}
      position="bottom"
      trigger="click-hover"
      opened={opened}
      closeOnClickOutside={false}
      onChange={setOpened}
    >
      <Menu.Target>
        <Box>
          <CircleContainer>
            <Title size={14} c="white">
              {nameToInitials(`${user?.FirstName} ${user?.LastName}`)}
            </Title>
          </CircleContainer>
        </Box>
      </Menu.Target>
      <Menu.Dropdown>
        <Dropdown closeDropdown={() => setOpened(false)} />
      </Menu.Dropdown>
    </Menu>
  );
}

export default ProfileDropdown;
