import { createFileRoute } from '@tanstack/react-router'
import GroupEditPage from '../../../../../pages/GroupPage/GroupEditPage/GroupEditPage'

export const Route = createFileRoute('/(fullscreen)/groups/$id/edit')({
  component: RouteComponent,
})

function RouteComponent() {
  const { id } = Route.useParams()
  return <GroupEditPage id={Number(id)} />
}
