import {
  addDays,
  addMonths,
  format,
  Locale,
  startOfWeek,
  startOfYear,
} from "date-fns";
import { enGB, fi, sv } from "date-fns/locale";
import { fiLocale, svLocale } from "./language.helpers";

export const BEGINNING_STATISTICS_DATE = "2020-05-02";

const WEEKDAYS = (short?: boolean) => {
  const locale: Locale = svLocale() ? sv : fiLocale() ? fi : enGB;
  const first = startOfWeek(new Date());
  return Array.from(Array(7)).map((_, i) =>
    format(addDays(first, i), short ? "EEEEEE" : "EEEE", { locale })
  );
};

const MONTHS = (short?: boolean) => {
  const locale: Locale = svLocale() ? sv : fiLocale() ? fi : enGB;
  const first = startOfYear(new Date());
  return Array.from(Array(12)).map((_, i) =>
    format(addMonths(first, i), short ? "LLL" : "LLLL", { locale })
  );
};

export const MONTHS_LONG = () => MONTHS();
export const MONTHS_SHORT = () => MONTHS(true);
export const WEEKDAYS_LONG = () => WEEKDAYS();
export const WEEKDAYS_SHORT = () => WEEKDAYS(true);

export const SKILLSTER_ORGANIZATION_ID = 1;
export const SKILLSTER_SCHOOL_ID = 25;

export const SKILLSTER_DEMO_ORGANIZATION_ID = 62;
export const SKILLSTER_DEMO_ORGANIZATION_ID_FINLAND = 193;
export const SKILLSTER_ORGANIZATION_IDS = [
  SKILLSTER_ORGANIZATION_ID,
  SKILLSTER_DEMO_ORGANIZATION_ID,
  SKILLSTER_DEMO_ORGANIZATION_ID_FINLAND,
];

export const ADMIN_ROLE = { label: "Admin", value: "1" };
export const STANDARD_ROLES = [
  { label: "Elev", value: "3" },
  { label: "Lärare", value: "2" },
];
export const createRoles = ({ includeAdmin }: { includeAdmin?: boolean }) =>
  includeAdmin ? [ADMIN_ROLE, ...STANDARD_ROLES] : STANDARD_ROLES;

const roles = createRoles({ includeAdmin: true });
export const rolesMap: Map<number, string> = new Map();
roles.forEach((role) => {
  rolesMap.set(Number(role.value), role.label);
});

export const START_CACHE_BASE_PATH = "skillster-start-search-cache-";

export const generateManualUrl = () =>
  svLocale()
    ? "https://res.cloudinary.com/dy7i8hekz/image/upload/v1683057604/L%C3%A4rarverktyget/Manualer/skillster-manual-sv_zczljx.pdf"
    : fiLocale()
    ? "https://res.cloudinary.com/dy7i8hekz/image/upload/v1683057605/L%C3%A4rarverktyget/Manualer/skillster-manual-fi_lgwv81.pdf"
    : "https://res.cloudinary.com/dy7i8hekz/image/upload/v1683057597/L%C3%A4rarverktyget/Manualer/skillster-manual-en_l9crgp.pdf";

export const COURSE_CARD_GRID_BREAKPOINTS = {
  base: 1,
  xs: 2,
  sm: 4,
  md: 6,
  lg: 7,
};
