import { createFileRoute, Outlet } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'
import PageContainer from '../../../components/PageContainer/PageContainer'
import CreateCoursePopover from '../../../features/Courses/CreateCoursePopover'
import SubBar from '../../../features/Layout/SubBar/SubBar'
import SubMenu from '../../../features/Layout/SubMenu/SubMenu'
import { useSubmenuItems } from '../../../pages/CoursesPage/submenuItems'

export const Route = createFileRoute('/(app)/courses')({
  component: RouteComponent,
})

function RouteComponent() {
  const { t } = useTranslation()
  const submenuItems = useSubmenuItems()
  return (
    <>
      <SubBar
        title={t('common:listTitles.courses')}
        filterKey="courses"
        rightSection={<CreateCoursePopover />}
      />
      <SubMenu items={submenuItems} />
      <PageContainer>
        <Outlet />
      </PageContainer>
    </>
  )
}
