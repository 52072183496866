import { ReactNode } from "react";
import { useFetchLang } from "../../helpers/language.helpers";

function RestrictToLanguage({
  children,
  language,
}: {
  children?: ReactNode;
  language: "sv" | "fi" | "en";
}) {
  const fetchLang = useFetchLang();

  if (fetchLang.startsWith(language)) return children;

  return null;
}

export default RestrictToLanguage;
