import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../AuthContext";
import { apiGet } from "../helpers/request.helpers";

export type UserFromSchool = {
  ID: number;
  FirstName: string;
  LastName: string;
  Email: string;
  Role: number;
  SchoolClass: string;
  SchoolID: number;
  School: string;
  SchoolClassID: number;
  OrganizationID: number;
  LastActive: string;
  AssignedCourses: string[];
};

export function useListSchoolUsers() {
  const { selectedSchoolId, isAuthenticated } = useAuth();
  return useQuery({
    queryKey: ["list-school-users", selectedSchoolId],
    queryFn: fetchSchoolUsers,
    staleTime: 20 * 1000,
    enabled: !!isAuthenticated,
  });
}

export const fetchSchoolUsers = async () => {
  const data = await apiGet("/list-school-users", {
    school_id: localStorage.getItem("school_id"),
  });
  const list: UserFromSchool[] = data.Users ?? [];
  const map = new Map<number, UserFromSchool>();
  list.forEach((u) => map.set(u.ID, u));
  return { list, map };
};
