import { createFileRoute } from "@tanstack/react-router";
import OrganizationsPage from "../../../../pages/Admin/Organizations/OrganizationsPage/OrganizationsPage";

export const Route = createFileRoute("/(app)/admin/organizations/")({
  component: RouteComponent,
});

function RouteComponent() {
  return <OrganizationsPage />;
}
