import { Button, Center, Group, Loader, Text } from "@mantine/core";
import { useHotkeys } from "@mantine/hooks";
import { IconArrowLeft } from "@tabler/icons-react";
import {
  createFileRoute,
  useCanGoBack,
  useNavigate,
  useParams,
  useRouter,
} from "@tanstack/react-router";
import { useState } from "react";
import { useBugScreenshot } from "../../api/tickets/useBugScreenshot";

export const Route = createFileRoute(
  "/(fullscreen)/admin/tickets/$id/screenshot/$bugId"
)({
  component: RouteComponent,
});

function RouteComponent() {
  const { data } = useBugScreenshot(Route.useParams().bugId);
  const [zoomed, setZoomed] = useState(false);
  const { id } = useParams({
    from: "/(fullscreen)/admin/tickets/$id/screenshot/$bugId",
  });
  const img = `data:image/jpeg;base64,${data}`;
  const navigate = useNavigate();

  const canGoBack = useCanGoBack();
  const router = useRouter();

  const handleGoBack = () => {
    if (canGoBack) {
      router.history.back();
    } else {
      navigate({
        to: "/admin/tickets/$id",
        replace: true,
        params: { id },
      });
    }
  };

  useHotkeys([["escape", handleGoBack]]);

  return (
    <div
      style={{
        display: zoomed ? undefined : "flex",
        alignItems: zoomed ? undefined : "center",
        backgroundColor: "var(--mantine-color-bluegray-8)",
        overflow: "scroll",
        width: "100vw",
        height: "100vh",
      }}
    >
      {data ? (
        <img
          src={img}
          width={zoomed ? "auto" : "100%"}
          style={{ cursor: zoomed ? "zoom-out" : "zoom-in" }}
          onClick={() => setZoomed((z) => !z)}
        />
      ) : (
        <Center w="100%">
          <Loader size="md" />
        </Center>
      )}
      <Group pos="fixed" top={40} left={40}>
        <Button
          leftSection={<IconArrowLeft size={16} />}
          radius="xl"
          onClick={handleGoBack}
        >
          Tillbaka
        </Button>
        <Text fw="bold" c="white">
          Skärmdump ärende {id}
        </Text>
      </Group>
    </div>
  );
}
