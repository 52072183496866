import { create } from "zustand";

interface StoreState {
  filters: Map<string, string>;
  selectedCourseCategory: number | null;
  mobileMenuOpen: boolean;
}

interface StoreActions {
  setFilter: (key: string, filter: string) => void;
  setSelectedCourseCategory: (selectedCourseCategory: number) => void;
  setMobileMenuOpen: (mobileMenuOpen: boolean) => void;
}

const useSkillsterStore = create<StoreState & StoreActions>()((set) => ({
  filters: new Map(),
  selectedCourseCategory: null,
  mobileMenuOpen: false,
  setMobileMenuOpen: (mobileMenuOpen: boolean) =>
    set(() => ({ mobileMenuOpen })),
  setFilter: (key: string, filter: string) => {
    return set((state) => {
      const updatedFilters = new Map(state.filters);
      updatedFilters.set(key, filter);
      return {
        filters: updatedFilters,
      };
    });
  },
  setSelectedCourseCategory: (selectedCourseCategory: number) =>
    set(() => ({ selectedCourseCategory })),
}));

export default useSkillsterStore;
