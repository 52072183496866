import { Box, Burger, Container, Flex, Group, Image } from "@mantine/core";
import { Link } from "@tanstack/react-router";
import { Suspense } from "react";
import logo from "../../../assets/svg/logo.skillster.svg";
import logoEn from "../../../assets/svg/logo.teacher.en.svg";
import logoSv from "../../../assets/svg/logo.teacher.sv.svg";
import { useFetchLang } from "../../../helpers/language.helpers";
import useIsMobile from "../../../hooks/useIsMobile";
import { HOME_START_PATH } from "../../../routes";
import useSkillsterStore from "../../../store/app";
import AdminOnly from "../AdminOnly/AdminOnly";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import FeedbackButton from "./components/FeedbackButton/FeedbackButton";
import HeaderMenuItems from "./components/HeaderMenuItems";
import OrganizationNavigation from "./components/ListSelects/OrganizationNavigation/OrganizationNavigation";
import SchoolNavigation from "./components/ListSelects/SchoolNavigation/SchoolNavigation";
import OnlineCounter from "./components/OnlineCounter/OnlineCounter";
import ProfileDropdown from "./components/ProfileDropdown/ProfileDropdown";
import classes from "./styles.module.css";

function Header() {
  const mobile = useIsMobile();
  const mobileMenuOpen = useSkillsterStore((state) => state.mobileMenuOpen);
  const setMobileMenuOpen = useSkillsterStore(
    (state) => state.setMobileMenuOpen
  );
  const fetchLang = useFetchLang();
  if (mobile)
    return (
      <Suspense fallback={<LoadingScreen />}>
        <Container fluid className={classes.container} p={0}>
          <Container p="xs">
            <Group gap="xs" justify="space-between" wrap="nowrap">
              <Group gap="lg" wrap="nowrap">
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "2px solid var(--mantine-color-bluegray-7)",
                    borderRadius: 10,
                    padding: 5,
                  }}
                  bg="bluegray.7"
                  onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                >
                  <Burger opened={mobileMenuOpen} color="white" size="sm" />
                </Box>
                <Box py={7} component={Link} to={HOME_START_PATH}>
                  <Image src={logo} width="auto" />
                </Box>
              </Group>
              <Group gap="xs" wrap="nowrap">
                <OnlineCounter />
                <FeedbackButton hidePlus />
                <ProfileDropdown />
              </Group>
            </Group>
          </Container>
        </Container>
      </Suspense>
    );
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Container fluid className={classes.container} p={0}>
        <Container py="sm">
          <Flex justify="space-between" gap="md">
            <Group style={{ flexGrow: 4 }}>
              <Link to={HOME_START_PATH}>
                <Image src={fetchLang === "sv-SE" ? logoSv : logoEn} />
              </Link>
            </Group>

            <Flex style={{ flexGrow: 10 }} align="center" gap="md">
              <OnlineCounter />
              <FeedbackButton />
              <HeaderMenuItems />
            </Flex>

            <Group
              justify="flex-end"
              style={{ flexGrow: 4 }}
              gap="xs"
              wrap="nowrap"
            >
              <AdminOnly>
                <OrganizationNavigation />
              </AdminOnly>
              <SchoolNavigation />
              <ProfileDropdown />
            </Group>
          </Flex>
        </Container>
      </Container>
    </Suspense>
  );
}

export default Header;
