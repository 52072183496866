import { createFileRoute } from "@tanstack/react-router";
import AcademyCourses from "../../../../features/Academy/AcademyCourses/AcademyCourses";

export const Route = createFileRoute("/(app)/admin/academy/courses")({
  component: RouteComponent,
});

function RouteComponent() {
  return <AcademyCourses />;
}
