import { createFileRoute } from '@tanstack/react-router'
import UserEditPage from '../../../../../pages/UserPage/UserEditPage/UserEditPage'

export const Route = createFileRoute('/(fullscreen)/users/$id/edit')({
  component: RouteComponent,
})

function RouteComponent() {
  const { id } = Route.useParams()
  return <UserEditPage id={Number(id)} />
}
