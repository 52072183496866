import { createFileRoute } from "@tanstack/react-router";
import HomePage from "../../../pages/HomePage/HomePage";
import PageNotFoundComponent from "../../../components/RoutingErrors/PageNotFoundComponent/PageNotFoundComponent";

export const Route = createFileRoute("/(app)/home")({
  component: RouteComponent,
  notFoundComponent: () => <PageNotFoundComponent />,
});

function RouteComponent() {
  return <HomePage />;
}
