import { Loader, Title } from "@mantine/core";
import { nameToInitials } from "../../../helpers/string.helpers";
import classes from "./styles.module.css";
import { forwardRef } from "react";

interface UserBadgeProps {
  firstName?: string;
  lastName?: string;
  online?: boolean;
  white?: boolean;
}
const UserBadge = forwardRef<HTMLDivElement, UserBadgeProps>((props, ref) => {
  return (
    <div ref={ref} className={classes.container} data-white={props.white}>
      {props.firstName || props.lastName ? (
        <>
          <Title order={6} tt="uppercase" c="dark.9" className="notranslate">
            {nameToInitials(`${props.firstName} ${props.lastName}`)}
          </Title>
        </>
      ) : (
        <Loader />
      )}
      <div className={`${classes.dot} ${props.online ? classes.online : ""}`} />
    </div>
  );
});

export default UserBadge;
