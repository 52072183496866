import { Stack, Text } from "@mantine/core";
import CoursesLink from "./components/CoursesLink";
import DrivingRecordLink from "./components/DrivingRecordLink/DrivingRecordLink";
import ProfileLink from "./components/ProfileLink/ProfileLink";
import UserDetailsLink from "./components/UserDetailsLink/UserDetailsLink";
import AdminOnly from "../../../../../AdminOnly/AdminOnly";
import AdminMenu from "./components/AdminMenu/AdminMenu";
import LogoutLink from "./components/LogoutLink";
import TachographLink from "./components/TachographLink/TachographLink";
import { useTranslation } from "react-i18next";

function Dropdown({ closeDropdown }: { closeDropdown: () => void }) {
  const { t } = useTranslation();
  return (
    <Stack gap={0} p={0} pb={5}>
      <div onClick={closeDropdown}>
        <ProfileLink />
        <CoursesLink />
        <DrivingRecordLink />
        <TachographLink />
        <UserDetailsLink />
      </div>
      <Text size="xs" c="white" m="sm" fw="bold">
        {t("common:misc.other")}
      </Text>
      <AdminOnly>
        <AdminMenu closeDropdown={closeDropdown} />
      </AdminOnly>
      <LogoutLink />
    </Stack>
  );
}

export default Dropdown;
