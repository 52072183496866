import { Paper, Stack, Text } from "@mantine/core";
import SkillsterTitle from "../../SkillsterTitle/SkillsterTitle";
import { useTranslation } from "react-i18next";
import { LinkButton } from "../../LinkButton/LinkButton";
import { ReactNode } from "react";

function ErrorComponent({
  title,
  description,
  bottomComponent,
}: {
  title?: string;
  description?: string;
  bottomComponent?: ReactNode;
}) {
  const { t } = useTranslation();
  return (
    <Paper bg="bluegray.1" py={200}>
      <Stack align="center">
        <SkillsterTitle order={2}>
          {title ?? t("common:feedback.error")}
        </SkillsterTitle>
        {description && <Text c="dimmed">{description}</Text>}
        {bottomComponent || (
          <LinkButton to="/home">{t("common:pageNotFound.button")}</LinkButton>
        )}
      </Stack>
    </Paper>
  );
}

export default ErrorComponent;
