export class NetworkError extends Error {
  Response: any;
  StatusCode: number;

  constructor(
    statusCode: number,
    statusText: string,
    endpoint: string,
    body: any
  ) {
    super(`API error ${endpoint}: ${statusCode} ${statusText}`);
    this.StatusCode = statusCode;
    this.Response = body;
  }
}

export class ErrorResponse extends Error {
  Response: any;

  constructor(response: any) {
    super(`Error Response: ${response.toString()}`);
    this.Response = response;
  }
}

async function apiGenericRequest(
  method: string,
  endpoint: string,
  extraArgs: any,
  body: any
): Promise<any> {
  const token = localStorage.getItem("authentication_token");
  const schoolID = extraArgs?.school_id
    ? undefined
    : localStorage.getItem("school_id");

  let querySchoolID = schoolID ? `&school_id=${schoolID}` : "";

  let query = `?token=${encodeURIComponent(token ?? "")}${querySchoolID}`;
  for (let key in extraArgs) {
    if (extraArgs.hasOwnProperty(key)) {
      query += `&${encodeURIComponent(key)}=${encodeURIComponent(extraArgs[key])}`;
    }
  }

  if (body !== undefined) body = JSON.stringify(body);
  const response = await fetch(
    `${import.meta.env.VITE_API_URI}/frontend${endpoint}${query}`,
    { method, body }
  );

  let json;

  if (response.headers.get("content-type")?.includes("application/json")) {
    json = await response.json();
  }

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      response.statusText,
      endpoint,
      json
    );
  }

  if (json.Status !== 1) throw new ErrorResponse(json.Error);

  return json.Data;
}

export function apiGet(endpoint: string, extraArgs?: any): Promise<any> {
  return apiGenericRequest("GET", endpoint, extraArgs, undefined);
}

export function apiPost(
  endpoint: string,
  body: any,
  extraArgs?: any
): Promise<any> {
  return apiGenericRequest("POST", endpoint, extraArgs, body);
}

export function apiDelete(endpoint: string, body: any): Promise<any> {
  return apiGenericRequest("DELETE", endpoint, undefined, body);
}

export function apiPut(endpoint: string, body: any): Promise<any> {
  return apiGenericRequest("PUT", endpoint, undefined, body);
}

export function apiUnauthenticatedPost(
  endpoint: string,
  body: any
): Promise<any> {
  return apiGenericRequest("POST", endpoint, null, body);
}
