import { createFileRoute, Outlet, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/")({
  component: RouteComponent,
  beforeLoad: () => {
    if (window.location.pathname === "/") {
      throw redirect({
        to: "/home/start",
      });
    }
  },
});

function RouteComponent() {
  return <Outlet />;
}
